import React, { useState } from 'react';
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';
import AdminSideBar from './Component/AdminSideBar';
import { Link, useNavigate } from 'react-router-dom';
import Error from '../../constants/ErrorMessage';
import AdminBlogService from '../../services/admin/AdminBlogService';
import AdminblogImageService from '../../services/admin/AdminblogImageService';
import AlertMessage from '../../components/AlertMessage';
import { Form } from 'react-bootstrap';
const AdminAddBlog = () => {

    const [blogData, setBlogData] = useState({
        blogTitle: "",
        blogCategory: "",
        authorName: "",
        date: "",
        quotation: "",
        content: "",
        secondContent: "",
        thirdContent: "",
    })
    const [showError, setShowError] = useState(false)
    const [blogImage, setBlogImage] = useState([]);
    const [alertState, setAlertState] = useState({})
    
    const inputChange = (e) => {
        setBlogData({ ...blogData, [e.target.name]: e.target.value })
    }

    const navigate = useNavigate()

    const onBlogImageChange = (event) => {

        const newImages = [...blogImage];
       
                for (let i = 0; i < event.target.files.length; i++) {
                    newImages.push({
                        id: blogImage.length + 1,
                        fileName: event.target.name[i],
                        file: event.target.files[i],

                    });

                }
                setBlogImage(newImages);
        
    };

    function alertClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        setAlertState({ open: false });
    };



    const SaveBlogHandler = async () => {
        setShowError(true)
        try {

            let curDate = new Date()
            let PublishDate = new Date(blogData.date)
            if (!blogData.blogTitle || blogData.blogTitle.length > 100) {
                if (blogData.blogTitle.length > 100) {
                    setAlertState({ open: true, type: "warning", message: Error.blogTitleLimit })
                } else {
                    setAlertState({ open: true, type: "warning", message: Error.blogTitle })
                }
            } else if (!blogData.blogCategory || blogData.blogCategory.length > 30) {
                if (blogData.blogCategory.length > 30) {
                    setAlertState({ open: true, type: "warning", message: Error.blogCategoryLimit })
                } else {
                    setAlertState({ open: true, type: "warning", message: Error.blogCategory })
                }
            } else if (!blogData.authorName || blogData.authorName.length > 30) {
                if (blogData.authorName.length > 30) {
                    setAlertState({ open: true, type: "warning", message: Error.authorNameLimit })
                } else {
                    setAlertState({ open: true, type: "warning", message: Error.authorName })
                }
            } else if (!blogData.date && +PublishDate < +curDate) {
                if(!blogData.date){
                    setAlertState({ open: true, type: "warning", message: "Please Provide Valid Date" })
                }else{
                    setAlertState({ open: true, type: "warning", message: "Please Provide Publish Date" })
                }
               

            } else if (!blogData.quotation || blogData.quotation.length > 250) {

                if (blogData.quotation.length > 250) {
                    setAlertState({ open: true, type: "warning", message: Error.quotationLimit })
                } else {
                    setAlertState({ open: true, type: "warning", message: Error.quotation })
                }
            } else if (!blogData.content || blogData.content.length > 1000) {
                if (blogData.content.length > 1000) {
                    setAlertState({ open: true, type: "warning", message: Error.contentLimit })
                } else {
                    setAlertState({ open: true, type: "warning", message: Error.content })
                }
            } else if (!blogData.secondContent || blogData.secondContent.length > 1000) {
                if (blogData.secondContent.length > 1000) {
                    setAlertState({ open: true, type: "warning", message: Error.secondContentLimit })
                } else {
                    setAlertState({ open: true, type: "warning", message: Error.secondContent })
                }

            } else if (!blogData.thirdContent || blogData.thirdContent.length > 1000) {
                if (blogData.thirdContent.length > 1000) {
                    setAlertState({ open: true, type: "warning", message: Error.thirdContentLimit })
                } else {
                    setAlertState({ open: true, type: "warning", message: Error.thirdContent })
                }
            } else if (blogImage.length < 3) {
                if (blogImage.length < 3) {
                    setAlertState({ open: true, type: "warning", message: 'Please add at least 3 images' });
                } else if (blogImage.length === 3) {
                    setAlertState({ open: true, type: "success", message: 'Images added successfully ' });
                }

            }


            else {
                const payload = {};
                payload.blogTitle = blogData.blogTitle;
                payload.blogCategory = blogData.blogCategory;
                payload.authorName = blogData.authorName;
                payload.publishdate = blogData.date;
                payload.quotation = blogData.quotation;
                payload.content = blogData.content;
                payload.secondContent = blogData.secondContent;
                payload.thirdContent = blogData.thirdContent;

                const res = await AdminBlogService.PostAdminBlog(payload)
                if (res.status === 200) {
                    // [...blogImage].forEach(async (file, index) => {
                    //     const formdata = new FormData();
                    //     formdata.append('blogId', res.data.identifiers[0].id);
                    //     formdata.append(`file${index}`, file);



                    // });
                    for (let i = 0; i < blogImage.length; i++) {
                        const formdata = new FormData();
                        formdata.append('blogId', res.data.identifiers[0].id);
                        formdata.append('position', blogImage[i].fileName)
                        formdata.append(`file`, blogImage[i].file);

                        const resp = await AdminblogImageService.PostBlogImage(formdata, {
                            headers: {
                                "Content-type": "multipart/form-data",
                            },
                        });

                     
                        if (resp.status === 200) {
                            setAlertState({ open: true, type: "success", message: "Blog Adding Successfully" })
                        } else {
                            setAlertState({ open: true, type: "warning", message: "Please Enter valid Inputs " })
                        }

                    }

                }

                setBlogData("")

                setShowError(false)
                setTimeout(() => {
                    navigate("/admin-manage-job")
                }, 1000);
                
            }
        } catch (error) {
            console.log(error)
        }


    }

    const curDate = new Date()
    const PublishDate = new Date(blogData.date)




    return (
        <>
            <Header activelist='admin-add-blog' />
            <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />
            <div style={{ borderTop: '3px solid #2e55fa' }}>
                <div className="page-content bg-white">

                    <div className="content-block">

                        <div className="section-full bg-white p-t50 p-b20">
                            <div className="container">
                                <div className="row">
                                    <div className="col-xl-3 col-lg-4 m-b30">
                                        <AdminSideBar activemenu="admin-add-blog" />
                                    </div>
                                    <div className="col-xl-9 col-lg-8 m-b30">
                                        <div className="job-bx submit-resume">
                                            <div className="job-bx-title clearfix">
                                                <h5 className="font-weight-700 pull-left text-uppercase">Post A Blog</h5>
                                                <Link to={"/admin-manage-job"} className="site-button right-arrow button-sm float-right">Back</Link>
                                            </div>

                                            <form>
                                                <div className='row'>
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="form-group">
                                                            <label>Blog Title <span style={{ color: 'red' }}>*</span></label>
                                                            <input type="text" className="form-control" value={blogData.blogTitle}
                                                                name="blogTitle"
                                                                onChange={inputChange}
                                                                placeholder="Enter Blog  Title" />
                                                            {blogData.blogTitle ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.blogTitle}</span> : null)}
                                                            {blogData.blogTitle ? (blogData.blogTitle.length > 100 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.blogTitleLimit}</span> : null) : null}
                                                        </div>

                                                    </div>

                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="form-group">
                                                            <label>Blog Category <span style={{ color: 'red' }}>*</span></label>
                                                            <Form.Control
                                                                as="select"
                                                                name="blogCategory"
                                                                value={blogData.blogCategory}
                                                                onChange={inputChange}
                                                                style={{ height: '50px' }}
                                                            >
                                                                <option value={''}>---SELECT---</option>
                                                                <option value="Social Media">Social Media</option>
                                                                <option value=" Book and writing"> Book and writing</option>
                                                                <option value="Sports">Sports</option>
                                                                <option value="News">News</option>
                                                                <option value="Art and design">Art and design</option>
                                                                <option value="Interviews">Interviews</option>
                                                                <option value="Beginner guides">Beginner guides</option>
                                                                <option value=" Business"> Business</option>


                                                            </Form.Control>

                                                            {/* <input type="text" className="form-control" value={blogData.blogCategory}
                                                                name="blogCategory"
                                                                onChange={inputChange}
                                                                placeholder="Enter Blog Category" /> */}
                                                            {blogData.blogCategory ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.blogCategory}</span> : null)}
                                                            {/* {blogData.blogCategory ? (blogData.blogCategory.length > 30 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.blogCategoryLimit}</span> : null) : null} */}
                                                        </div>

                                                    </div>
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="form-group">
                                                            <label>Author Name <span style={{ color: 'red' }}>*</span></label>
                                                            <input type="text" className="form-control" value={blogData.authorName}
                                                                name="authorName"
                                                                onChange={inputChange}
                                                                placeholder="Enter The Author Name" />
                                                            {blogData.authorName ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.blogCategory}</span> : null)}
                                                            {blogData.authorName ? (blogData.authorName.length > 30 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.blogCategoryLimit}</span> : null) : null}
                                                        </div>

                                                    </div>
                                                    <div className="col-lg-6 col-md-6">
                                                        <div className="form-group">
                                                            <label>Publish Date <span style={{ color: 'red' }}>*</span></label>
                                                            <input type="date" className="form-control" value={blogData.date}
                                                                name="date"
                                                                onChange={inputChange}
                                                                placeholder="Enter the Blog Date" />
                                                                {blogData.date ? null :( showError ? <span style={{fontSize:11 ,fontWeight:'bold',color:'red',marginLeft:5}}>{Error.date}</span> :null)}
                                                            {blogData.date ? +PublishDate < +curDate ? null : <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.currentDate}</span> : null}
                                                            {/* {blogData.date ? +PublishDate > +curDate ? null : <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.currentDate}</span> : null} */}
                                                        </div>

                                                    </div>
                                                    <div className="col-lg-12 col-md-12">
                                                        <div className="form-group">
                                                            <label>Quotation<span style={{ color: 'red' }}>*</span></label>
                                                            <textarea type="text" className="form-control" value={blogData.quotation}
                                                                name="quotation"
                                                                onChange={inputChange}
                                                                placeholder="Enter Your Blog Quotation" />
                                                            {blogData.quotation ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.quotation}</span> : null)}
                                                            {blogData.quotation ? (blogData.quotation.length > 250 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.quotationLimit}</span> : null) : null}
                                                        </div>

                                                    </div>
                                                    <div className="col-lg-12 col-md-12">
                                                        <div className="form-group">
                                                            <label>Content for 1st Section<span style={{ color: 'red' }}>*</span></label>
                                                            <textarea type="text" className="form-control" value={blogData.content}
                                                                name="content"
                                                                onChange={inputChange}
                                                                placeholder="Enter Your Blog Content " style={{ overflowWrap: 'break-word' }} />
                                                            {blogData.content ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.content}</span> : null)}
                                                            {blogData.content ? (blogData.content.length > 1000 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.contentLimit}</span> : null) : null}
                                                        </div>

                                                    </div>
                                                    <div className="col-lg-12 col-md-12">
                                                        <div className="form-group">
                                                            <label>Content for 2nd Section<span style={{ color: 'red' }}>*</span></label>
                                                            <textarea type="text" className="form-control" value={blogData.secondContent}
                                                                name="secondContent"
                                                                onChange={inputChange}
                                                                placeholder="Enter Your Blog Content" style={{ overflowWrap: 'break-word' }} />
                                                            {blogData.secondContent ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.secondContent}</span> : null)}
                                                            {blogData.secondContent ? (blogData.secondContent.length > 1000 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.secondContentLimit}</span> : null) : null}
                                                        </div>

                                                    </div>
                                                    <div className="col-lg-12 col-md-12">
                                                        <div className="form-group">
                                                            <label>Content for 3rd Section<span style={{ color: 'red' }}>*</span></label>
                                                            <textarea type="text" className="form-control" value={blogData.thirdContent}
                                                                name="thirdContent"
                                                                onChange={inputChange}
                                                                placeholder="Enter Your Blog Content" style={{ overflowWrap: 'break-word' }} />
                                                            {blogData.thirdContent ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.thirdContent}</span> : null)}
                                                            {blogData.thirdContent ? (blogData.thirdContent.length > 1000 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.thirdContent}</span> : null) : null}
                                                        </div>

                                                    </div>



                                                    <div className="col-lg-4 col-md-4">
                                                        <div className="form-group">
                                                            <label>Image (Size=1000*600)<span style={{ color: 'red' }}>*</span></label>
                                                            <input type="file" name='Center' className="form-control"

                                                                onChange={(e) => onBlogImageChange(e)}
                                                                placeholder="Enter Your Blog Content"
                                                                accept="image/x-png, image/gif, image/jpeg"  />
                                                            {/* <div className='d-flex'>
                                                                {blogImage.map((imageURL, index) => (
                                                                <img key={index} src={imageURL} alt={`Image ${index}`} className="thumbnail" />
                                                            ))}
                                                                </div> */}

                                                        </div>

                                                    </div>
                                                    <div className="col-lg-4 col-md-4">
                                                        <div className="form-group">
                                                            <label>Image (Size=700*500)<span style={{ color: 'red' }}>*</span></label>
                                                            <input type="file" name='Left' className="form-control"

                                                                onChange={(e) => onBlogImageChange(e)}
                                                                placeholder="Enter Your Blog Content"
                                                                accept="image/x-png, image/gif, image/jpeg"  />

                                                        </div>

                                                    </div>
                                                    <div className="col-lg-4 col-md-4">
                                                        <div className="form-group">
                                                            <label>Image (Size=700*500)<span style={{ color: 'red' }}>*</span></label>
                                                            <input type="file" name='Right' className="form-control"

                                                                onChange={(e) => onBlogImageChange(e)}
                                                                placeholder="Enter Your Blog Content" 
                                                                accept="image/x-png, image/gif, image/jpeg" />

                                                        </div>

                                                    </div>

                                                    <div style={{ width: '100%', display: 'flex', justifyContent: 'center', marginTop: 20 }}>

                                                        <button type="button" className="site-button " style={{ borderRadius: 30 }} onClick={SaveBlogHandler} >Post Blog</button>
                                                    </div>


                                                </div>

                                            </form>
                                        </div>
                                    </div>



                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default AdminAddBlog
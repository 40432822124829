import React, { useState, useEffect } from "react";
import Header from "../../components/Layout/Header";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/Layout/Footer";
import PageTitle from "../../components/Layout/PageTitle";
import UsersService from "../../services/Users";
import AlertMessage from '../../components/AlertMessage';
import { Modal } from "react-bootstrap";
import * as EmailValidator from 'email-validator';
import EmployerTeamService from "../../services/EmployerTeam";
import { ThreeCircles } from "react-loader-spinner";
import Error from "../../constants/ErrorMessage";
import EmployerSideNav from "./component/EmployerSideNav";

const EmployerTeam = () => {
    const [loader, setLoader] = useState(false)
    const [employerModal, setEmployerModal] = useState(false);
    const [employerData, setEmployerData] = useState([]);
    const [employerId, setEmployerId] = useState();
    const [employers, setEmployers] = useState({});
    const [employerModalView, setEmployerModalView] = useState(false);
    const [users, setUsers] = useState([]);
    const [empTeamId, setEmpTeamId] = useState();
    const [empTeam, setEmpTeam] = useState({});
    const [employer, setEmployer] = useState({ name: '', email: '', role: '', mobile: '', password: '', confirmPassword: '' });
    const [employerModalEdit, setEmployerModalEdit] = useState(false);
    const [employerModalDelete, setEmployerModalDelete] = useState(false);
    const [showError, setShowError] = useState(false);
    const [alertState, setAlertState] = useState({});
    const [userData, setUserData] = useState({});
    const [viewError, setViewError] = useState(false);

    let userId = JSON.parse(localStorage.getItem('userId'));

    function alertClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        setAlertState({ open: false });
    };

    const getAllEmployer = async () => {
        try {
            const res = await EmployerTeamService.getEmployerTeam();
            if (res.status === 200) {
                setEmployerData(res.data);
            }
        } catch (error) {
            console.log(error);
        }
    }

    const getUserData = async () => {
        try {
            const res = await UsersService.getUserById(userId);
            setUserData(res.data);
        } catch (error) {
            console.log(error);
        }
    }

    const getAllUserData = async () => {
        try {
            const res = await UsersService.getUser();
            if (res.status === 200) {
                setUsers(res.data)
            }
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getUserData();
    }, [userId])

    useEffect(() => {
        getAllEmployer();
        getAllUserData();
    }, []);



    const viewHandler = async (Id) => {
        try {
            employerData.find((itm) => {
                if (itm.id === Id) {
                    setEmployerId(itm.id);
                    setEmployers(itm);
                    setEmployerModalView(true);
                }
            })
        } catch (error) {
            console.log(error)
        }
    }

    const editHandler = async (Id) => {
        employerData.find((itm) => {
            if (itm.id === Id) {
                users.find((val) => {
                    if (val.email === itm.email) {
                        setEmpTeamId(val.id);
                        setEmpTeam(val)
                    }
                })
                setEmployerId(itm.id);
                setEmployer(itm);
                setEmployerModalEdit(true);
            }
        })
    }

    const deleteHandler = async (Id) => {
        employerData.find((itm) => {
            if (itm.id === Id) {
                users.find((val) => {
                    if (val.email === itm.email) {
                        setEmpTeamId(val.id);
                        setEmpTeam(val)
                    }
                })
                setEmployerId(itm.id);
                setEmployerModalDelete(true);

            }
        })
    }

    const deleteFunction = async () => {
        setLoader(true);
        try {
            const resp = await UsersService.deleteUser(empTeamId);
            if (resp.status === 200) {
                const res = await EmployerTeamService.deleteEmployer(employerId);
                if (res.status === 200) {
                    setAlertState({ open: true, type: 'error', message: "Employer Deleted successfully" })
                    window.location.reload();
                }
            }

        } catch (error) {
            console.log(error);
        }
        setLoader(false);
        setEmployerModalDelete(false);
    }

    const handleInputs = async (e) => {
        try {
            setEmployer({ ...employer, [e.target.name]: e.target.value })
        } catch (error) {
            console.log(error);
        }
    }


    const saveHandler = async () => {
        setShowError(true);
        setLoader(true);
        try {
            if (!employer.name || employer.name.length > 30) {
                if (employer.name.length > 30) {
                    setAlertState({ open: true, type: 'warning', message: 'Name should be under 30 characters' });
                } else {
                    setAlertState({ open: true, type: 'warning', message: 'Please provide employer name.' });
                }
            } else if (!employer.email || employer.email.length > 100 || EmailValidator.validate(employer.email) === false) {
                if (employer.email.length > 100) {
                    setAlertState({ open: true, type: 'warning', message: 'Email should be under 100 characters' });
                } else if (EmailValidator.validate(employer.email) === false) {
                    setAlertState({ open: true, type: 'warning', message: 'Please provide a valid email.' });
                }
                else {
                    setAlertState({ open: true, type: 'warning', message: 'Please provide employer email.' });
                }

            } else if (employer.mobile.length !== 10 || parseInt(employer.mobile) < 0) {
                setAlertState({ open: true, type: 'warning', message: 'Please provide a valid mobile number.' });
            } else if (!employer.role || employer.role.length > 100) {
                if (employer.role.length > 100) {
                    setAlertState({ open: true, type: 'warning', message: 'Role should be under 100 characters' });
                } else {
                    setAlertState({ open: true, type: 'warning', message: 'Please provide employer role.' });
                }

            } else if (employer.password === "" || employer.password.length < 8 || employer.password.length > 100) {
                if (employer.password.length < 8) {
                    setAlertState({ open: true, type: 'warning', message: 'Password should be minimum 8 characters.' });
                } else if (employer.password.length > 100) {
                    setAlertState({ open: true, type: 'warning', message: 'Password should be under 100 characters' });
                } else {
                    setAlertState({ open: true, type: 'warning', message: 'Please provide a password.' });
                }
            }
            else if (employer.confirmPassword === "" || employer.confirmPassword.length < 8 || employer.confirmPassword !== employer.password || employer.confirmPassword.length > 100) {
                if (employer.confirmPassword !== employer.password) {
                    setAlertState({ open: true, type: 'warning', message: "Password doesn't match." });
                } else {
                    setAlertState({ open: true, type: 'warning', message: 'Please provide a password.' });

                }
            }
            else {

                const payload = {};
                payload.name = employer.name;
                payload.email = employer.email;
                payload.mobile = employer.mobile;
                payload.password = employer.password;
                payload.roleId = 4;
                payload.status = 'In Review';

                payload.roleName = "NA";
                payload.company_name = "NA";
                payload.company_email = "NA";
                payload.company_category = "NA";
                payload.company_streetAddress = "NA";
                payload.company_zip = "NA";
                payload.company_city = "NA";
                payload.company_state = "NA";
                payload.company_country = "NA";

                if (payload.name && payload.email && payload.mobile && payload.password) {
                    const res = await UsersService.registerUser(payload);

                    if (res.status === 200) {
                        const payload = {};
                        payload.employerId = userId;
                        payload.name = employer.name;
                        payload.email = employer.email;
                        payload.mobile = employer.mobile;
                        payload.role = employer.role;
                        payload.status = 'In Review';

                        const resp = await EmployerTeamService.addEmployer(payload);

                        if (resp.status === 200) {
                            setAlertState({ open: true, type: 'success', message: "Employer Add in successfully" })
                            window.location.reload();

                        }

                    }
                }

            }

        } catch (error) {
            console.log(error);
            setAlertState({ open: true, type: 'error', message: error.response.data.message });
        }
        setLoader(false);
    }


    const updateMethod = async () => {
        setLoader(true);
        setViewError(true);
        try {
            if (!employer.name || employer.name.length > 30) {
                if (employer.name.length > 30) {
                    setAlertState({ open: true, type: 'warning', message: 'Name should be under 30 characters' });
                } else {
                    setAlertState({ open: true, type: 'warning', message: 'Please provide employer name.' });
                }
            } else if (!employer.email || employer.email.length > 100 || EmailValidator.validate(employer.email) === false) {
                if (employer.email.length > 100) {
                    setAlertState({ open: true, type: 'warning', message: 'Email should be under 100 characters' });
                } else if (EmailValidator.validate(employer.email) === false) {
                    setAlertState({ open: true, type: 'warning', message: 'Please provide a valid email.' });
                }
                else {
                    setAlertState({ open: true, type: 'warning', message: 'Please provide employer email.' });
                }

            } else if (employer.mobile.length !== 10 || parseInt(employer.mobile) < 0) {
                setAlertState({ open: true, type: 'warning', message: 'Please provide a valid mobile number.' });
            } else if (!employer.role || employer.role.length > 100) {
                if (employer.role.length > 100) {
                    setAlertState({ open: true, type: 'warning', message: 'Role should be under 100 characters' });
                }
                setAlertState({ open: true, type: 'warning', message: 'Please provide employer role.' });
            } else {
                const userRegistration = {};
                userRegistration.name = employer.name;
                userRegistration.email = employer.email;
                userRegistration.mobile = employer.mobile;
                userRegistration.password = empTeam.password;
                userRegistration.roleId = 4;
                userRegistration.status = 'In Review';

                const res = await UsersService.updateUser(empTeamId, userRegistration);
                if (res.status === 200) {
                    const payload = {};
                    payload.employerId = userId;
                    payload.name = employer.name;
                    payload.email = employer.email;
                    payload.mobile = employer.mobile;
                    payload.role = employer.role;
                    payload.status = 'In Review';

                    const resp = await EmployerTeamService.editEmployer(employerId, payload)

                    if (resp.status === 200) {
                        setAlertState({ open: true, type: 'success', message: "Employer team update successfully" })
                        setTimeout(() => {
                            window.location.reload(); 
                        }, 1000);
                        
                    }
                }
            }

        } catch (error) {
            console.log(error)
        }
        setLoader(false);
    }


    return (
        <>

            <Header status={userData.status} activelist="employer-team" />

            <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />
            {/* {loader ? 
        <div style={{width:"100%",height:"500px",display:"flex",justifyContent:"center",alignItems:'center'}}>
        <ThreeCircles
         height="80"
         width="80"
         color="#2e55fa"
         wrapperStyle={{}}
         wrapperClass=""
         visible={true}
         ariaLabel="three-circles-rotating"
         outerCircleColor=""
         innerCircleColor=""
         middleCircleColor=""
       />
     </div>: */}

            <div style={{ borderTop: '3px solid #2e55fa' }}>
                <div className="page-content bg-white">
                    <div className="content-block">

                        <div className="section-full bg-white p-t50 p-b20">
                            <div className="dez-bnr-inr overlay-black-middle" style={{ backgroundImage: "url(images/banner/bnr1.jpg)" }}>
                                <PageTitle motherName="Home" activeName="Employer Team" />
                            </div>
                            <div className="container">


                                <div className="row">
                                    <div className="col-xl-3 col-lg-4 m-b30 mt-5">

                                        <EmployerSideNav />


                                    </div>
                                    <div className="col-xl-9 col-lg-8 m-b30 mt-5">
                                        <div className="job-bx clearfix">
                                            <div >
                                                <Link onClick={() => setEmployerModal(true)} className="site-button right-arrow button-sm float-right  mb-3"><i className="fa fa-plus"></i> Add Employer </Link>
                                                {/* <button type="button" className="site-button button-sm" style={{ borderRadius: 30 }} onClick={() => setEmployerModal(true)}><i className="fa fa-plus"></i> Add Employer</button> */}
                                            </div>
                                            <div className='table-responsive' >
                                                <table className="table-job-bx cv-manager company-manage-job">
                                                    <thead>
                                                        <tr className="table-primary">
                                                            <th scope="col">Employer</th>
                                                            <th scope="col">Email</th>
                                                            <th scope="col">Status</th>
                                                            <th scope="col" >Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {
                                                            employerData.filter((item) => { if (item.employerId === userId) { return item; } })
                                                                .map((val) => {
                                                                    return (
                                                                        <>
                                                                            <tr key={val.id}>
                                                                                <td>{val.name}</td>
                                                                                <td>{val.email}</td>
                                                                                <td>{val.status}</td>


                                                                                <td className="job-links mt-2 ml-2">


                                                                                    <Link className='mr-1' onClick={() => viewHandler(val.id)} data-toggle="modal" data-target="#exampleModalLong" >
                                                                                        <i className="fa fa-eye bg-primary "></i>
                                                                                    </Link>
                                                                                    <Link className='mr-1' onClick={() => editHandler(val.id)} >
                                                                                        <i style={{ color: 'white', borderRadius: '5px', borderColor: 'orange' }} className="fa fa-pencil bg-warning" ></i>
                                                                                    </Link>
                                                                                    <Link onClick={() => deleteHandler(val.id)} >
                                                                                        <i className="ti-trash bg-danger "></i>
                                                                                    </Link>
                                                                                </td>
                                                                            </tr>
                                                                        </>
                                                                    )
                                                                })
                                                        }
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* <div className="dez-bnr-inr overlay-black-middle" style={{ backgroundImage: "url(images/banner/bnr1.jpg)" }}>
                    <PageTitle motherName="Home" activeName="Employer Team" />
                </div> */}


            {/* // } */}
            <Footer />
            <Modal show={employerModal} onHide={setEmployerModal} className="modal fade modal-bx-info" id="exampleModalLong" tabindex="-1" role="dialog" aria-hidden="true"
            >
                <div className="modal-dialog my-0" role="document">
                    <div className="modal-content">
                        <div className="modal-header" >

                            <h5 className="modal-title" >Employer Profile</h5>
                            <button type="button" className="close" onClick={() => { setEmployerModal(false); setShowError(false); }}>
                                <span aria-hidden="true" >&times;</span>
                            </button>
                        </div>

                        <div className="modal-body">
                            <form  >

                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label>Name <span style={{ color: 'red' }}>*</span></label>
                                            <input type="text" name="name" className="form-control" placeholder="Recruiter Name"
                                                value={employer.name}
                                                onChange={handleInputs}
                                            />
                                            {employer.name ? null : (showError ? (!employer.name ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.name}</span> : null) : null)}
                                            {employer.name.length > 30 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.nameLimit}</span> : null}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label>Email <span style={{ color: 'red' }}>*</span></label>
                                            <input type="email" name="email" className="form-control" placeholder="Recruiter Email"
                                                value={employer.email}
                                                onChange={handleInputs}
                                            />
                                            {employer.email ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.email}</span> : null)}
                                            {employer.email.length > 100 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.emailLimit}</span> : null}
                                            {employer.email ? (EmailValidator.validate(employer.email) === false ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.invalidEmail}</span> : null) : null}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label>Whatsapp No <span style={{ color: 'red' }}>*</span></label>
                                            <input type="number" name="mobile" className="form-control" placeholder="Recruiter Whatsapp"
                                                value={employer.mobile}
                                                onChange={handleInputs}
                                            />
                                            {employer.mobile ? null : (showError ? !employer.mobile ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.mobile}</span> : null : null)}
                                            {employer.mobile ? (employer.mobile.length !== 10 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.invalidMobile}</span> : null) : null}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label>Role <span style={{ color: 'red' }}>*</span></label>
                                            <input type="text" name="role" className="form-control" placeholder="Recruiter Role"
                                                value={employer.role}
                                                onChange={handleInputs}
                                            />
                                            {employer.role ? null : (showError ? (!employer.role ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.role}</span> : null) : null)}
                                            {employer.role.length > 100 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.roleLimit}</span> : null}
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label>Password <span style={{ color: 'red' }}>*</span></label>
                                            <input type="password" name="password" className="form-control" placeholder="Password"
                                                value={employer.password}
                                                onChange={handleInputs}
                                            />
                                            {employer.password ? null : (showError ? (!employer.password ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.password}</span> : null) : null)}
                                            {employer.password ? (employer.password.length < 8 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.minPass}</span> : null) : null}
                                            {employer.password ? (employer.password.length > 100 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.maxPass}</span> : null) : null}
                                        </div>
                                    </div>

                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label>Confirm Password <span style={{ color: 'red' }}>*</span></label>
                                            <input type="password" name="confirmPassword" className="form-control" placeholder="Confirm Password"
                                                value={employer.confirmPassword}
                                                onChange={handleInputs}
                                            />
                                            {employer.confirmPassword ? null : (showError ? (!employer.confirmPassword ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.confirmPassword}</span> : null) : null)}
                                            {employer.confirmPassword ? (employer.confirmPassword !== employer.password ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.matchedPassword}</span> : null) : null}
                                        </div>
                                    </div>

                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="site-button red" onClick={() => { setEmployerModal(false); setShowError(false); }}>Close </button>
                            <button type="button" className="site-button" onClick={saveHandler} >Save </button>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal show={employerModalEdit} onHide={setEmployerModalEdit} className="modal fade modal-bx-info" id="exampleModalLong" tabindex="-1" role="dialog" aria-hidden="true"
            >
                <div className="modal-dialog my-0" role="document" >
                    <div className="modal-content">
                        <div className="modal-header">

                            <h5 className="modal-title" >Update Employer Profile</h5>
                            <button type="button" className="close" onClick={() => { setEmployerModalEdit(false); setViewError(false); }}>
                                <span aria-hidden="true" >&times;</span>
                            </button>
                        </div>

                        <div className="modal-body">
                            <form  >


                                <div className="row">
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label>Name <span style={{ color: 'red' }}>*</span></label>
                                            <input type="text" name="name" className="form-control" placeholder="Recruiter Name"
                                                value={employer.name}
                                                onChange={handleInputs}
                                            />
                                            {employer.name ? null : (viewError ? (!employer.name ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.name}</span> : null) : null)}
                                            {employer.name.length > 30 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.nameLimit}</span> : null}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label>Email <span style={{ color: 'red' }}>*</span></label>
                                            <input type="email" name="email" className="form-control" placeholder="Recruiter Email"
                                                value={employer.email}
                                                onChange={handleInputs}
                                            />
                                            {employer.email ? null : (viewError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.email}</span> : null)}
                                            {employer.email.length > 100 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.emailLimit}</span> : null}
                                            {employer.email ? (EmailValidator.validate(employer.email) === false ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.invalidEmail}</span> : null) : null}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label>Whatsapp No <span style={{ color: 'red' }}>*</span></label>
                                            <input type="number" name="mobile" className="form-control" placeholder="Recruiter Whatsapp"
                                                value={employer.mobile}
                                                onChange={handleInputs}
                                            />
                                            {employer.mobile ? null : (viewError ? !employer.mobile ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.mobile}</span> : null : null)}
                                            {employer.mobile ? (employer.mobile.length !== 10 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.invalidMobile}</span> : null) : null}
                                        </div>
                                    </div>
                                    <div className="col-lg-6 col-md-6">
                                        <div className="form-group">
                                            <label>Role <span style={{ color: 'red' }}>*</span></label>
                                            <input type="text" name="role" className="form-control" placeholder="Recruiter Role"
                                                value={employer.role}
                                                onChange={handleInputs}
                                            />
                                            {employer.role ? null : (viewError ? (!employer.role ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.role}</span> : null) : null)}
                                            {employer.role.length > 100 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.roleLimit}</span> : null}
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="site-button red " onClick={() => { setEmployerModalEdit(false); setViewError(false) }}>Close </button>
                            <button type="button" className="site-button orange" onClick={updateMethod} >Update </button>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal
                show={employerModalDelete}
                onHide={setEmployerModalDelete}
                backdrop=""
                size='md'
                className="modal fade modal-bx-info" id="exampleModalLong" tabindex="-1" role="dialog" aria-hidden="true"

            >
                <div class="modal-dialog my-0" role="document" style={{ width: '100%' }}>
                    <div className="modal-content "  >
                        <div class="modal-header">
                            <div class="logo-img">
                                <img alt="" src="images/logo/icon2.png" />
                            </div>
                            <h5 class="modal-title">Delete Job</h5>
                            <button type="button" className="close" onClick={() => setEmployerModalDelete(false)} data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>

                        <div className="modal-body" >
                            {/* <h5 className="modal-title" >Job details: </h5> */}
                            <p style={{ color: "red" }}> Do you want to delete this Employer </p>



                        </div>
                        <div className="modal-footer">
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>

                            </div>
                            <button type='button' className='btn btn-info' onClick={() => setEmployerModalDelete(false)} data-dismiss="modal" aria-label="Close">Cancel</button>
                            <button type="button" className="btn btn-danger" onClick={deleteFunction}>Delete</button>

                        </div>
                    </div>
                </div>

            </Modal >


            {/* <Modal size='sm' show={employerModalDelete} onHide={setEmployerModalDelete} className="modal fade modal-bx-info" id="exampleModalLong" tabindex="-1" role="dialog" aria-hidden="true"
>
                <div className="modal-dialog my-0 " role="document" >
                    <div className="modal-content">
                        <div className="modal-header" >

                            <h6 className="modal-title" >Delete Employer </h6>
                            <button type="button" className="close" onClick={() => setEmployerModalDelete(false)}>
                                <span aria-hidden="true" >&times;</span>
                            </button>
                        </div>

                        <div className="modal-body">
                            <div style={{ width: '100%', padding:10, display: 'flex', justifyContent: 'flex-start', alignItems: 'center' }}>
                                <span style={{ color: 'red', fontSize: 14 }}>Do you want to delete this employer ?</span>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <button  type="button" className="btn btn-md btn-success " onClick={() => setEmployerModalDelete(false)}>Close </button>
                            <button  type="button" className="btn btn-md btn-danger" onClick={deleteFunction} >Delete </button>
                        </div>
                    </div>
                </div>
            </Modal> */}

            <Modal show={employerModalView} onHide={setEmployerModalView} className="modal fade modal-bx-info ">
                <div className="modal-dialog my-0 mx-0" role="document" >
                    <div className="modal-content">
                        <div className="modal-header" >

                            <h6 className="modal-title"  > Employer Profile</h6>
                            <button type="button" className="close" onClick={() => setEmployerModalView(false)}>
                                <span aria-hidden="true" >&times;</span>
                            </button>
                        </div>

                        <div className="modal-body">
                            <div className="row">
                                <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                        <label>Name <span >:</span></label>
                                        <span style={{ fontSize: 14, color: 'gray' }}> {employers.name}</span>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                        <label>Email <span >:</span></label>
                                        <span style={{ fontSize: 14, color: 'gray' }}> {employers.email}</span>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                        <label>Whatsapp No <span >:</span></label>
                                        <span style={{ fontSize: 14, color: 'gray' }}> {employers.mobile}</span>
                                    </div>
                                </div>
                                <div className="col-lg-12 col-md-12">
                                    <div className="form-group">
                                        <label>Role <span >:</span></label>
                                        <span style={{ fontSize: 14, color: 'gray' }}> {employers.role}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="modal-footer">
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>


        </>


    )
}

export default EmployerTeam
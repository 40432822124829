import React, { useEffect, useState } from 'react';
import {  useNavigate } from 'react-router-dom';
import {Link} from 'react-router-dom';
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';
import JobPostService from '../../services/JobPost';
import AlertMessage from '../../components/AlertMessage';

//Images



function Categorycompanyjob(){

	const[joblist ,setJoblists]=useState([]);
	const [activeTab, setActiveTab] = useState('1');
	const [radioData, setRadioData] = useState('');
	const [alertState, setAlertState] = useState({});

	const[dataclr ,setDataclr]=useState("");
	const[ColorChangeDataAll, setColorChangeDataAll]=useState('Blue');
	const[ColorChangeDataA,setColorChangeDataA]=useState('black');
	const[ColorChangeDataB,setColorChangeDataB]=useState('black')
	const[ColorChangeDataC,setColorChangeDataC]=useState('black')
	const[ColorChangeDataD,setColorChangeDataD]=useState('black')
	const[ColorChangeDataE,setColorChangeDataE]=useState('black')
	const[ColorChangeDataF,setColorChangeDataF]=useState('black')
	const[ColorChangeDataG,setColorChangeDataG]=useState('black')
	const[ColorChangeDataH,setColorChangeDataH]=useState('black')
	const[ColorChangeDataI,setColorChangeDataI]=useState('black')
	const[ColorChangeDataJ,setColorChangeDataJ]=useState('black')
	const[ColorChangeDataK,setColorChangeDataK]=useState('black')
	const[ColorChangeDataL,setColorChangeDataL]=useState('black')
	const[ColorChangeDataM,setColorChangeDataM]=useState('black')
	const[ColorChangeDataN,setColorChangeDataN]=useState('black')
	const[ColorChangeDataO,setColorChangeDataO]=useState('black')
	const[ColorChangeDataP,setColorChangeDataP]=useState('black')
	const[ColorChangeDataQ,setColorChangeDataQ]=useState('black')
	const[ColorChangeDataR,setColorChangeDataR]=useState('black')
	const[ColorChangeDataS,setColorChangeDataS]=useState('black')
	const[ColorChangeDataT,setColorChangeDataT]=useState('black')
	const[ColorChangeDataU,setColorChangeDataU]=useState('black')
	const[ColorChangeDataV,setColorChangeDataV]=useState('black')
	const[ColorChangeDataW,setColorChangeDataW]=useState('black')
	const[ColorChangeDataX,setColorChangeDataX]=useState('black')
	const[ColorChangeDataY,setColorChangeDataY]=useState('black')
	const[ColorChangeDataZ,setColorChangeDataZ]=useState('black')
	const[ColorChangeDataZero,setColorChangeDataZero]=useState('black')
	
	
	
	
	
	
	
	
	const navigate=useNavigate();

	function alertClose(event, reason) {
		if (reason === 'clickaway') {
			return;
		}
		setAlertState({open:false});
	};

    const toggle = tab => {
        if (activeTab !== tab) setActiveTab(tab);
    }



	
 

	const getCompanies=async()=>{
        try{
           const res=await JobPostService.getJobPost();
		   if(res.status===200){
			   setJoblists(res.data);
		   }
		}catch(err){
			return err;
		}
	}
useEffect(()=>{
	getCompanies();
},[])








var company=joblist.filter((val)=>{if(val.status === 'Active'){return val;}}).map(val=>val.job_company);

company.sort();
var current = null;
var arr=[];
var cnt = 0;
for (var i = 0; i < company.length; i++) {
	if (company[i]!= current) {
		if (cnt > 0) {
			arr.push(current);
		}
		current = company[i];
		cnt = 1;
	} else {
		cnt++;
	}
}
if (cnt > 0) {
	arr.push(current);
}
 




const searchHandler=async()=>{
	try{
        if(radioData){
			window.localStorage.setItem('companyData',radioData);
			window.localStorage.setItem('all','');
			window.localStorage.setItem('categories','');
		  window.localStorage.setItem('city','');
			window.localStorage.setItem('tags','');
			window.localStorage.setItem('designation','');


			navigate('/browse-job-grid');

		}else{
			setAlertState({open:true, type:'warning', message:'Please select company.'});
		}
	}catch(err){
		setAlertState({open:true, type:'error', message:err});
	}

}
	

const colorChangeHandelerAll=()=>{
	setDataclr("All");
	setColorChangeDataAll("blue");
	setColorChangeDataA("black");
	setColorChangeDataB("black");
	setColorChangeDataC("black");
	setColorChangeDataD("black");
	setColorChangeDataE("black");
	setColorChangeDataF("black");
	setColorChangeDataG("black");
	setColorChangeDataH("black");
	setColorChangeDataI("black");
	setColorChangeDataJ("black");
	setColorChangeDataK("black");
	setColorChangeDataL("black");
	setColorChangeDataM("black");
	setColorChangeDataN("black");
	setColorChangeDataO("black");
	setColorChangeDataP("black");
	setColorChangeDataQ("black");
	setColorChangeDataR("black");
	setColorChangeDataS("black");
	setColorChangeDataT("black");
	setColorChangeDataU("black");
	setColorChangeDataV("black");
	setColorChangeDataW("black");
	setColorChangeDataX("black");
	setColorChangeDataY("black");
	setColorChangeDataZ("black");
	setColorChangeDataZero("black");
}
	const ClrChangeHandlerA=()=>{
				setDataclr("A");
				setColorChangeDataAll("black");
				setColorChangeDataA("blue");
				setColorChangeDataB("black");
				setColorChangeDataC("black");
				setColorChangeDataD("black");
				setColorChangeDataE("black");
				setColorChangeDataF("black");
				setColorChangeDataG("black");
				setColorChangeDataH("black");
				setColorChangeDataI("black");
				setColorChangeDataJ("black");
				setColorChangeDataK("black");
				setColorChangeDataL("black");
				setColorChangeDataM("black");
				setColorChangeDataN("black");
				setColorChangeDataO("black");
				setColorChangeDataP("black");
				setColorChangeDataQ("black");
				setColorChangeDataR("black");
				setColorChangeDataS("black");
				setColorChangeDataT("black");
				setColorChangeDataU("black");
				setColorChangeDataV("black");
				setColorChangeDataW("black");
				setColorChangeDataX("black");
				setColorChangeDataY("black");
				setColorChangeDataZ("black");
				setColorChangeDataZero("black");
	}
	const ClrChangeHandlerB=()=>{
		setDataclr("B");
		setColorChangeDataAll("black");
		setColorChangeDataA("black");
		setColorChangeDataB("blue");
		setColorChangeDataC("black");
		setColorChangeDataD("black");
		setColorChangeDataE("black");
		setColorChangeDataF("black");
		setColorChangeDataG("black");
		setColorChangeDataH("black");
		setColorChangeDataI("black");
		setColorChangeDataJ("black");
		setColorChangeDataK("black");
		setColorChangeDataL("black");
		setColorChangeDataM("black");
		setColorChangeDataN("black");
		setColorChangeDataO("black");
		setColorChangeDataP("black");
		setColorChangeDataQ("black");
		setColorChangeDataR("black");
		setColorChangeDataS("black");
		setColorChangeDataT("black");
		setColorChangeDataU("black");
		setColorChangeDataV("black");
		setColorChangeDataW("black");
		setColorChangeDataX("black");
		setColorChangeDataY("black");
		setColorChangeDataZ("black");
		setColorChangeDataZero("black");
}
const ClrChangeHandlerC=()=>{
	setDataclr("C");
	setColorChangeDataAll("black");
	setColorChangeDataA("black");
	setColorChangeDataB("black");
	setColorChangeDataC("blue");
	setColorChangeDataD("black");
	setColorChangeDataE("black");
	setColorChangeDataF("black");
	setColorChangeDataG("black");
	setColorChangeDataH("black");
	setColorChangeDataI("black");
	setColorChangeDataJ("black");
	setColorChangeDataK("black");
	setColorChangeDataL("black");
	setColorChangeDataM("black");
	setColorChangeDataN("black");
	setColorChangeDataO("black");
	setColorChangeDataP("black");
	setColorChangeDataQ("black");
	setColorChangeDataR("black");
	setColorChangeDataS("black");
	setColorChangeDataT("black");
	setColorChangeDataU("black");
	setColorChangeDataV("black");
	setColorChangeDataW("black");
	setColorChangeDataX("black");
	setColorChangeDataY("black");
	setColorChangeDataZ("black");
	setColorChangeDataZero("black");
}
const ClrChangeHandlerD=()=>{
	setDataclr("D");
	setColorChangeDataAll("black");
	setColorChangeDataA("black");
	setColorChangeDataB("black");
	setColorChangeDataC("black");
	setColorChangeDataD("blue");
	setColorChangeDataE("black");
	setColorChangeDataF("black");
	setColorChangeDataG("black");
	setColorChangeDataH("black");
	setColorChangeDataI("black");
	setColorChangeDataJ("black");
	setColorChangeDataK("black");
	setColorChangeDataL("black");
	setColorChangeDataM("black");
	setColorChangeDataN("black");
	setColorChangeDataO("black");
	setColorChangeDataP("black");
	setColorChangeDataQ("black");
	setColorChangeDataR("black");
	setColorChangeDataS("black");
	setColorChangeDataT("black");
	setColorChangeDataU("black");
	setColorChangeDataV("black");
	setColorChangeDataW("black");
	setColorChangeDataX("black");
	setColorChangeDataY("black");
	setColorChangeDataZ("black");
	setColorChangeDataZero("black");
}
const ClrChangeHandlerE=()=>{
	setDataclr("E");
	setColorChangeDataAll("black");
	setColorChangeDataA("black");
	setColorChangeDataB("black");
	setColorChangeDataC("black");
	setColorChangeDataD("black");
	setColorChangeDataE("blue");
	setColorChangeDataF("black");
	setColorChangeDataG("black");
	setColorChangeDataH("black");
	setColorChangeDataI("black");
	setColorChangeDataJ("black");
	setColorChangeDataK("black");
	setColorChangeDataL("black");
	setColorChangeDataM("black");
	setColorChangeDataN("black");
	setColorChangeDataO("black");
	setColorChangeDataP("black");
	setColorChangeDataQ("black");
	setColorChangeDataR("black");
	setColorChangeDataS("black");
	setColorChangeDataT("black");
	setColorChangeDataU("black");
	setColorChangeDataV("black");
	setColorChangeDataW("black");
	setColorChangeDataX("black");
	setColorChangeDataY("black");
	setColorChangeDataZ("black");
	setColorChangeDataZero("black");
}

const ClrChangeHandlerF=()=>{
	setDataclr("F");
	setColorChangeDataAll("black");
	setColorChangeDataA("black");
	setColorChangeDataB("black");
	setColorChangeDataC("black");
	setColorChangeDataD("black");
	setColorChangeDataE("black");
	setColorChangeDataF("blue");
	setColorChangeDataG("black");
	setColorChangeDataH("black");
	setColorChangeDataI("black");
	setColorChangeDataJ("black");
	setColorChangeDataK("black");
	setColorChangeDataL("black");
	setColorChangeDataM("black");
	setColorChangeDataN("black");
	setColorChangeDataO("black");
	setColorChangeDataP("black");
	setColorChangeDataQ("black");
	setColorChangeDataR("black");
	setColorChangeDataS("black");
	setColorChangeDataT("black");
	setColorChangeDataU("black");
	setColorChangeDataV("black");
	setColorChangeDataW("black");
	setColorChangeDataX("black");
	setColorChangeDataY("black");
	setColorChangeDataZ("black");
	setColorChangeDataZero("black");
}
const ClrChangeHandlerG=()=>{
	setDataclr("G");
	setColorChangeDataAll("black");
	setColorChangeDataA("black");
	setColorChangeDataB("black");
	setColorChangeDataC("black");
	setColorChangeDataD("black");
	setColorChangeDataE("black");
	setColorChangeDataF("black");
	setColorChangeDataG("blue");
	setColorChangeDataH("black");
	setColorChangeDataI("black");
	setColorChangeDataJ("black");
	setColorChangeDataK("black");
	setColorChangeDataL("black");
	setColorChangeDataM("black");
	setColorChangeDataN("black");
	setColorChangeDataO("black");
	setColorChangeDataP("black");
	setColorChangeDataQ("black");
	setColorChangeDataR("black");
	setColorChangeDataS("black");
	setColorChangeDataT("black");
	setColorChangeDataU("black");
	setColorChangeDataV("black");
	setColorChangeDataW("black");
	setColorChangeDataX("black");
	setColorChangeDataY("black");
	setColorChangeDataZ("black");
	setColorChangeDataZero("black");
}
const ClrChangeHandlerH=()=>{
	setDataclr("H");
	setColorChangeDataAll("black");
	setColorChangeDataA("black");
	setColorChangeDataB("black");
	setColorChangeDataC("black");
	setColorChangeDataD("black");
	setColorChangeDataE("black");
	setColorChangeDataF("black");
	setColorChangeDataG("black");
	setColorChangeDataH("blue");
	setColorChangeDataI("black");
	setColorChangeDataJ("black");
	setColorChangeDataK("black");
	setColorChangeDataL("black");
	setColorChangeDataM("black");
	setColorChangeDataN("black");
	setColorChangeDataO("black");
	setColorChangeDataP("black");
	setColorChangeDataQ("black");
	setColorChangeDataR("black");
	setColorChangeDataS("black");
	setColorChangeDataT("black");
	setColorChangeDataU("black");
	setColorChangeDataV("black");
	setColorChangeDataW("black");
	setColorChangeDataX("black");
	setColorChangeDataY("black");
	setColorChangeDataZ("black");
	setColorChangeDataZero("black");
}
const ClrChangeHandlerI=()=>{
	setDataclr("I");
	setColorChangeDataAll("black");
	setColorChangeDataA("black");
	setColorChangeDataB("black");
	setColorChangeDataC("black");
	setColorChangeDataD("black");
	setColorChangeDataE("black");
	setColorChangeDataF("black");
	setColorChangeDataG("black");
	setColorChangeDataH("black");
	setColorChangeDataI("blue");
	setColorChangeDataJ("black");
	setColorChangeDataK("black");
	setColorChangeDataL("black");
	setColorChangeDataM("black");
	setColorChangeDataN("black");
	setColorChangeDataO("black");
	setColorChangeDataP("black");
	setColorChangeDataQ("black");
	setColorChangeDataR("black");
	setColorChangeDataS("black");
	setColorChangeDataT("black");
	setColorChangeDataU("black");
	setColorChangeDataV("black");
	setColorChangeDataW("black");
	setColorChangeDataX("black");
	setColorChangeDataY("black");
	setColorChangeDataZ("black");
	setColorChangeDataZero("black");
}
const ClrChangeHandlerJ=()=>{
	setDataclr("J");
	setColorChangeDataAll("black");
	setColorChangeDataA("black");
	setColorChangeDataB("black");
	setColorChangeDataC("black");
	setColorChangeDataD("black");
	setColorChangeDataE("black");
	setColorChangeDataF("black");
	setColorChangeDataG("black");
	setColorChangeDataH("black");
	setColorChangeDataI("black");
	setColorChangeDataJ("blue");
	setColorChangeDataK("black");
	setColorChangeDataL("black");
	setColorChangeDataM("black");
	setColorChangeDataN("black");
	setColorChangeDataO("black");
	setColorChangeDataP("black");
	setColorChangeDataQ("black");
	setColorChangeDataR("black");
	setColorChangeDataS("black");
	setColorChangeDataT("black");
	setColorChangeDataU("black");
	setColorChangeDataV("black");
	setColorChangeDataW("black");
	setColorChangeDataX("black");
	setColorChangeDataY("black");
	setColorChangeDataZ("black");
	setColorChangeDataZero("black");
}
const ClrChangeHandlerK=()=>{
	setDataclr("K");
	setColorChangeDataAll("black");
	setColorChangeDataA("black");
	setColorChangeDataB("black");
	setColorChangeDataC("black");
	setColorChangeDataD("black");
	setColorChangeDataE("black");
	setColorChangeDataF("black");
	setColorChangeDataG("black");
	setColorChangeDataH("black");
	setColorChangeDataI("black");
	setColorChangeDataJ("black");
	setColorChangeDataK("blue");
	setColorChangeDataL("black");
	setColorChangeDataM("black");
	setColorChangeDataN("black");
	setColorChangeDataO("black");
	setColorChangeDataP("black");
	setColorChangeDataQ("black");
	setColorChangeDataR("black");
	setColorChangeDataS("black");
	setColorChangeDataT("black");
	setColorChangeDataU("black");
	setColorChangeDataV("black");
	setColorChangeDataW("black");
	setColorChangeDataX("black");
	setColorChangeDataY("black");
	setColorChangeDataZ("black");
	setColorChangeDataZero("black");
}
const ClrChangeHandlerL=()=>{
	setDataclr("L");
	setColorChangeDataAll("black");
	setColorChangeDataA("black");
	setColorChangeDataB("black");
	setColorChangeDataC("black");
	setColorChangeDataD("black");
	setColorChangeDataE("black");
	setColorChangeDataF("black");
	setColorChangeDataG("black");
	setColorChangeDataH("black");
	setColorChangeDataI("black");
	setColorChangeDataJ("black");
	setColorChangeDataK("black");
	setColorChangeDataL("blue");
	setColorChangeDataM("black");
	setColorChangeDataN("black");
	setColorChangeDataO("black");
	setColorChangeDataP("black");
	setColorChangeDataQ("black");
	setColorChangeDataR("black");
	setColorChangeDataS("black");
	setColorChangeDataT("black");
	setColorChangeDataU("black");
	setColorChangeDataV("black");
	setColorChangeDataW("black");
	setColorChangeDataX("black");
	setColorChangeDataY("black");
	setColorChangeDataZ("black");
	setColorChangeDataZero("black");
}
const ClrChangeHandlerM=()=>{
	setDataclr("M");
	setColorChangeDataAll("black");
	setColorChangeDataA("black");
	setColorChangeDataB("black");
	setColorChangeDataC("black");
	setColorChangeDataD("black");
	setColorChangeDataE("black");
	setColorChangeDataF("black");
	setColorChangeDataG("black");
	setColorChangeDataH("black");
	setColorChangeDataI("black");
	setColorChangeDataJ("black");
	setColorChangeDataK("black");
	setColorChangeDataL("black");
	setColorChangeDataM("blue");
	setColorChangeDataN("black");
	setColorChangeDataO("black");
	setColorChangeDataP("black");
	setColorChangeDataQ("black");
	setColorChangeDataR("black");
	setColorChangeDataS("black");
	setColorChangeDataT("black");
	setColorChangeDataU("black");
	setColorChangeDataV("black");
	setColorChangeDataW("black");
	setColorChangeDataX("black");
	setColorChangeDataY("black");
	setColorChangeDataZ("black");
	setColorChangeDataZero("black");
}
const ClrChangeHandlerN=()=>{
	setDataclr("N");
	setColorChangeDataAll("black");
	setColorChangeDataA("black");
	setColorChangeDataB("black");
	setColorChangeDataC("black");
	setColorChangeDataD("black");
	setColorChangeDataE("black");
	setColorChangeDataF("black");
	setColorChangeDataG("black");
	setColorChangeDataH("black");
	setColorChangeDataI("black");
	setColorChangeDataJ("black");
	setColorChangeDataK("black");
	setColorChangeDataL("black");
	setColorChangeDataM("black");
	setColorChangeDataN("blue");
	setColorChangeDataO("black");
	setColorChangeDataP("black");
	setColorChangeDataQ("black");
	setColorChangeDataR("black");
	setColorChangeDataS("black");
	setColorChangeDataT("black");
	setColorChangeDataU("black");
	setColorChangeDataV("black");
	setColorChangeDataW("black");
	setColorChangeDataX("black");
	setColorChangeDataY("black");
	setColorChangeDataZ("black");
	setColorChangeDataZero("black");
}
const ClrChangeHandlerO=()=>{
	setDataclr("O");
	setColorChangeDataAll("black");
	setColorChangeDataA("black");
	setColorChangeDataB("black");
	setColorChangeDataC("black");
	setColorChangeDataD("black");
	setColorChangeDataE("black");
	setColorChangeDataF("black");
	setColorChangeDataG("black");
	setColorChangeDataH("black");
	setColorChangeDataI("black");
	setColorChangeDataJ("black");
	setColorChangeDataK("black");
	setColorChangeDataL("black");
	setColorChangeDataM("black");
	setColorChangeDataN("black");
	setColorChangeDataO("blue");
	setColorChangeDataP("black");
	setColorChangeDataQ("black");
	setColorChangeDataR("black");
	setColorChangeDataS("black");
	setColorChangeDataT("black");
	setColorChangeDataU("black");
	setColorChangeDataV("black");
	setColorChangeDataW("black");
	setColorChangeDataX("black");
	setColorChangeDataY("black");
	setColorChangeDataZ("black");
	setColorChangeDataZero("black");
}
const ClrChangeHandlerP=()=>{
	setDataclr("P");
	setColorChangeDataAll("black");
	setColorChangeDataA("black");
	setColorChangeDataB("black");
	setColorChangeDataC("black");
	setColorChangeDataD("black");
	setColorChangeDataE("black");
	setColorChangeDataF("black");
	setColorChangeDataG("black");
	setColorChangeDataH("black");
	setColorChangeDataI("black");
	setColorChangeDataJ("black");
	setColorChangeDataK("black");
	setColorChangeDataL("black");
	setColorChangeDataM("black");
	setColorChangeDataN("black");
	setColorChangeDataO("black");
	setColorChangeDataP("blue");
	setColorChangeDataQ("black");
	setColorChangeDataR("black");
	setColorChangeDataS("black");
	setColorChangeDataT("black");
	setColorChangeDataU("black");
	setColorChangeDataV("black");
	setColorChangeDataW("black");
	setColorChangeDataX("black");
	setColorChangeDataY("black");
	setColorChangeDataZ("black");
	setColorChangeDataZero("black");
}
const ClrChangeHandlerQ=()=>{
	setDataclr("Q");
	setColorChangeDataAll("black");
	setColorChangeDataA("black");
	setColorChangeDataB("black");
	setColorChangeDataC("black");
	setColorChangeDataD("black");
	setColorChangeDataE("black");
	setColorChangeDataF("black");
	setColorChangeDataG("black");
	setColorChangeDataH("black");
	setColorChangeDataI("black");
	setColorChangeDataJ("black");
	setColorChangeDataK("black");
	setColorChangeDataL("black");
	setColorChangeDataM("black");
	setColorChangeDataN("black");
	setColorChangeDataO("black");
	setColorChangeDataP("black");
	setColorChangeDataQ("blue");
	setColorChangeDataR("black");
	setColorChangeDataS("black");
	setColorChangeDataT("black");
	setColorChangeDataU("black");
	setColorChangeDataV("black");
	setColorChangeDataW("black");
	setColorChangeDataX("black");
	setColorChangeDataY("black");
	setColorChangeDataZ("black");
	setColorChangeDataZero("black");
}
const ClrChangeHandlerR=()=>{
	setDataclr("R");
	setColorChangeDataAll("black");
	setColorChangeDataA("black");
	setColorChangeDataB("black");
	setColorChangeDataC("black");
	setColorChangeDataD("black");
	setColorChangeDataE("black");
	setColorChangeDataF("black");
	setColorChangeDataG("black");
	setColorChangeDataH("black");
	setColorChangeDataI("black");
	setColorChangeDataJ("black");
	setColorChangeDataK("black");
	setColorChangeDataL("black");
	setColorChangeDataM("black");
	setColorChangeDataN("black");
	setColorChangeDataO("black");
	setColorChangeDataP("black");
	setColorChangeDataQ("black");
	setColorChangeDataR("blue");
	setColorChangeDataS("black");
	setColorChangeDataT("black");
	setColorChangeDataU("black");
	setColorChangeDataV("black");
	setColorChangeDataW("black");
	setColorChangeDataX("black");
	setColorChangeDataY("black");
	setColorChangeDataZ("black");
	setColorChangeDataZero("black");
}
const ClrChangeHandlerS=()=>{
	setDataclr("S");
	setColorChangeDataAll("black");
	setColorChangeDataA("black");
	setColorChangeDataB("black");
	setColorChangeDataC("black");
	setColorChangeDataD("black");
	setColorChangeDataE("black");
	setColorChangeDataF("black");
	setColorChangeDataG("black");
	setColorChangeDataH("black");
	setColorChangeDataI("black");
	setColorChangeDataJ("black");
	setColorChangeDataK("black");
	setColorChangeDataL("black");
	setColorChangeDataM("black");
	setColorChangeDataN("black");
	setColorChangeDataO("black");
	setColorChangeDataP("black");
	setColorChangeDataQ("black");
	setColorChangeDataR("black");
	setColorChangeDataS("blue");
	setColorChangeDataT("black");
	setColorChangeDataU("black");
	setColorChangeDataV("black");
	setColorChangeDataW("black");
	setColorChangeDataX("black");
	setColorChangeDataY("black");
	setColorChangeDataZ("black");
	setColorChangeDataZero("black");
}
const ClrChangeHandlerT=()=>{
	setDataclr("T");
	setColorChangeDataAll("black");
	setColorChangeDataA("black");
	setColorChangeDataB("black");
	setColorChangeDataC("black");
	setColorChangeDataD("black");
	setColorChangeDataE("black");
	setColorChangeDataF("black");
	setColorChangeDataG("black");
	setColorChangeDataH("black");
	setColorChangeDataI("black");
	setColorChangeDataJ("black");
	setColorChangeDataK("black");
	setColorChangeDataL("black");
	setColorChangeDataM("black");
	setColorChangeDataN("black");
	setColorChangeDataO("black");
	setColorChangeDataP("black");
	setColorChangeDataQ("black");
	setColorChangeDataR("black");
	setColorChangeDataS("black");
	setColorChangeDataT("blue");
	setColorChangeDataU("black");
	setColorChangeDataV("black");
	setColorChangeDataW("black");
	setColorChangeDataX("black");
	setColorChangeDataY("black");
	setColorChangeDataZ("black");
	setColorChangeDataZero("black");
}
const ClrChangeHandlerU=()=>{
	setDataclr("U");
	setColorChangeDataAll("black");
	setColorChangeDataA("black");
	setColorChangeDataB("black");
	setColorChangeDataC("black");
	setColorChangeDataD("black");
	setColorChangeDataE("black");
	setColorChangeDataF("black");
	setColorChangeDataG("black");
	setColorChangeDataH("black");
	setColorChangeDataI("black");
	setColorChangeDataJ("black");
	setColorChangeDataK("black");
	setColorChangeDataL("black");
	setColorChangeDataM("black");
	setColorChangeDataN("black");
	setColorChangeDataO("black");
	setColorChangeDataP("black");
	setColorChangeDataQ("black");
	setColorChangeDataR("black");
	setColorChangeDataS("black");
	setColorChangeDataT("black");
	setColorChangeDataU("blue");
	setColorChangeDataV("black");
	setColorChangeDataW("black");
	setColorChangeDataX("black");
	setColorChangeDataY("black");
	setColorChangeDataZ("black");
	setColorChangeDataZero("black");
}
const ClrChangeHandlerV=()=>{
	setDataclr("V");
	setColorChangeDataAll("black");
	setColorChangeDataA("black");
	setColorChangeDataB("black");
	setColorChangeDataC("black");
	setColorChangeDataD("black");
	setColorChangeDataE("black");
	setColorChangeDataF("black");
	setColorChangeDataG("black");
	setColorChangeDataH("black");
	setColorChangeDataI("black");
	setColorChangeDataJ("black");
	setColorChangeDataK("black");
	setColorChangeDataL("black");
	setColorChangeDataM("black");
	setColorChangeDataN("black");
	setColorChangeDataO("black");
	setColorChangeDataP("black");
	setColorChangeDataQ("black");
	setColorChangeDataR("black");
	setColorChangeDataS("black");
	setColorChangeDataT("black");
	setColorChangeDataU("black");
	setColorChangeDataV("blue");
	setColorChangeDataW("black");
	setColorChangeDataX("black");
	setColorChangeDataY("black");
	setColorChangeDataZ("black");
	setColorChangeDataZero("black");
}
const ClrChangeHandlerW=()=>{
	setDataclr("W");
	setColorChangeDataAll("black");
	setColorChangeDataA("black");
	setColorChangeDataB("black");
	setColorChangeDataC("black");
	setColorChangeDataD("black");
	setColorChangeDataE("black");
	setColorChangeDataF("black");
	setColorChangeDataG("black");
	setColorChangeDataH("black");
	setColorChangeDataI("black");
	setColorChangeDataJ("black");
	setColorChangeDataK("black");
	setColorChangeDataL("black");
	setColorChangeDataM("black");
	setColorChangeDataN("black");
	setColorChangeDataO("black");
	setColorChangeDataP("black");
	setColorChangeDataQ("black");
	setColorChangeDataR("black");
	setColorChangeDataS("black");
	setColorChangeDataT("black");
	setColorChangeDataU("black");
	setColorChangeDataV("black");
	setColorChangeDataW("blue");
	setColorChangeDataX("black");
	setColorChangeDataY("black");
	setColorChangeDataZ("black");
	setColorChangeDataZero("black");
}
const ClrChangeHandlerX=()=>{
	setDataclr("X");
	setColorChangeDataAll("black");
	setColorChangeDataA("black");
	setColorChangeDataB("black");
	setColorChangeDataC("black");
	setColorChangeDataD("black");
	setColorChangeDataE("black");
	setColorChangeDataF("black");
	setColorChangeDataG("black");
	setColorChangeDataH("black");
	setColorChangeDataI("black");
	setColorChangeDataJ("black");
	setColorChangeDataK("black");
	setColorChangeDataL("black");
	setColorChangeDataM("black");
	setColorChangeDataN("black");
	setColorChangeDataO("black");
	setColorChangeDataP("black");
	setColorChangeDataQ("black");
	setColorChangeDataR("black");
	setColorChangeDataS("black");
	setColorChangeDataT("black");
	setColorChangeDataU("black");
	setColorChangeDataV("black");
	setColorChangeDataW("black");
	setColorChangeDataX("blue");
	setColorChangeDataY("black");
	setColorChangeDataZ("black");
	setColorChangeDataZero("black");
}
const ClrChangeHandlerY=()=>{
	setDataclr("Y");
	setColorChangeDataAll("black");
	setColorChangeDataA("black");
	setColorChangeDataB("black");
	setColorChangeDataC("black");
	setColorChangeDataD("black");
	setColorChangeDataE("black");
	setColorChangeDataF("black");
	setColorChangeDataG("black");
	setColorChangeDataH("black");
	setColorChangeDataI("black");
	setColorChangeDataJ("black");
	setColorChangeDataK("black");
	setColorChangeDataL("black");
	setColorChangeDataM("black");
	setColorChangeDataN("black");
	setColorChangeDataO("black");
	setColorChangeDataP("black");
	setColorChangeDataQ("black");
	setColorChangeDataR("black");
	setColorChangeDataS("black");
	setColorChangeDataT("black");
	setColorChangeDataU("black");
	setColorChangeDataV("black");
	setColorChangeDataW("black");
	setColorChangeDataX("black");
	setColorChangeDataY("blue");
	setColorChangeDataZ("black");
	setColorChangeDataZero("black");
}
const ClrChangeHandlerZ=()=>{
	setDataclr("Z");
	setColorChangeDataAll("black");
	setColorChangeDataA("black");
	setColorChangeDataB("black");
	setColorChangeDataC("black");
	setColorChangeDataD("black");
	setColorChangeDataE("black");
	setColorChangeDataF("black");
	setColorChangeDataG("black");
	setColorChangeDataH("black");
	setColorChangeDataI("black");
	setColorChangeDataJ("black");
	setColorChangeDataK("black");
	setColorChangeDataL("black");
	setColorChangeDataM("black");
	setColorChangeDataN("black");
	setColorChangeDataO("black");
	setColorChangeDataP("black");
	setColorChangeDataQ("black");
	setColorChangeDataR("black");
	setColorChangeDataS("black");
	setColorChangeDataT("black");
	setColorChangeDataU("black");
	setColorChangeDataV("black");
	setColorChangeDataW("black");
	setColorChangeDataX("black");
	setColorChangeDataY("black");
	setColorChangeDataZ("blue");
	setColorChangeDataZero("black");
}
const ClrChangeHandler0=()=>{
	setDataclr("0");
	setColorChangeDataAll("black");
	setColorChangeDataA("black");
	setColorChangeDataB("black");
	setColorChangeDataC("black");
	setColorChangeDataD("black");
	setColorChangeDataE("black");
	setColorChangeDataF("black");
	setColorChangeDataG("black");
	setColorChangeDataH("black");
	setColorChangeDataI("black");
	setColorChangeDataJ("black");
	setColorChangeDataK("black");
	setColorChangeDataL("black");
	setColorChangeDataM("black");
	setColorChangeDataN("black");
	setColorChangeDataO("black");
	setColorChangeDataP("black");
	setColorChangeDataQ("black");
	setColorChangeDataR("black");
	setColorChangeDataS("black");
	setColorChangeDataT("black");
	setColorChangeDataU("black");
	setColorChangeDataV("black");
	setColorChangeDataW("black");
	setColorChangeDataX("black");
	setColorChangeDataY("black");
	setColorChangeDataZ("black");
	setColorChangeDataZero("blue");
}


	return(
		<>
			<Header activelist="category-company-jobs"/>
			<AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose}/>

			<div className="page-content">
				<div className="dez-bnr-inr jobs-category overlay-black-middle" style={{backgroundImage:"url(images/companyJob.jpg)"}}>
					<div className="container">
						<div className="dez-bnr-inr-entry">
							<div className="category-jobs-info">
								<div className="nav">
									<ul>
										<li ><Link to={"/category-all-jobs"}>All Jobs</Link></li>
										<li className="active"><Link to ={"/category-company-jobs"}>Jobs by Company</Link></li>
										<li><Link to ={"/category-jobs"}>Jobs by Category</Link></li>
										<li><Link to ={"/category-location-jobs"}>Jobs by Location</Link></li>
										<li ><Link to ={"/category-designation-jobs"}>Jobs by Designation</Link></li>
										<li><Link to ={"/category-skill-jobs"}>Jobs by Skill</Link></li>
									</ul>
								</div>
							</div>
						</div>
					</div>
				</div>
				<div className="content-block">
					<div className="section-full content-inner jobs-category-bx">
						<div className="container">
							<div className="row">
								<div className="col-lg-12 m-b30">
									<div className="job-bx bg-white">
										<div className="job-bx-title clearfix">
											<h6 className="font-weight-700 pull-left text-uppercase">Browse Jobs by Companies</h6>
										</div>
								

										<div tabs="true">
	                                      	<div className="site-filters clearfix m-b30"  >
			
			                                     <ul style={{display:"flex",flexDirection:"row",flexWrap:"wrap"}}>
				
			                                	 <li className="mr-2 mb-2" >
			                                		<button type ="button"  onClick={colorChangeHandelerAll} style={{backgroundColor:ColorChangeDataAll,color:"white",height:"30px", padding:"5px",borderRadius:"5px",outline:'none' }}>Top 100</button> 
			                                	</li> 
			                                	
			                                	<li  className="mr-2 mb-2">
			                                	 <button type ="button" onClick={ClrChangeHandlerA} style={{backgroundColor:ColorChangeDataA,color:"white",height:"30px", padding:"5px",width:"40px",borderRadius:"5px",outline:'none' }}>A </button> 
			                                		
			                                
			                                	</li >
			                                	 <li  className="mr-2 mb-2" >
												<button type ="button" onClick={ClrChangeHandlerB} style={{backgroundColor:ColorChangeDataB,color:"white",height:"30px", padding:"5px",width:"40px",borderRadius:"5px",outline:'none' }}>B</button> 
                                
			                                	</li>
                                
			                                	<li  className="mr-2 mb-2">
												<button type ="button" onClick={ClrChangeHandlerC} style={{backgroundColor:ColorChangeDataC,color:"white",height:"30px", padding:"5px",width:"40px",borderRadius:"5px",outline:'none' }}>C</button> 
			                                	</li>
			                                	
			                                	<li  className="mr-2 mb-2">
			                                	
			                                	<button type ="button" onClick={ClrChangeHandlerD} style={{backgroundColor:ColorChangeDataD,color:"white",height:"30px", padding:"5px",width:"40px",borderRadius:"5px",outline:'none' }}>D</button>
			                                	</li>
			                                	<li  className="mr-2 mb-2">
												<button type ="button" onClick={ClrChangeHandlerE} style={{backgroundColor:ColorChangeDataE,color:"white",height:"30px", padding:"5px",width:"40px",borderRadius:"5px",outline:'none' }}>E</button>
			                                	</li>
			                                	
			                                	<li className="mr-2 mb-2">
												<button type ="button" onClick={ClrChangeHandlerF} style={{backgroundColor:ColorChangeDataF,color:"white",height:"30px", padding:"5px",width:"40px",borderRadius:"5px",outline:'none' }}>F</button>
			                                	</li>
			                                	
			                                	<li className="mr-2 mb-2">
			                                	
												<button type ="button" onClick={ClrChangeHandlerG} style={{backgroundColor:ColorChangeDataG,color:"white",height:"30px", padding:"5px",width:"40px",borderRadius:"5px",outline:'none' }}>G</button>
			                                
			                                	</li>
			                                	<li className="mr-2 mb-2">
			                                
												<button type ="button" onClick={ClrChangeHandlerH} style={{backgroundColor:ColorChangeDataH,color:"white",height:"30px", padding:"5px",width:"40px",borderRadius:"5px",outline:'none' }}>H</button>
			                                	</li>
                                
			                                	<li className="mr-2 mb-2">
												<button type ="button" onClick={ClrChangeHandlerI} style={{backgroundColor:ColorChangeDataI,color:"white",height:"30px", padding:"5px",width:"40px",borderRadius:"5px",outline:'none' }}>I</button>

			                                	</li> 
			                                	<li  className="mr-2 mb-2">
												<button type ="button" onClick={ClrChangeHandlerJ} style={{backgroundColor:ColorChangeDataJ,color:"white",height:"30px", padding:"5px",width:"40px",borderRadius:"5px",outline:'none' }}>J</button>

			                                	</li> 
												<li  className="mr-2 mb-2">
												<button type ="button" onClick={ClrChangeHandlerK} style={{backgroundColor:ColorChangeDataK,color:"white",height:"30px", padding:"5px",width:"40px",borderRadius:"5px",outline:'none' }}>K</button>

			                                	</li> 
												<li  className="mr-2 mb-2">
												<button type ="button" onClick={ClrChangeHandlerL} style={{backgroundColor:ColorChangeDataL,color:"white",height:"30px", padding:"5px",width:"40px",borderRadius:"5px",outline:'none' }}>L</button>

			                                	</li> 
												<li  className="mr-2 mb-2">
												<button type ="button" onClick={ClrChangeHandlerM} style={{backgroundColor:ColorChangeDataM,color:"white",height:"30px", padding:"5px",width:"40px",borderRadius:"5px",outline:'none' }}>M</button>

			                                	</li> 
												<li  className="mr-2 mb-2">
												<button type ="button" onClick={ClrChangeHandlerN} style={{backgroundColor:ColorChangeDataN,color:"white",height:"30px", padding:"5px",width:"40px",borderRadius:"5px",outline:'none' }}>N</button>

			                                	</li> 
												<li className="mr-2 mb-2">
												<button type ="button" onClick={ClrChangeHandlerO} style={{backgroundColor:ColorChangeDataO,color:"white",height:"30px", padding:"5px",width:"40px",borderRadius:"5px",outline:'none' }}>O</button>

			                                	</li> 
												<li className="mr-2 mb-2">
												<button type ="button" onClick={ClrChangeHandlerP} style={{backgroundColor:ColorChangeDataP,color:"white",height:"30px", padding:"5px",width:"40px",borderRadius:"5px",outline:'none' }}>P</button>

			                                	</li> 
												<li className="mr-2 mb-2">
												<button type ="button" onClick={ClrChangeHandlerQ} style={{backgroundColor:ColorChangeDataQ,color:"white",height:"30px", padding:"5px",width:"40px",borderRadius:"5px",outline:'none' }}>Q</button>

			                                	</li> 
												<li className="mr-2 mb-2">
												<button type ="button" onClick={ClrChangeHandlerR} style={{backgroundColor:ColorChangeDataR,color:"white",height:"30px", padding:"5px",width:"40px",borderRadius:"5px",outline:'none' }}>R</button>

			                                	</li> 
												<li className="mr-2 mb-2">
												<button type ="button" onClick={ClrChangeHandlerS} style={{backgroundColor:ColorChangeDataS,color:"white",height:"30px", padding:"5px",width:"40px",borderRadius:"5px",outline:'none' }}>S</button>

			                                	</li> 
												<li className="mr-2 mb-2">
												<button type ="button" onClick={ClrChangeHandlerT} style={{backgroundColor:ColorChangeDataT,color:"white",height:"30px", padding:"5px",width:"40px",borderRadius:"5px",outline:'none' }}>T</button>

			                                	</li> 
												<li className="mr-2 mb-2">
												<button type ="button" onClick={ClrChangeHandlerU} style={{backgroundColor:ColorChangeDataU,color:"white",height:"30px", padding:"5px",width:"40px",borderRadius:"5px",outline:'none' }}>U</button>

			                                	</li> 
												<li className="mr-2 mb-2">
												<button type ="button" onClick={ClrChangeHandlerV} style={{backgroundColor:ColorChangeDataV,color:"white",height:"30px", padding:"5px",width:"40px",borderRadius:"5px",outline:'none' }}>V</button>

			                                	</li> 
												<li className="mr-2 mb-2">
												<button type ="button" onClick={ClrChangeHandlerW} style={{backgroundColor:ColorChangeDataW,color:"white",height:"30px", padding:"5px",width:"40px",borderRadius:"5px",outline:'none' }}>W</button>

			                                	</li> 
												<li className="mr-2 mb-2">
												<button type ="button" onClick={ClrChangeHandlerX} style={{backgroundColor:ColorChangeDataX,color:"white",height:"30px", padding:"5px",width:"40px",borderRadius:"5px",outline:'none' }}>X</button>

			                                	</li> 
												<li className="mr-2 mb-2">
												<button type ="button" onClick={ClrChangeHandlerY} style={{backgroundColor:ColorChangeDataY,color:"white",height:"30px", padding:"5px",width:"40px",borderRadius:"5px",outline:'none' }}>Y</button>

			                                	</li> 
												<li className="mr-2 mb-2">
												<button type ="button" onClick={ClrChangeHandlerZ} style={{backgroundColor:ColorChangeDataZ,color:"white",height:"30px", padding:"5px" ,width:"40px",borderRadius:"5px",outline:'none' }}>Z</button>

			                                	</li> 
												<li className="mr-2 mb-2">
												<button type ="button" onClick={ClrChangeHandler0} style={{backgroundColor:ColorChangeDataZero,color:"white",height:"30px", padding:"5px",width:"60px",borderRadius:"5px",outline:'none' }}>0-99</button>

			                                	</li> 

			                                	
			                                </ul>
		                                </div>
		                                </div>
                                     {
										arr.filter((val)=>{
											if((!dataclr || dataclr==="All")||(dataclr && (dataclr === (val).toString().substring(0,1))|| dataclr && (dataclr.toLowerCase() === (val).toString().substring(0,1))|| dataclr === parseInt((val).toString().substring(0,1)) || dataclr < parseInt((val).toString().substring(0,1)))){
												return val;
											}
										}).length === 0 ? 
										<div style={{width:'100%',height:100,display:'flex',justifyContent:'center',alignItems:'center',color:'#2e55fa'}}>
											Company Job Not Found.
									    </div>:
									 
										<ul className="post-job-bxx browse-job-grid row" style={{listStyle:"none"}}>
								          {arr.filter((val)=>{
											  if((!dataclr || dataclr==="All")||(dataclr && (dataclr === (val).toString().substring(0,1)) || dataclr && (dataclr.toLowerCase() === (val).toString().substring(0,1))|| dataclr === parseInt((val).toString().substring(0,1)) || dataclr < parseInt((val).toString().substring(0,1)))){
												  return val;
												 
											  }
										  }).map((item,index)=>(	
											<li className="col-lg-4 col-md-4" key={index} >
											<div className="job-post-inf ">
											  <div className="d-flex m-b10">
												<div className="job-post-info">
												  <ul>
													<div style={{ display: "flex", alignItems: "center" }}>
													  <li style={{listStyle:"none"}}>	
														<div style={ radioData === item ? { width: "300px", height: "50px", display: "flex", alignItems: "center", justifyContent: "flex-start", border: '1px solid ', borderRadius: "5px", backgroundColor: " #fbfbfb", marginLeft: "5px", borderColor: "blue", listStyle: "none" }:{ width: "300px", height: "50px", display: "flex", alignItems: "center", justifyContent: "flex-start", border: '1px solid ', borderRadius: "5px", backgroundColor: " #fbfbfb", marginLeft: "5px", borderColor: "#bfdcff", listStyle: "none" }} onClick={()=>{setRadioData(item);}}>
															 <div style={{ paddingLeft: "9px", paddingRight: "9px", borderRight: "1px solid #bfdcff", marginRight: '10px', borderColor: "#bfdcff" }}><img src="images/logo/logo17.png" alt="" style={{ height: "30px", width: "30px" }} /></div> <span style={{ color: "#2e55fa" }}>{item}</span></div>			
													<label htmlFor={'radio4' + item} >
														</label>
													  </li>
													</div>
												  </ul>
			
			
			
			
			
			
			
			
												</div>
											  </div>
											</div>
										  </li>
								))}	
								
							</ul>	}
							{
								arr.filter((val)=>{
									if((!dataclr || dataclr==="All")||(dataclr && (dataclr === (val).toString().substring(0,1))|| dataclr === parseInt((val).toString().substring(0,1)) || dataclr < parseInt((val).toString().substring(0,1)))){
										return val;
									}
								}).length === 0 ? null :
							
							<center ><button type="button"  className="site-button btn-block col-lg-2 col-md-2" onClick={searchHandler} style={{borderRadius:"50px"}} >Find Job</button></center>	}	
				
									</div>    
								</div>
							</div>
						</div>
					</div>
				</div>	
			</div>
			<Footer />	
		</>
	)
}
export default Categorycompanyjob;

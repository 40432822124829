import React, { useState, useEffect } from 'react';
import Header1 from '../../../components/Layout/Header';
import Footer from '../../../components/Layout/Footer';
import { Link } from 'react-router-dom';
import AdminSideBar2 from './AdminSideBar2';
import { Modal } from 'react-bootstrap';
import AlertMessage from '../../../components/AlertMessage';
import JobType from '../../../services/JobType';






const AdminJobTypeMaster = () => {

    const [addJobType, setAddJobType] = useState(false);
    const [editJobType, setEditJobType] = useState(false);
    const [deleteJobType, setDeleteJobType] = useState(false);
    const [jobTypes, setJobTypes] = useState([]);
    const [jobType, setJobType] = useState('');
    const [jobTypeId, setJobTypeId] = useState(0);
    const [type, setType] = useState('');
    const [alertState, setAlertState] = useState({ open: '', type: '', message: '' });




    function alertClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        setAlertState({ open: false });
    };

    const clr = () => {
        setType('');
    }

    const getAllJobType = async () => {
        try {

            const res = await JobType.getJobTypes();

            setJobTypes(res.data)


        } catch (error) {
            console.log(error)
        }
    }


    useEffect(() => {
        getAllJobType();
    }, [])



    const EditHandler = (id) => {
        try {

            jobTypes.find((val) => {
                if (val.id === id) {
                    setEditJobType(true);
                    setJobTypeId(id)
                    setJobType(val.jobType)
                }
            })


        } catch (error) {
            console.log(error);
        }
    }

    const DeleteHandler = async (id) => {
        try {

            jobTypes.find((val) => {
                if (val.id === id) {
                    setDeleteJobType(true);
                    setJobTypeId(id)
                }
            })


        } catch (error) {
            console.log(error);
        }
    }

    const AddHandler = async () => {
        try {
            if (!type) {
                setAlertState({ open: true, type: 'warning', message: 'Please provide valid inputs.' });
            } else {
                const payload = {};
                payload.jobType = type;
                const res = await JobType.addJobType(payload);
                if (res.status === 200) {
                    setAlertState({ open: true, type: 'success', message: 'JobType added successfully.' });
                    clr();
                    // setAddJobType(false)
                    setTimeout(() => {
                        window.location.reload();  
                    }, 1000);
                  
                } else {
                    setAlertState({ open: true, type: 'warning', message: 'Please provide valid inputs.' });
                }
            }

        } catch (error) {
            console.log(error);
        }

    }

    const EditJobTypeMethod = async () => {
        try {

            if (!jobType) {
                setAlertState({ open: true, type: 'warning', message: 'Please provide valid inputs.' });
            } else {
                var iid = parseInt(jobTypeId)
                const payload = {};
                payload.jobType = jobType;
                const res = await JobType.editJobType(iid, payload);
                if (res.status === 200) {
                    setAlertState({ open: true, type: 'success', message: 'JobType updated successfully.' });
                    setJobTypeId(0)
                    // setEditJobType(false)
                    setTimeout(() => {
                        window.location.reload(); 
                    }, 1000);
                } else {
                    setAlertState({ open: true, type: 'warning', message: 'Please provide valid inputs.' });
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    const deleteJobTypeMethod = async () => {
        try {
            var iid = parseInt(jobTypeId)
            const res = await JobType.deleteJobType(iid);
            if (res.status === 200) {
                setAlertState({ open: true, type: 'error', message: 'JobType deleted successfully.' });
                setJobTypeId(0)
                setTimeout(() => {
                    window.location.reload();  
                }, 1000);
            }
        } catch (error) {
            console.log(error);
        }
    }

    return (
        <>
            <Header1 />
            <div style={{ borderTop: '3px solid #2e55fa' }}>
                {/* <div className="breadcrumb-row" style={{color:"black",paddingLeft:"5.5%",backgroundColor:"White"}}>
								<ul className="list-inline">
									<li style={{color:"black"}} ><Link to={"/"}>Home</Link></li>
									<li style={{color:"black"}}>Job Type Master</li>
								</ul>
							</div> */}
                <div className="content-block" >
                    <div className="section-full browse-job content-inner-2">

                        <div className="container">
                            <div className="row">
                                <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12 m-b30">
                                    <AdminSideBar2 activelist="admin-jobtype-master" />
                                </div>
                                <div className="ml-30 col-xl-9 col-lg-8 col-md-8 col-sm-12">
                                    <div className=" job-bx bg-white m-b30">
                                        <div className="d-flex">
                                            <h5 className="m-b15">Job Type Master</h5>
                                            <Link to={"#"} className="site-button add-btn button-sm" onClick={() => setAddJobType(true)}>
                                                <i className="fa fa-plus m-r5"></i> Add
                                            </Link>
                                        </div>
                                        <table className="  table-job-bx cv-manager company-manage-job">
                                            <thead>
                                                <tr className='table-primary'>
                                                    <th >Sl No.</th>
                                                    <th >Job Type</th>
                                                    <th >Action</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {
                                                    jobTypes.map((val, ind) => {
                                                        return (
                                                            <tr className='table-hover' key={ind}>
                                                                <td className=' mb-1'><span className='fc-blk' >{ind + 1}</span> </td>
                                                                <td className=' mb-1'><span className='fc-blk'>{val.jobType}</span></td>
                                                                <td className='mb-1'>
                                                                    <button className="btn btn-warning button-sm mrg" onClick={() => EditHandler(val.id)}  ><i className="fa fa-pencil"></i></button>
                                                                    <button className="btn btn-danger button-sm mrg" style={{ marginLeft: "10px" }} onClick={() => DeleteHandler(val.id)}  ><i className="fa fa-trash"></i></button>
                                                                </td>
                                                            </tr>
                                                        )
                                                    })
                                                }

                                            </tbody>
                                        </table>
                                        <div className='df-rr'><Link className='f-sz' to='/admin-all-masters' >...View Less</Link></div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* =================================================== Add Modal ======================================================= */}
            <Modal show={addJobType} onHide={setAddJobType} className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog my-0" role="document">
                    <div className="modal-content">
                        <div className="modal-header" >
                            <h5 className="modal-title" id="RegionMaster">Add Job Type </h5>
                            <button type="button" className="close" onClick={() => { setAddJobType(false) }} >
                                <span aria-hidden="true" >&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p><span className='cl-r'>Note: </span> It is the first thing Candidates and Employers notice,while filling the form. Write concisely what makes your application unique and suitable for them.</p>
                            <form>
                                <div className="row">

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label>Job Type</label>
                                            <input type="text"
                                                className="form-control"
                                                placeholder="Enter Job Type."
                                                value={type}
                                                onChange={(e) => setType(e.target.value)}
                                                style={{ color: 'black' }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" onClick={() => { setAddJobType(false) }} >Cancel</button>
                            <button type="button" className="btn btn-success" onClick={AddHandler} >Save</button>
                        </div>
                    </div>
                </div>
                <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />

            </Modal>
            {/* ================================================== Edit Modal ==================================================== */}
            <Modal show={editJobType} onHide={setEditJobType} className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog my-0" role="document">
                    <div className="modal-content">
                        <div className="modal-header" >
                            <h5 className="modal-title" id="RegionMaster">Edit Job Type </h5>
                            <button type="button" className="close" onClick={() => { setEditJobType(false) }} >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p><span className='cl-r'>Note: </span> It is the first thing Candidates and Employers notice,while filling the form. Write concisely what makes your application unique and suitable for them.</p>
                            <form>
                                <div className="row">

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label>Category</label>
                                            <input type="text"
                                                className="form-control"
                                                placeholder="Enter Job Type."
                                                value={jobType}
                                                onChange={(e) => setJobType(e.target.value)}
                                                style={{ color: 'black' }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger" onClick={() => { setEditJobType(false) }} >Cancel</button>
                            <button type="button" className="btn btn-warning" onClick={EditJobTypeMethod} >Update</button>
                        </div>
                    </div>
                </div>
                <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />

            </Modal>


            {/* ======================================================================= Delete Modal ===================================================================================== */}



            <Modal show={deleteJobType} onHide={setDeleteJobType} className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true">

                <div className="modal-dialog my-0 mx-0" role="document">
                    <div className="modal-content">
                        <div className="modal-header" >
                            <h5 className="modal-title" id="RegionMaster"> Delete JobType</h5>

                            <button type="button" className="close" onClick={() => { setDeleteJobType(false) }} >
                                <span aria-hidden="true" >&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p><span className='cl-r'> Do You want to delete this JobType </span></p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary sm" onClick={() => { setDeleteJobType(false) }} >Cancel</button>
                            <button type="button" className=" btn btn-danger sm" onClick={deleteJobTypeMethod} >Delete</button>
                        </div>
                    </div>
                </div>
            </Modal>

            <Footer />
        </>
    )
}

export default AdminJobTypeMaster;
import React, { useState , useEffect} from 'react'
import AdminSideBar2 from './AdminSideBar2';
import Header1 from '../../../components/Layout/Header';
import Footer from '../../../components/Layout/Footer';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import AlertMessage from '../../../components/AlertMessage';
import RegionMasterServices from '../../../services/Master/RegionMaster';





const AdminRegionMaster = () => {

    const [regionModals, setRegionModals] = useState();
    const [region, setRegion] = useState([]);
    const [id, setId] = useState();
    const [region_name, setRegion_name] = useState();
    const [editRegion, setEditRegion] = useState(false);
    const [deleteRegion, setDeleteRegion] = useState(false);
    const [resionModal, setRegionModal] = useState(false);
    const [alertState, setAlertState] = useState({ open: '', type: '', message: '' });



    function alertClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        setAlertState({ open: false });
    };


    const getAllRegion=async()=>{
        try {
            const res = await RegionMasterServices.GetRegion();
            if(res.status===200){
                setRegion(res.data);
          }
        } catch (error) {
            return error;
        }
    }
  
    useEffect(()=>{
        getAllRegion();
    },[]);



    const EditHandler = (Id) => {
        region.map((val) => {
            if (val.id === Id) {
                setId(val.id)
                setRegion_name(val.state)
                setEditRegion(true);

            }
        })
    }

    const RegionDeleteHandler = (Id) => {
        region.map((val) => {
            if (val.id === Id) {
                setId(val.id)
                setDeleteRegion(true);

            }
        })
    }

    const regionHandler = async () => {
        try {
            const payload = {};
            payload.state = regionModals;
            if (payload.state) {
                const res = await RegionMasterServices.AddRegion(payload);
                if (res.status === 200) {
                    setAlertState({ open: true, type: 'success', message: 'Region added successfully.' });
                    setTimeout(() => {
                        window.location.reload();  
                    }, 1000);
                    
                }
            } else {
                setAlertState({ open: true, type: 'warning', message: 'Please provide valid inputs.' });
            }

        } catch (err) {
            return (err);
        }
    }

    const EditRegionMethod = async () => {
        try {
            const payload = {};
            payload.state = region_name;
            var Id = parseInt(id);
            if (payload.state) {
                const res = await RegionMasterServices.UpdateRegion(Id, payload);
                if (res.status === 200) {
                    setAlertState({ open: true, type: 'success', message: 'Region updated successfully.' });
                    setTimeout(() => {
                        window.location.reload(); 
                    }, 1000);
                }
            } else {
                setAlertState({ open: true, type: 'warning', message: 'Please provide valid inputs.' });
            }
        } catch (err) {
            return err;
        }

    }

    const deleteRegionMethod = async () => {
        try {

            var Id = parseInt(id);
            const res = await RegionMasterServices.DeleteRegion(Id);
            if (res.status === 200) {
                setAlertState({ open: true, type: 'error', message: 'Region deleted successfully.' });
                setTimeout(() => {
                    window.location.reload();
                }, 1000);
               
            }
        } catch (err) {
            return err;
        }

    }


    return (
        <>
            <Header1 />
            <div style={{ borderTop: '3px solid #2e55fa' }}>
            {/* <div className="breadcrumb-row" style={{color:"black",paddingLeft:"5.5%",backgroundColor:"White"}}>
								<ul className="list-inline">
									<li style={{color:"black"}} ><Link to={"/"}>Home</Link></li>
									<li style={{color:"black"}}>Region Master</li>
								</ul>
							</div> */}
            <div className="content-block" >
                <div className="section-full browse-job content-inner-2">

                    <div className="container">
                        <div className="row">
                            <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12 m-b30">
                                <AdminSideBar2 activelist="admin-region-master" />

                            </div>
                            <div className="ml-30 col-xl-9 col-lg-8 col-md-8 col-sm-12 ">
                                <div className=" job-bx bg-white m-b30">
                                    <div className='d-flex'	>
                                        <h5 className="m-b15">Region Master</h5>
                                        <Link to={"#"} className="site-button add-btn button-sm" data-toggle="modal" data-target="#AddRegionMaster" onClick={() => setRegionModal(true)} >
                                            <i className="fa fa-plus m-r5"></i> Add
                                        </Link>
                                    </div>

                                    <table className=' table-job-bx cv-manager company-manage-job'>
                                        <thead>
                                            <tr className="table-primary">
                                                <th >Sl No.</th>
                                                <th >Regions</th>
                                                <th >Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                region.map((val, ind) => {
                                                    return (
                                                        <tr className='table-hover' key={ind} >
                                                            <td >{ind + 1}</td>
                                                            <td>{val.state}</td>
                                                            <td>
                                                                <button className="btn btn-warning button-sm mrg" onClick={() => EditHandler(val.id)} ><i className="fa fa-pencil"></i></button>
                                                                <button className="btn btn-danger button-sm mrg" style={{ marginLeft: "10px" }} onClick={() => RegionDeleteHandler(val.id)}  ><i className="fa fa-trash"></i></button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    <div className='df-rr'><Link className='f-sz' to='/admin-all-masters' >...View Less</Link></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            {/* ======================================================== Add Region ==================================================== */}
            <Modal show={resionModal} onHide={setRegionModal} className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog my-0" role="document">
                    <div className="modal-content">
                        <div className="modal-header" >
                            <h5 className="modal-title"  id="RegionMaster">Add Region </h5>
                            <button type="button" className="close" onClick={() => { setRegionModal(false) }} >
                                <span aria-hidden="true" >&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p><span className='cl-r'>Note: </span> It is the first thing Candidates and Employers notice,while filling the form. Write concisely what makes your application unique and suitable for them.</p>
                            <form>
                                <div className="row">


                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label>Region</label>
                                            <input type="text"
                                                className="form-control"
                                                placeholder="Enter Region."
                                                value={regionModals}
                                                onChange={(e) => setRegionModals(e.target.value)} style={{color:'black'}}/>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger"  onClick={() => { setRegionModal(false) }} >Cancel</button>
                            <button type="button" className="btn btn-success"  onClick={regionHandler} >Save</button>
                        </div>
                    </div>
                </div>
                <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />

            </Modal>
            {/* ================================================== Edit Modal ==================================================== */}
            <Modal show={editRegion} onHide={setEditRegion} className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog my-0" role="document">
                    <div className="modal-content">
                        <div className="modal-header" >
                            <h5 className="modal-title" id="RegionMaster">Edit Region </h5>
                            <button type="button" className="close" onClick={() => { setEditRegion(false) }} >
                                <span aria-hidden="true" >&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p><span className='cl-r'>Note: </span> It is the first thing Candidates and Employers notice,while filling the form. Write concisely what makes your application unique and suitable for them.</p>
                            <form>
                                <div className="row">


                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label>Region</label>
                                            <input type="text"
                                                className="form-control"
                                                placeholder="Enter Region."
                                                value={region_name}
                                                onChange={(e) => setRegion_name(e.target.value)} style={{color:'black'}}/>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger"  onClick={() => { setRegionModal(false) }} >Cancel</button>
                            <button type="button" className="btn btn-success"  onClick={EditRegionMethod}  >Save</button>
                        </div>
                    </div>
                </div>
                <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />

            </Modal>
            {/* ============================================== Delete Modal ================================================= */}


      

            <Modal
				show={deleteRegion}
				onHide={setDeleteRegion}
				backdrop=""
				size='md'
				className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true"

			>
				<div class="modal-dialog my-0" role="document" style={{ width: '100%' }}>
					<div className="modal-content "  >
						<div class="modal-header">
							<div class="logo-img">
								<img alt="" src="images/logo/icon2.png" />
							</div>
							<h5 class="modal-title">Delete Region</h5>
							<button type="button" className="close" onClick={() => setDeleteRegion(false)} data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>

						<div className="modal-body" >
							
							<p style={{ color: "red" }}> Do you want to delete this Region </p>



						</div>
						<div className="modal-footer">
							<div style={{ display: 'flex', justifyContent: 'flex-end' }}>

							</div>
							<button type='button' className='btn btn-info' onClick={() => setDeleteRegion(false)} data-dismiss="modal" aria-label="Close">Cancel</button>
							<button type="button" className="btn btn-danger" onClick={deleteRegionMethod}>Delete</button>

						</div>
					</div>
				</div>
                <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />
			</Modal >
            <Footer />
        </>
    )
}

export default AdminRegionMaster;
import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CountUp from 'react-countup';
import Footer from '../../components/Layout/Footer';
import IndexBanner from '../../components/Layout/IndexBanner';
import Jobcategories from '../../components/Jobcategories';
import Jobsection from '../../components/Jobsection';
import Users from '../../services/Users';
import JobPost from '../../services/JobPost';
import Featureblog from '../../components/Featureblog';
import { ThreeCircles } from 'react-loader-spinner';
import TestimonialSlider from '../../components/TestimonialSlider';
const Home = () => {

	const [alljobs, setAllJobs] = useState([]);
	const [user, setUser] = useState([]);
	const [logUser, setLogUser] = useState({});
	const [loader, setLoader] = useState(false);

	let userId = JSON.parse(localStorage.getItem('userId'));

	const navigate = useNavigate();

	const getAllJobs = async () => {
		try {
			const res = await JobPost.getJobPost()

			setAllJobs(res.data)
		} catch (error) {
			console.log(error)
		}
	}

	const getAllUser = async () => {
		try {
			const res = await Users.getUser();
			setUser(res.data);
		} catch (error) {
			console.log(error)
		}
	}

	const getUserDetails = async () => {
		try {
			const res = await Users.getUserById(userId);
			if (res.status === 200) {
				setLogUser(res.data)
			}
		} catch (error) {
			console.log(error);
		}
	}

	useEffect(() => {
		setLoader(true);
		getAllJobs();
		getAllUser();
		getUserDetails();
		setTimeout(() => {
			setLoader(false);
		}, 1500);

	}, [`${userId}`]);

	const categories = alljobs.filter((val) => { if (val.status === "Active") { return val } }).map(val => val.job_category)

	const count = [...new Set(categories)]

	if (logUser.roleId === 2 || logUser.roleId === 4) {
		navigate('/manage-job');
	}

	return (
		<>
			{
				loader ?
					<div style={{ width: "100%", height: "500px", display: "flex", justifyContent: "center", alignItems: 'center' }}>
						<ThreeCircles
							height="90"
							width="90"
							color="#2e55fa"
							wrapperStyle={{}}
							wrapperClass=""
							visible={true}
							ariaLabel="three-circles-rotating"
							outerCircleColor=""
							innerCircleColor=""
							middleCircleColor=""
						/>
					</div>
					:
					<div>
						<div className="page-content">
							<IndexBanner job={alljobs.filter((val) => { if (val.status === 'Active') { return val } }).map(val => val).length} />
							<div className="section-full job-categories content-inner-2 bg-white">
								<div className="container">
									<div className="section-head d-flex head-counter clearfix">
										<div className="mr-auto">
											<h2 className="m-b5">Popular Categories</h2>
											<h6 className="fw3">{count.map(val => val).length > 21 ? '20+ Categories' : (count.map(val => val).length === 1 ? count.map(val => val).length + ' Category' : count.map(val => val).length + ' Categories')}  work waiting for you</h6>
										</div>
										<div className="head-counter-bx">
											<h2 className="m-b5 "><CountUp end={alljobs.map(val => val).length} duration={5} /></h2>
											<h6 className="fw3">Jobs Posted</h6>
										</div>
										<div className="head-counter-bx">
											<h2 className="m-b5 "><CountUp end={user.filter((val) => { if (val.roleId === 2) { return val } }).map(val => val).length} duration={5} /></h2>
											<h6 className="fw3">Employers</h6>
										</div>
										<div className="head-counter-bx">
											<h2 className="m-b5 "><CountUp end={user.filter((val) => { if (val.roleId === 1) { return val } }).map(val => val).length} duration={5} /></h2>
											<h6 className="fw3">Candidates</h6>
										</div>
									</div>

									<Jobcategories />

								</div>
							</div>

							<Featureblog />

							<Jobsection />

							<div class="section-full p-tb70 overlay-black-dark text-white text-center bg-img-fix" style={{
								backgroundImage: 'url(images/background/testimonials_bg.jpg)',
								backgroundSize: 'cover',
								backgroundPosition: 'center',
								backgroundRepeat: 'no-repeat',
								backgroundColor: '#f5f5f5'
							}}>
								<div class="container">
									<div class="section-head text-center text-white">
										<h2 class="m-b5">Testimonials</h2>
										<h5 class="fw4">Few words from candidates</h5>
									</div>
									<TestimonialSlider />
								</div>
							</div>


							<div className="section-full content-inner-2 bg-white" style={{ backgroundImage: "url(images/lines.png)", backgroundPosition: "bottom", backgroundRepeat: "no-repeat", backgroundSize: "100%" }}>
								<div className="container">
									<div className="section-head text-black text-center">
										<h2 className="m-b0">Membership Plans</h2>
										<p>These plans are designed to provide different levels of access to job posting features, candidate searches, and recruitment tools.</p>
									</div>

									<div className="section-content box-sort-in button-example m-t80">
										<div className="pricingtable-row">
											<div className="row max-w1000 m-auto">
												<div className="col-sm-12 col-md-4 col-lg-4 p-lr0">
													<div className="pricingtable-wrapper style2">
														<div className="pricingtable-inner">
															<div className="pricingtable-price">
																<h4 className="font-weight-300 m-t10 m-b0">Basic</h4>
																<div className="pricingtable-bx"><span>Free</span></div>
															</div>
															<p>Basic job visibility (standard listing)</p>
															<p>24/7 customer support via email</p>
															{/* <div className="m-t20">
																<Link to="register.html" className="site-button radius-xl"><span className="p-lr30">Sign Up</span></Link>
															</div> */}
														</div>
													</div>
												</div>
												<div className="col-sm-12 col-md-4 col-lg-4 p-lr0">
													<div className="pricingtable-wrapper style2 text-white active">
														<div className="pricingtable-inner">
															<div className="pricingtable-price">
																<h4 className="font-weight-300 m-t10 m-b0">Professional</h4>
																{/* <div className="pricingtable-bx"> &#8377; <span>0</span> /  Per User </div> */}
																<div className="pricingtable-bx"> <span>Coming Soon</span> </div>
															</div>
															<p>Enhanced job visibility (priority listing)</p>
															<p>24/7 customer support via email and chat</p>
															{/* <div className="m-t20">
																<Link to="register.html" className="site-button white radius-xl"><span className="text-primary p-lr30">Sign Up</span></Link>
															</div> */}
														</div>
													</div>
												</div>
												<div className="col-sm-12 col-md-4 col-lg-4 p-lr0">
													<div className="pricingtable-wrapper style2">
														<div className="pricingtable-inner">
															<div className="pricingtable-price">
																<h4 className="font-weight-300 m-t10 m-b0">Extended</h4>
																<div className="pricingtable-bx"> <span>Coming Soon</span> </div>
															</div>
															<p>Maximum job visibility(featured listing)</p>
															<p>24/7 customer support via email, chat</p>
															{/* <div className="m-t20">
																<Link to="register.html" className="site-button radius-xl"><span className="p-lr30">Sign Up</span></Link>
															</div> */}
														</div>
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>
							</div>
						</div>

						<Footer />
					</div>}
		</>
	)
};
export default Home;
import React, { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import Footer from "../../components/Layout/Footer";
import Header from "../../components/Layout/Header";
import getJobServices from "../../services/JobPost";
import SavedJobServices from "../../services/SavedJob";
import JobTypeMaster from "../../services/Master/JobTypeMaster";
import ExperienceMaster from "../../services/Master/ExperienceMaster";
import Users from "../../services/Users";
import { searchBucket } from "../../components/Layout/IndexBanner";
import { ThreeCircles, Oval } from 'react-loader-spinner'


const BrowseJobFilterList = () => {
  const [jobData, setJobData] = useState([]);
  const [data, setData] = useState([]);
  const [userData, setUserData] = useState({});
  const [loader, setLoader] = useState(false);
  const [experience, setExperience] = useState();
  const [salary, setSalary] = useState();
  const [companies, setCompanies] = useState([]);
  const [jobType, setJobtype] = useState();
  const [alertState, setAlertState] = useState({
    open: "",
    type: "",
    message: "",
  });
  const [loaderSavedJob, setLoaderSavedJob] = useState(false)
  const [experienceRadio, setExperienceRadio] = useState(true);
  const [salaryRadio, setSalaryRadio] = useState(true);
  const [jobTypeRadio, setJobTypeRadio] = useState(true);
  const [keySkills, setKeySkillls] = useState([]);
  const [sortData, setSortData] = useState("");
  const [jobTypeData, setJobTypeData] = useState([]);
  const [experienceData, setExperienceData] = useState([]);
  const [jobRequirement, setJobRequirement] = useState([]);
  const [searchData, setSearchData] = useState({})
  const [comanyView, seyCompanyView] = useState(false);
  const [salaryView, setSalaryView] = useState(false);
  const [experienceView, setExperienceView] = useState(false);
  const [jobTypeView, setJobTypeView] = useState(false);
  const [loaderId, setLoaderId] = useState(0);

  let filteredArray = [];
  const userId = JSON.parse(localStorage.getItem("userId"));
  const Id = userId;

  const companiesOnChange = (e) => {
    if (e.target.checked === true) {
      setCompanies([...companies, e.target.value]);
    } else if (e.target.checked === false) {
      let freshArray = companies.filter((val) => val !== e.target.value);
      setCompanies([...freshArray]);
    }
  };

  const experienceOnChange = (e) => {

    setExperience(e.target.value);


  };
  const salaryOnchange = (e) => {
    setSalary(e.target.value);
  };

  const jobTypeOnChange = (e) => {
    setJobtype(e.target.value);
  };

  const getUserData = async () => {
    try {
      const res = await Users.getUserById(Id);

      if (res.status === 200) {
        setUserData(res.data);
      }
    } catch (error) {
      return error;
    }
  };
  useEffect(() => {
    getUserData();
  }, [Id]);

  const getJoblist = async () => {
    try {
      const res = await getJobServices.getJobPost();



      setJobData(res.data);
    } catch (err) {
      console.log(err);
    }
  };

  const getKeySkill = async () => {
    try {
      const res = await getJobServices.getAllKeySkills();
      setKeySkillls(res.data);
    } catch (error) {
      console.log(error);
    }
  };
  const getJobType = async () => {
    try {
      const res = await JobTypeMaster.getAllJobTypes();
      setJobTypeData(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getExperience = async () => {
    try {
      const res = await ExperienceMaster.GetExperience();
      setExperienceData(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  const getAllJobRequirement = async () => {
    try {
      const res = await getJobServices.getAllJobRequirement();
      setJobRequirement(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    setLoader(true);
    setSearchData(searchBucket);
    getJoblist();
    getKeySkill();
    getJobType();
    getExperience();
    getAllJobRequirement();
    setTimeout(() => {
      setLoader(false);

    }, 1000);
  }, []);

  var company = [...new Set(jobData.filter((val) => { if (val.status === "Active" && (!searchData.title || searchData.title && val.job_title.toLowerCase().includes(searchData.title.toLowerCase())) && (!searchData.location || searchData.location && val.job_area.toLowerCase().includes(searchData.location.toLowerCase())) && (!searchData.sector || searchData.sector && val.job_category.toLowerCase().includes(searchData.sector.toLowerCase()))) { return val; } }).map((val) => val.job_company))];


  for (var i = 0; i < jobData.length; i++) {
    var match = false;
    for (var j = 0; j < companies.length; j++) {
      if (jobData[i].job_company === companies[j]) {
        match = true;
        break;
      }
    }
    if (match) {
      filteredArray.push(jobData[i]);
    }
  }

  var sal = parseInt(salary) * 1000;
  var Sal = sal < 50000 ? sal + 10000 : 100000000;

  const saveHandler = async (jobs) => {
    setLoaderId(jobs.id);
    setLoaderSavedJob(true);
    try {
      let job = data.filter((val) => {
        if (val.job_id === jobs.id) {
          return val.id;
        }
      });
      if (job.length > 0) {
        var id = job[0].id;

        const res = await SavedJobServices.deleteSavedJob(id);
        if (res.status === 200) {

          getJoblist();
          getKeySkill();
          getJobType();
          getExperience();
          getAllJobRequirement();
          getSavedJob();

        }
      } else {
        const payload = {};

        payload.user_id = userId;
        payload.job_id = parseInt(jobs.id);
        payload.job_title = jobs.job_title;
        payload.companay = jobs.job_company;
        payload.created_on = jobs.created_on;

        const res = await SavedJobServices.postSavedJob(payload);



        if (res.status === 200) {
          getJoblist();
          getKeySkill();
          getJobType();
          getExperience();
          getAllJobRequirement();
          getSavedJob();
        }

      }
    } catch (err) {
      return err;
    }
    getJoblist();
    setTimeout(() => {
      setLoaderSavedJob(false);

    }, 1500);
  };
  const getSavedJob = async () => {
    const userId = JSON.parse(localStorage.getItem("userId"));
    try {
      const res = await SavedJobServices.getSavedJob(userId);

      setData(res.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getSavedJob();
  }, [userId]);

  if (sortData) {
    var today = new Date();
    var priorDate = new Date(
      new Date().setDate(today.getDate() - parseInt(sortData))
    );
  }

  if (sortData) {
    var today = new Date();
    var priorDate = new Date(
      new Date().setDate(today.getDate() - parseInt(sortData))
    );
  }

  function formatNumber(number) {
    if (number >= 10000000) {
      return (number / 10000000).toFixed(1) + 'Cr';
    } else if (number >= 100000) {
      return (number / 100000).toFixed(1) + 'L';
    } 
    else if (number >= 1000) {
      return (number / 1000).toFixed(1) + 'K';
    }else {
      return number;
    }
  }

  return (
    <>
      <Header />
      {
        loader ?
          <div style={{ width: "100%", height: "500px", display: "flex", justifyContent: "center", alignItems: 'center' }}>
            <ThreeCircles
              height="90"
              width="90"
              color="#2e55fa"
              wrapperStyle={{}}
              wrapperClass=""
              visible={true}
              ariaLabel="three-circles-rotating"
              outerCircleColor=""
              innerCircleColor=""
              middleCircleColor=""
            />
          </div>
          :
          <div>

            <div className="page-content bg-white">

              <div
                className="dez-bnr-inr overlay-black-middle"
                style={{ backgroundImage: "url(images/job10.jpg)" }}
              >
                <div className="container">
                  <div className="dez-bnr-inr-entry">
                    <h1 className="text-white">Browse Job Filter List</h1>

                    <div className="breadcrumb-row">
                      <ul className="list-inline">
                        <li>
                          <Link to="/">Home</Link>
                        </li>
                        <li>Browse Job Filter List</li>
                      </ul>
                    </div>

                  </div>
                </div>
              </div>
              <div className="content-block">
                <div className="section-full browse-job p-b50 mt-5">
                  <div className="container">
                    <div className="row">
                      <div className="col-xl-3 col-lg-4 col-md-5 m-b30 ">
                        <aside id="accordion1" className="sticky-top sidebar-filter ">
                          <h6 className="title">
                            <i className="fa fa-sliders "></i> Refined By{" "}
                            <Link to={"#"} className="font-12 float-right">
                              Reset All
                            </Link>
                          </h6>
                          <div className="panel" style={{ borderTop: "1px solid black" }}>
                            <div
                              style={{
                                height: "50px",
                                border: "1px solid #fff",
                                boxShadow: " 0px 3px 3px rgba(0,0,0,0.1)",
                                paddingLeft: "5%",
                                display: 'flex',
                                alignItems: 'center'
                              }}>
                              <h6 className="acod-title">
                                Companies
                              </h6>
                            </div>

                            <div id="companies" className="acod-body collapse show">
                              <div className="acod-content">
                                {company.length === 0 ? (
                                  <div className="custom-control custom-checkbox">
                                    NA
                                  </div>
                                ) : (
                                  company.map(val => val).length > 4 ?
                                    (
                                      comanyView === false ?
                                        <div>
                                          {
                                            company.slice(0, 4).map((val) => {
                                              return (
                                                <div style={{ marginBottom: "-10px" }}>
                                                  <input
                                                    style={{ marginRight: "10px" }}
                                                    id={val}
                                                    type="checkbox"
                                                    value={val}
                                                    onChange={companiesOnChange}
                                                  />
                                                  <label className="" htmlFor={val}>
                                                    {val}
                                                  </label>
                                                </div>
                                              );
                                            })}
                                          <Link onClick={() => { seyCompanyView(true); }} className="font-12 float-right">
                                            View All
                                          </Link>
                                        </div> :
                                        <div>
                                          {
                                            company.map((val) => {
                                              return (
                                                <div style={{ marginBottom: "-10px" }}>
                                                  <input
                                                    style={{ marginRight: "10px" }}
                                                    id={val}
                                                    type="checkbox"
                                                    value={val}
                                                    onChange={companiesOnChange}
                                                  />
                                                  <label className="" htmlFor={val}>
                                                    {val}
                                                  </label>
                                                </div>
                                              );
                                            })}
                                          <Link onClick={() => { seyCompanyView(false); }} className="font-12 float-right">
                                            View Less
                                          </Link>
                                        </div>)
                                    :
                                    company.map((val) => {
                                      return (
                                        <div style={{ marginBottom: "-10px" }}>
                                          <input
                                            style={{ marginRight: "10px" }}
                                            id={val}
                                            type="checkbox"
                                            value={val}
                                            onChange={companiesOnChange}
                                          />
                                          <label className="" htmlFor={val}>
                                            {val}
                                          </label>
                                        </div>
                                      );
                                    })
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="panel" style={{ borderTop: "1px solid black" }}>
                            <div
                              style={{
                                height: "50px",
                                border: "1px solid #fff",
                                boxShadow: " 0px 3px 3px rgba(0,0,0,0.1)",
                                paddingLeft: "5%",
                                display: 'flex',
                                alignItems: 'center'
                              }}>
                              <h6 className="acod-title">
                                <a
                                  data-toggle="collapse"
                                  href="#experience"
                                  className="collapsed"
                                >
                                  Experience
                                </a>
                              </h6>
                            </div>

                            <div id="experience" className="acod-body collapse show">
                              <div className="acod-content">
                                <div className="custom-control custom-radio">
                                  <input
                                    className="custom-control-input"
                                    id="one-yearss"
                                    type="radio"
                                    name="radio-years"
                                    checked={experienceRadio}
                                    value={""}
                                    onChange={(e) => {
                                      setLoader(true);

                                      experienceOnChange(e);
                                      setExperienceRadio(true);
                                      setLoader(false);

                                    }}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="one-yearss"
                                  >
                                    All <span></span>{" "}
                                  </label>
                                </div>
                                {experienceData.map(val => val).length > 3 ?
                                  experienceView === false ?
                                    <div>
                                      {

                                        experienceData.slice(0, 3).map((val) => {
                                          return (
                                            <div
                                              className="custom-control custom-radio"
                                              key={val.id}
                                            >
                                              <input
                                                className="custom-control-input"
                                                id={`"one-years"+${val.id}`}
                                                type="radio"
                                                name="radio-years"
                                                value={val.exp_year}
                                                onChange={(e) => {
                                                  setLoader(true);
                                                  experienceOnChange(e);
                                                  setExperienceRadio(false);
                                                  setLoader(false);

                                                }}
                                              />
                                              <label
                                                className="custom-control-label"
                                                htmlFor={`"one-years"+${val.id}`}
                                              >
                                                {val.exp_year} <span></span>{" "}
                                              </label>
                                            </div>
                                          );
                                        })}
                                      <Link onClick={() => { setExperienceView(true); }} className="font-12 float-right">
                                        View All
                                      </Link>
                                    </div> :
                                    <div>
                                      {

                                        experienceData.map((val) => {
                                          return (
                                            <div
                                              className="custom-control custom-radio"
                                              key={val.id}
                                            >
                                              <input
                                                className="custom-control-input"
                                                id={`"one-years"+${val.id}`}
                                                type="radio"
                                                name="radio-years"
                                                value={val.exp_year}
                                                onChange={(e) => {
                                                  setLoader(true);
                                                  experienceOnChange(e);
                                                  setExperienceRadio(false);
                                                  setLoader(false);
                                                }}
                                              />
                                              <label
                                                className="custom-control-label"
                                                htmlFor={`"one-years"+${val.id}`}
                                              >
                                                {val.exp_year} <span></span>{" "}
                                              </label>
                                            </div>
                                          );
                                        })}
                                      <Link onClick={() => { setExperienceView(false); }} className="font-12 float-right">
                                        View Less
                                      </Link>
                                    </div>
                                  :

                                  experienceData.map((val) => {
                                    return (
                                      <div
                                        className="custom-control custom-radio"
                                        key={val.id}
                                      >
                                        <input
                                          className="custom-control-input"
                                          id={`"one-years"+${val.id}`}
                                          type="radio"
                                          name="radio-years"
                                          value={val.exp_year}
                                          onChange={(e) => {
                                            setLoader(true);
                                            experienceOnChange(e);
                                            setExperienceRadio(false);
                                            setLoader(false);
                                          }}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor={`"one-years"+${val.id}`}
                                        >
                                          {val.exp_year} <span></span>{" "}
                                        </label>
                                      </div>
                                    );
                                  })}
                              </div>
                            </div>
                          </div>
                          <div className="panel" style={{ borderTop: "1px solid black" }}>
                            <div
                              style={{
                                height: "50px",
                                border: "1px solid #fff",
                                boxShadow: " 0px 3px 3px rgba(0,0,0,0.1)",
                                paddingLeft: "5%",
                                display: 'flex',
                                alignItems: 'center'
                              }}>
                              <h6 className="acod-title">
                                <a
                                  data-toggle="collapse"
                                  href="#salary"
                                  className="collapsed"
                                >
                                  Salary
                                </a>
                              </h6>
                            </div>

                            <div id="salary" className="acod-body collapse show">
                              <div className="acod-content">
                                <div className="custom-control custom-radio">
                                  <input
                                    className="custom-control-input"
                                    id="salary-op11"
                                    type="radio"
                                    name="radio-currency"
                                    value=""
                                    checked={salaryRadio}
                                    onChange={(e) => {
                                      salaryOnchange(e);
                                      setSalaryRadio(true);
                                    }}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="salary-op11"
                                  >
                                    All{" "}
                                  </label>
                                </div>
                                {
                                  salaryView === false ?
                                    <div>
                                      <div className="custom-control custom-radio">
                                        <input
                                          className="custom-control-input"
                                          id="salary-op1"
                                          type="radio"
                                          name="radio-currency"
                                          value={0}
                                          onChange={(e) => {
                                            salaryOnchange(e);
                                            setSalaryRadio(false);
                                          }}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="salary-op1"
                                        >
                                          Less than 10k (Monthly){" "}
                                        </label>
                                      </div>
                                      <div className="custom-control custom-radio">
                                        <input
                                          className="custom-control-input"
                                          id="salary-op2"
                                          type="radio"
                                          name="radio-currency"
                                          value={10}
                                          onChange={(e) => {
                                            salaryOnchange(e);
                                            setSalaryRadio(false);
                                          }}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="salary-op2"
                                        >
                                          10k-20k (Monthly){" "}
                                        </label>
                                      </div>
                                      <div className="custom-control custom-radio">
                                        <input
                                          className="custom-control-input"
                                          id="salary-op3"
                                          type="radio"
                                          name="radio-currency"
                                          value={20}
                                          onChange={(e) => {
                                            salaryOnchange(e);
                                            setSalaryRadio(false);
                                          }}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="salary-op3"
                                        >
                                          20k-30k (Monthly){" "}
                                        </label>
                                      </div>
                                      <Link onClick={() => { setSalaryView(true); }} className="font-12 float-right">
                                        View All
                                      </Link>
                                    </div> :
                                    <div>

                                      <div className="custom-control custom-radio">
                                        <input
                                          className="custom-control-input"
                                          id="salary-op1"
                                          type="radio"
                                          name="radio-currency"
                                          value={0}
                                          onChange={(e) => {
                                            salaryOnchange(e);
                                            setSalaryRadio(false);
                                          }}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="salary-op1"
                                        >
                                          Less than 10k (Monthly){" "}
                                        </label>
                                      </div>
                                      <div className="custom-control custom-radio">
                                        <input
                                          className="custom-control-input"
                                          id="salary-op2"
                                          type="radio"
                                          name="radio-currency"
                                          value={10}
                                          onChange={(e) => {
                                            salaryOnchange(e);
                                            setSalaryRadio(false);
                                          }}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="salary-op2"
                                        >
                                          10k-20k (Monthly){" "}
                                        </label>
                                      </div>
                                      <div className="custom-control custom-radio">
                                        <input
                                          className="custom-control-input"
                                          id="salary-op3"
                                          type="radio"
                                          name="radio-currency"
                                          value={20}
                                          onChange={(e) => {
                                            salaryOnchange(e);
                                            setSalaryRadio(false);
                                          }}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="salary-op3"
                                        >
                                          20k-30k (Monthly){" "}
                                        </label>
                                      </div>
                                      <div className="custom-control custom-radio">
                                        <input
                                          className="custom-control-input"
                                          id="salary-op4"
                                          type="radio"
                                          name="radio-currency"
                                          value={30}
                                          onChange={(e) => {
                                            salaryOnchange(e);
                                            setSalaryRadio(false);
                                          }}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="salary-op4"
                                        >
                                          30k-40k (Monthly)
                                        </label>
                                      </div>
                                      <div className="custom-control custom-radio">
                                        <input
                                          className="custom-control-input"
                                          id="salary-op5"
                                          type="radio"
                                          name="radio-currency"
                                          value={40}
                                          onChange={(e) => {
                                            salaryOnchange(e);
                                            setSalaryRadio(false);
                                          }}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="salary-op5"
                                        >
                                          40k-50k (Monthly)
                                        </label>
                                      </div>
                                      <div className="custom-control custom-radio">
                                        <input
                                          className="custom-control-input"
                                          id="salary-op5p"
                                          type="radio"
                                          name="radio-currency"
                                          value={50}
                                          onChange={(e) => {
                                            salaryOnchange(e);
                                            setSalaryRadio(false);
                                          }}
                                        />
                                        <label
                                          className="custom-control-label"
                                          htmlFor="salary-op5p"
                                        >
                                          50k plus (Monthly)
                                        </label>
                                      </div>
                                      <Link onClick={() => { setSalaryView(false); }} className="font-12 float-right">
                                        View Less
                                      </Link>
                                    </div>}
                              </div>
                            </div>
                          </div>
                          <div className="panel" style={{ borderTop: "1px solid black" }}>
                            <div
                              style={{
                                height: "50px",
                                border: "1px solid #fff",
                                boxShadow: " 0px 3px 3px rgba(0,0,0,0.1)",
                                paddingLeft: "5%",
                                display: 'flex',
                                alignItems: 'center'
                              }}>
                              <h6 className="acod-title">
                                <a
                                  data-toggle="collapse"
                                  href="#job-function"
                                  className="collapsed"
                                >
                                  Job Type
                                </a>
                              </h6>
                            </div>

                            <div
                              id="job-function"
                              className="acod-body collapse show"
                            >
                              <div className="acod-content">
                                <div className="custom-control custom-radio">
                                  <input
                                    className="custom-control-input"
                                    id="function-services-11"
                                    type="radio"
                                    name="radio-function"
                                    value=""
                                    checked={jobTypeRadio}
                                    onChange={(e) => {
                                      jobTypeOnChange(e);
                                      setJobTypeRadio(true);
                                    }}
                                  />
                                  <label
                                    className="custom-control-label"
                                    htmlFor="function-services-11"
                                  >
                                    All <span></span>{" "}
                                  </label>
                                </div>
                                {jobTypeData.map(val => val).length > 3 ?

                                  jobTypeView === false ?

                                    <div>
                                      {

                                        jobTypeData.slice(0, 3).map((val) => {
                                          return (
                                            <div
                                              className="custom-control custom-radio"
                                              key={val.id}
                                            >
                                              <input
                                                className="custom-control-input"
                                                id={`1"function-services-1"+${val.id}`}
                                                type="radio"
                                                name="radio-function"
                                                value={val.jobType}
                                                onChange={(e) => {
                                                  jobTypeOnChange(e);
                                                  setJobTypeRadio(false);
                                                }}
                                              />
                                              <label
                                                className="custom-control-label"
                                                htmlFor={`1"function-services-1"+${val.id}`}
                                              >
                                                {val.jobType} <span></span>{" "}
                                              </label>
                                            </div>
                                          );
                                        })
                                      }
                                      <Link onClick={() => { setJobTypeView(true); }} className="font-12 float-right">
                                        View All
                                      </Link>
                                    </div> :

                                    <div>
                                      {

                                        jobTypeData.map((val) => {
                                          return (
                                            <div
                                              className="custom-control custom-radio"
                                              key={val.id}
                                            >
                                              <input
                                                className="custom-control-input"
                                                id={`1"function-services-1"+${val.id}`}
                                                type="radio"
                                                name="radio-function"
                                                value={val.jobType}
                                                onChange={(e) => {
                                                  jobTypeOnChange(e);
                                                  setJobTypeRadio(false);
                                                }}
                                              />
                                              <label
                                                className="custom-control-label"
                                                htmlFor={`1"function-services-1"+${val.id}`}
                                              >
                                                {val.jobType} <span></span>{" "}
                                              </label>
                                            </div>
                                          );
                                        })
                                      }
                                      <Link onClick={() => { setJobTypeView(false); }} className="font-12 float-right">
                                        View Less
                                      </Link>
                                    </div>

                                  :

                                  <div>
                                    {

                                      jobTypeData.map((val) => {
                                        return (
                                          <div
                                            className="custom-control custom-radio"
                                            key={val.id}
                                          >
                                            <input
                                              className="custom-control-input"
                                              id={`1"function-services-1"+${val.id}`}
                                              type="radio"
                                              name="radio-function"
                                              value={val.jobType}
                                              onChange={(e) => {
                                                jobTypeOnChange(e);
                                                setJobTypeRadio(false);
                                              }}
                                            />
                                            <label
                                              className="custom-control-label"
                                              htmlFor={`1"function-services-1"+${val.id}`}
                                            >
                                              {val.jobType} <span></span>{" "}
                                            </label>
                                          </div>
                                        );
                                      })
                                    }

                                  </div>
                                }
                              </div>
                            </div>
                          </div>
                        </aside>
                      </div>
                      <div className="col-xl-9 col-lg-8 col-md-7 "

                      >
                        <div style={{
                          position: "relative",
                          top: "0",
                          left: 0,
                          width: "100%",
                          height: '100%'
                        }}>


                          <div className="scroll-container">
                            <div className="job-bx-title clearfix" >
                              <h5 className="font-weight-700 pull-left text-uppercase">
                                {
                                  filteredArray.length === 0 ?
                                    jobData
                                      .filter((val) => {
                                        if (
                                          val.status === "Active" && (!searchData.title || searchData.title && val.job_title.toLowerCase().includes(searchData.title.toLowerCase())) && (!searchData.location || searchData.location && val.job_area.toLowerCase().includes(searchData.location.toLowerCase())) && (!searchData.sector || searchData.sector && val.job_category.toLowerCase().includes(searchData.sector.toLowerCase())) &&
                                          (!experience ||
                                            (experience && experience === val.experience)) &&
                                          (!salary ||
                                            (salary &&
                                              sal <= val.maximum_salary &&
                                              Sal >= val.maximum_salary)) &&
                                          (!jobType || (jobType && jobType === val.job_type)) &&
                                          (!priorDate ||
                                            (priorDate &&
                                              Date.parse(priorDate) <=
                                              Date.parse(val.created_on)))
                                        ) {
                                          return val;
                                        }
                                      })
                                      .map((val) => val).length > 1 ?
                                      jobData
                                        .filter((val) => {
                                          if (
                                            val.status === "Active" && (!searchData.title || searchData.title && val.job_title.toLowerCase().includes(searchData.title.toLowerCase())) && (!searchData.location || searchData.location && val.job_area.toLowerCase().includes(searchData.location.toLowerCase())) && (!searchData.sector || searchData.sector && val.job_category.toLowerCase().includes(searchData.sector.toLowerCase())) &&
                                            (!experience ||
                                              (experience && experience === val.experience)) &&
                                            (!salary ||
                                              (salary &&
                                                sal <= val.maximum_salary &&
                                                Sal >= val.maximum_salary)) &&
                                            (!jobType || (jobType && jobType === val.job_type)) &&
                                            (!priorDate ||
                                              (priorDate &&
                                                Date.parse(priorDate) <=
                                                Date.parse(val.created_on)))
                                          ) {
                                            return val;
                                          }
                                        })
                                        .map((val) => val).length + " " + "Jobs Found" :

                                      jobData
                                        .filter((val) => {
                                          if (
                                            val.status === "Active" && (!searchData.title || searchData.title && val.job_title.toLowerCase().includes(searchData.title.toLowerCase())) && (!searchData.location || searchData.location && val.job_area.toLowerCase().includes(searchData.location.toLowerCase())) && (!searchData.sector || searchData.sector && val.job_category.toLowerCase().includes(searchData.sector.toLowerCase())) &&
                                            (!experience ||
                                              (experience && experience === val.experience)) &&
                                            (!salary ||
                                              (salary &&
                                                sal <= val.maximum_salary &&
                                                Sal >= val.maximum_salary)) &&
                                            (!jobType || (jobType && jobType === val.job_type)) &&
                                            (!priorDate ||
                                              (priorDate &&
                                                Date.parse(priorDate) <=
                                                Date.parse(val.created_on)))
                                          ) {
                                            return val;
                                          }
                                        })
                                        .map((val) => val).length + " Job Found"
                                    :
                                    filteredArray
                                      .filter((val) => {
                                        if (
                                          val.status === "Active" && (!searchData.title || (searchData.title && val.job_title.toLowerCase().includes(searchData.title.toLowerCase())) && (!searchData.location || searchData.location && val.job_area.toLowerCase().includes(searchData.location.toLowerCase())) && (!searchData.sector || searchData.sector && val.job_category.toLowerCase().includes(searchData.sector.toLowerCase()))) &&
                                          (!experience ||
                                            (experience && experience === val.experience)) &&
                                          (!salary ||
                                            (salary &&
                                              sal < val.maximum_salary &&
                                              Sal > val.maximum_salary)) &&
                                          (!jobType ||
                                            (jobType && jobType === val.job_type)) &&
                                          (!priorDate ||
                                            (priorDate &&
                                              Date.parse(priorDate) <=
                                              Date.parse(val.created_on)))
                                        ) {
                                          return val;
                                        }
                                      })
                                      .map((val) => val).length > 1 ?

                                      filteredArray
                                        .filter((val) => {
                                          if (
                                            val.status === "Active" && (!searchData.title || searchData.title && val.job_title.toLowerCase().includes(searchData.title.toLowerCase())) && (!searchData.location || searchData.location && val.job_area.toLowerCase().includes(searchData.location.toLowerCase())) && (!searchData.sector || searchData.sector && val.job_category.toLowerCase().includes(searchData.sector.toLowerCase())) &&
                                            (!experience ||
                                              (experience && experience === val.experience)) &&
                                            (!salary ||
                                              (salary &&
                                                sal < val.maximum_salary &&
                                                Sal > val.maximum_salary)) &&
                                            (!jobType ||
                                              (jobType && jobType === val.job_type)) &&
                                            (!priorDate ||
                                              (priorDate &&
                                                Date.parse(priorDate) <=
                                                Date.parse(val.created_on)))
                                          ) {
                                            return val;
                                          }
                                        })
                                        .map((val) => val).length + "Jobs Found" :
                                      filteredArray
                                        .filter((val) => {
                                          if (
                                            val.status === "Active" && (!searchData.title || searchData.title && val.job_title.toLowerCase().includes(searchData.title.toLowerCase())) && (!searchData.location || searchData.location && val.job_area.toLowerCase().includes(searchData.location.toLowerCase())) && (!searchData.sector || searchData.sector && val.job_category.toLowerCase().includes(searchData.sector.toLowerCase())) &&
                                            (!experience ||
                                              (experience && experience === val.experience)) &&
                                            (!salary ||
                                              (salary &&
                                                sal < val.maximum_salary &&
                                                Sal > val.maximum_salary)) &&
                                            (!jobType ||
                                              (jobType && jobType === val.job_type)) &&
                                            (!priorDate ||
                                              (priorDate &&
                                                Date.parse(priorDate) <=
                                                Date.parse(val.created_on)))
                                          ) {
                                            return val;
                                          }
                                        })
                                        .map((val) => val).length + "Job Found"
                                }
                              </h5>
                              {filteredArray.length === 0 ? (
                                jobData.filter((val) => {
                                  if (
                                    val.status === "Active" && (!searchData.title || searchData.title && val.job_title.toLowerCase().includes(searchData.title.toLowerCase())) && (!searchData.location || searchData.location && val.job_area.toLowerCase().includes(searchData.location.toLowerCase())) && (!searchData.sector || searchData.sector && val.job_category.toLowerCase().includes(searchData.sector.toLowerCase())) &&
                                    (!experience ||
                                      (experience && experience === val.experience)) &&
                                    (!salary ||
                                      (salary &&
                                        sal <= val.maximum_salary &&
                                        Sal >= val.maximum_salary)) &&
                                    (!jobType || (jobType && jobType === val.job_type))
                                  ) {
                                    return val;
                                  }
                                }).length === 0 ? null : (
                                  <div className="float-right" style={{ fontSize: 12 }}>
                                    <span className="select-title">
                                      Sort by freshness
                                    </span>
                                    <select
                                      style={{ paddingLeft: 10 }}
                                      value={sortData}
                                      onChange={(e) => setSortData(e.target.value)}
                                    >
                                      <option value={""}>All</option>
                                      <option value={60}>Last 2 Months</option>
                                      <option value={30}>Last Months</option>
                                      <option value={7}>Last Weeks</option>
                                      <option value={3}>Last 3 Days</option>
                                    </select>
                                  </div>
                                )
                              ) : filteredArray.filter((val) => {
                                if (
                                  val.status === "Active" && (!searchData.title || searchData.title && val.job_title.toLowerCase().includes(searchData.title.toLowerCase())) && (!searchData.location || searchData.location && val.job_area.toLowerCase().includes(searchData.location.toLowerCase())) && (!searchData.sector || searchData.sector && val.job_category.toLowerCase().includes(searchData.sector.toLowerCase())) &&

                                  (!experience ||
                                    (experience && experience === val.experience)) &&
                                  (!salary ||
                                    (salary &&
                                      sal < val.maximum_salary &&
                                      Sal > val.maximum_salary)) &&
                                  (!jobType || (jobType && jobType === val.job_type))
                                ) {
                                  return val;
                                }
                              }).length === 0 ? null : (
                                <div className="float-right" style={{ fontSize: 12, }}>
                                  <span className="select-title">Sort by freshness</span>
                                  <select
                                    style={{ paddingLeft: 10 }}
                                    value={sortData}
                                    onChange={(e) => setSortData(e.target.value)}
                                  >
                                    <option value={""}>All</option>
                                    <option value={60}>Last 2 Months</option>
                                    <option value={30}>Last Months</option>
                                    <option value={7}>Last Weeks</option>
                                    <option value={3}>Last 3 Days</option>
                                  </select>
                                </div>
                              )}
                            </div>

                            {filteredArray.length === 0 ? (
                              jobData
                                .filter((val) => {
                                  if (
                                    val.status === "Active" && (!searchData.title || searchData.title && val.job_title.toLowerCase().includes(searchData.title.toLowerCase())) && (!searchData.location || searchData.location && val.job_area.toLowerCase().includes(searchData.location.toLowerCase())) && (!searchData.sector || searchData.sector && val.job_category.toLowerCase().includes(searchData.sector.toLowerCase())) &&
                                    (!experience ||
                                      (experience && experience === val.experience)) &&
                                    (!salary ||
                                      (salary &&
                                        sal <= val.maximum_salary &&
                                        Sal >= val.maximum_salary)) &&
                                    (!jobType || (jobType && jobType === val.job_type)) &&
                                    (!priorDate ||
                                      (priorDate &&
                                        Date.parse(priorDate) <=
                                        Date.parse(val.created_on)))
                                  ) {
                                    return val;
                                  }
                                })
                                .map((val) => val).length === 0 ? (
                                <div style={{ width: "100%", display: "flex", justifyContent: 'center', alignItems: 'center' }}>
                                  <a href="{#}"><img src="images/gallery/noJobFound.png" width={150} height={150} alt="" /></a>
                                </div>
                              ) : (
                                <ul className="post-job-bx" style={{ paddingRight: "10px", }}>
                                  {jobData
                                    .filter((val) => {
                                      if (
                                        val.status === "Active" &&
                                        (!searchData.title || searchData.title && val.job_title.toLowerCase().includes(searchData.title.toLowerCase())) && (!searchData.location || searchData.location && val.job_area.toLowerCase().includes(searchData.location.toLowerCase())) && (!searchData.sector || searchData.sector && val.job_category.toLowerCase().includes(searchData.sector.toLowerCase()))
                                        && (!experience ||
                                          (experience &&
                                            experience === val.experience)) &&
                                        (!salary ||
                                          (salary &&
                                            sal <= val.maximum_salary &&
                                            Sal >= val.maximum_salary)) &&
                                        (!jobType ||
                                          (jobType && jobType === val.job_type)) &&
                                        (!priorDate ||
                                          (priorDate &&
                                            Date.parse(priorDate) <=
                                            Date.parse(val.created_on)))
                                      ) {
                                        return val;
                                      }
                                    })
                                    .map((item, index) => {
                                      return (
                                        <li key={index}>
                                          <div className="post-bx">
                                            <div className="d-flex m-b30">
                                              <div className="job-post-company">
                                                <Link to={"#"}>
                                                  <span>
                                                    <img
                                                      alt=""
                                                      src="images/logo/svg/logo1.svg"
                                                    />
                                                  </span>
                                                </Link>
                                              </div>
                                              <div className="job-post-info">
                                                <h4>
                                                  <Link to={`/job-details/${item.id}`}>
                                                    <div>
                                                      <div>
                                                        <div>{item.job_title}</div>
                                                        <p className="m-b5 font-13">
                                                          {item.job_tags}  at {""}
                                                          <Link to="#" className="text-primary">{item.job_company}</Link></p>
                                                      </div>
                                                    </div>
                                                  </Link>
                                                </h4>
                                                <Link to={`/job-details/${item.id}`}>
                                                  <div className="job-post-info">
                                                    <ul>
                                                      <li>
                                                        <i className="fa fa-map-marker"></i>
                                                        {item.workplace_type},
                                                        {item.location},{item.region}
                                                      </li>
                                                      <li>
                                                        <i className="fa fa-bookmark-o"></i>{" "}
                                                        {item.experience}
                                                      </li>
                                                      <li>
                                                        <i className="fa fa-clock-o"></i>
                                                        {item.created_on}
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </Link>

                                              </div>
                                            </div>
                                            <div className="d-flex">
                                              <div className="job-time mr-auto">
                                                <Link to={`/job-details/${item.id}`}>
                                                  <span>{item.job_type}</span>
                                                </Link>
                                              </div>
                                              <div className="salary-bx">
                                                <span>
                                                  {formatNumber(item.minimum_salary)} -{" "}
                                                  {formatNumber(item.maximum_salary)}
                                                </span>
                                              </div>
                                            </div>


                                            <label className="like-btn">
                                              <input type="checkbox" />
                                              {
                                                loaderSavedJob && loaderId === item.id ?
                                                  <Oval
                                                    height={20}
                                                    width={20}
                                                    color="#4fa94d"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    visible={true}
                                                    ariaLabel='oval-loading'
                                                    secondaryColor="#4fa94d"
                                                    strokeWidth={2}
                                                    strokeWidthSecondary={2}
                                                  />
                                                  :
                                                  <div>
                                                    {userData.roleId === 1 ? (
                                                      data.find((val) => { if (val.job_id === item.id) { return val; } }) ? (
                                                        <Link>
                                                          <i
                                                            style={{ color: "red" }}
                                                            className="fa fa-heart"
                                                            aria-hidden="true"
                                                            onClick={() => saveHandler(item)}
                                                          ></i>
                                                        </Link>
                                                      ) : (
                                                        <Link>
                                                          <i
                                                            style={{ color: "blue" }}
                                                            className="fa fa-heart-o"
                                                            aria-hidden="true"
                                                            onClick={() => saveHandler(item)}
                                                          ></i>
                                                        </Link>
                                                      )
                                                    ) : null}
                                                  </div>}
                                            </label>
                                          </div>
                                        </li>
                                      );
                                    })}
                                </ul>
                              )
                            ) : (
                              <ul
                                className="post-job-bx"
                                style={{ backgroundColor: "#fff" }}
                              >
                                {filteredArray
                                  .filter((val) => {
                                    if (
                                      val.status === "Active" && (!searchData.title || searchData.title && val.job_title.toLowerCase().includes(searchData.title.toLowerCase())) && (!searchData.location || searchData.location && val.job_area.toLowerCase().includes(searchData.location.toLowerCase())) && (!searchData.sector || searchData.sector && val.job_category.toLowerCase().includes(searchData.sector.toLowerCase())) &&
                                      (!experience ||
                                        (experience && experience === val.experience)) &&
                                      (!salary ||
                                        (salary &&
                                          sal < val.maximum_salary &&
                                          Sal > val.maximum_salary)) &&
                                      (!jobType ||
                                        (jobType && jobType === val.job_type)) &&
                                      (!priorDate ||
                                        (priorDate &&
                                          Date.parse(priorDate) <=
                                          Date.parse(val.created_on)))
                                    ) {
                                      return val;
                                    }
                                  })
                                  .map((val) => val).length === 0 ? (
                                  <div
                                    style={{
                                      width: "100%",
                                      color: "red",
                                      display: "flex",
                                      justifyContent: "center",
                                      alignItems: "center",
                                    }}
                                  >
                                    <a href="{#}"><img src="images/gallery/noJobFound.png" width={150} height={150} alt="" /></a>
                                  </div>
                                ) : (
                                  filteredArray
                                    .filter((val) => {
                                      if (
                                        val.status === "Active" && (!searchData.title || searchData.title && val.job_title.toLowerCase().includes(searchData.title.toLowerCase())) && (!searchData.location || searchData.location && val.job_area.toLowerCase().includes(searchData.location.toLowerCase())) && (!searchData.sector || searchData.sector && val.job_category.toLowerCase().includes(searchData.sector.toLowerCase())) &&

                                        (!experience ||
                                          (experience &&
                                            experience === val.experience)) &&
                                        (!salary ||
                                          (salary &&
                                            sal < val.maximum_salary &&
                                            Sal > val.maximum_salary)) &&
                                        (!jobType ||
                                          (jobType && jobType === val.job_type)) &&
                                        (!priorDate ||
                                          (priorDate &&
                                            Date.parse(priorDate) <=
                                            Date.parse(val.created_on)))
                                      ) {
                                        return val;
                                      }
                                    })
                                    .map((item, index) => {
                                      return (
                                        <li key={index}>
                                          <div className="post-bx">
                                            <div className="d-flex m-b30">
                                              <div className="job-post-company">
                                                <Link to={"#"}>
                                                  <span>
                                                    <img
                                                      alt=""
                                                      src="images/logo/svg/logo1.svg"
                                                    />
                                                  </span>
                                                </Link>
                                              </div>
                                              <div className="job-post-info">
                                                <h4>
                                                  <Link to={`/job-details/${item.id}`}>
                                                    <div>
                                                      <div>
                                                        <div>{item.job_title}</div>
                                                        <p className="m-b5 font-13">
                                                          {item.job_tags}  at {""}
                                                          <Link to="#" className="text-primary">{item.job_company}</Link></p>
                                                      </div>
                                                    </div>
                                                  </Link>
                                                </h4>
                                                <Link to={`/job-details/${item.id}`}>
                                                  <div className="job-post-info">
                                                    <ul>
                                                      <li>
                                                        <i className="fa fa-map-marker"></i>
                                                        {item.workplace_type},
                                                        {item.location},{item.region}
                                                      </li>
                                                      <li>
                                                        <i className="fa fa-bookmark-o"></i>{" "}
                                                        {item.experience}
                                                      </li>
                                                      <li>
                                                        <i className="fa fa-clock-o"></i>
                                                        {item.created_on}
                                                      </li>
                                                    </ul>
                                                  </div>
                                                </Link>

                                              </div>
                                            </div>
                                            <div className="d-flex">
                                              <div className="job-time mr-auto">
                                                <Link to={`/job-details/${item.id}`}>
                                                  <span>{item.job_type}</span>
                                                </Link>
                                              </div>
                                              <div className="salary-bx">
                                                <span>
                                                  {formatNumber(item.minimum_salary)} -{" "}
                                                  {formatNumber(item.maximum_salaryzy)}
                                                </span>
                                              </div>
                                            </div>
                                            {
                                              loaderSavedJob && loaderId === item.id ?
                                                <label className="like-btn">
                                                  <Oval
                                                    height={20}
                                                    width={20}
                                                    color="#4fa94d"
                                                    wrapperStyle={{}}
                                                    wrapperClass=""
                                                    visible={true}
                                                    ariaLabel='oval-loading'
                                                    secondaryColor="#4fa94d"
                                                    strokeWidth={2}
                                                    strokeWidthSecondary={2}
                                                  />
                                                </label> :

                                                <label className="like-btn">
                                                  <input type="checkbox" />
                                                  {/* <span className="checkmark"></span> */}
                                                  <div>
                                                    {userData.roleId === 1 ? (
                                                      data.find((val) => { if (val.job_id === item.id) { return val; } }) ? (
                                                        <Link>
                                                          <i
                                                            style={{ color: "red" }}
                                                            className="fa fa-heart"
                                                            aria-hidden="true"
                                                            onClick={() => saveHandler(item)}
                                                          ></i>
                                                        </Link>
                                                      ) : (
                                                        <Link>
                                                          <i
                                                            style={{ color: "blue" }}
                                                            className="fa fa-heart-o"
                                                            aria-hidden="true"
                                                            onClick={() => saveHandler(item)}
                                                          ></i>
                                                        </Link>
                                                      )
                                                    ) : null}
                                                  </div>
                                                </label>}
                                          </div>
                                        </li>
                                      );
                                    })
                                )}
                              </ul>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Footer />
          </div>}
    </>
  );
};

export default BrowseJobFilterList;



import React, { useState, useEffect } from 'react';
import Header1 from '../../../components/Layout/Header';
import AdminSideBar2 from './AdminSideBar2';
import Footer from '../../../components/Layout/Footer';
import { Link } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import CategoryMaster from '../../../services/Master/CategoryMaster';
import AlertMessage from '../../../components/AlertMessage';
import Education from '../../../services/Education';
import axios from 'axios';
const AdminQualificationMaster = () => {



    const [qualification, setQualification] = useState([])
    const [qualicationData, setQualificationdata] = useState('')
    const [qualificationModal, setQualificationModal] = useState(false)
    const [alertState, setAlertState] = useState({});
    const [id, setid] = useState(0);
    const [qualification_name, setQualificationName] = useState()
    const [EditQualificationModal, setEditQualificationModal] = useState(false);
    const [DeleteQualificationModal, setDeleteQualificationModal] = useState(false)



    function alertClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        setAlertState({ open: false });
    };

    const getAllQualification = async () => {
        try {
            const res = await Education.getEducation();
            if (res.status === 200) {
                setQualification(res.data);
            }
        } catch (err) {
            return err;
        }
    }
    useEffect(() => {
        getAllQualification();

    }, []);


    const addQualificationHandler = async () => {
        try {
            const payload = {};
            payload.education = qualicationData;
            if (payload.education) {
                const res = await Education.addEducation(payload);
                if (res.status === 200) {
                    setAlertState({ open: true, type: 'success', message: 'Qualification added successfully.' });
                    setTimeout(() => {
                        window.location.reload();  
                    }, 1000);
                    
                }
            } else {
                setAlertState({ open: true, type: 'warning', message: 'Please provide valid inputs.' });

            }

        } catch (err) {
            console.log(err);
        }
    }



    const EditHandler = (Id) => {
        qualification.find((val) => {

            if (val.id === Id) {
                setid(Id);
                setQualificationName(val.education);
                setEditQualificationModal(true);


            }
        })
    }


    const EditQualificationHandler = async () => {
        try {
            const payload = {};
            payload.education = qualification_name;
            var Id = parseInt(id);
            if (payload.education) {
                const res = await Education.UpdateQualification(Id, payload);

                if (res.status === 200) {
                    setAlertState({ open: true, type: 'success', message: 'Qualification updated successfully.' });
                    setTimeout(() => {
                        window.location.reload(); 
                    }, 1000);
                   
                }
            } else {
                setAlertState({ open: true, type: 'warning', message: 'Please provide valid inputs.' });

            }

        } catch (err) {
            return err;
        }
    }



    const QualificationDeleteHandler = (Id) => {
        qualification.map((val) => {
            if (val.id === Id) {
                setid(val.id);
                setDeleteQualificationModal(true);


            }
        })
    }


    const deleteQualification = async () => {
        try {

            var Id = parseInt(id);
            const res = await Education.DeleteQualification(Id);
            if (res.status === 200) {
                setAlertState({ open: true, type: 'error', message: 'Delete Qualification successfully.' });
                setTimeout(() => {
                    window.location.reload();   
                }, 1000);
                
            }
        } catch (err) {
            return err;
        }
    }
    return (
        <>

            <Header1 />
            <div style={{ borderTop: '3px solid #2e55fa' }}>
            {/* <div className="breadcrumb-row" style={{color:"black",paddingLeft:"5.5%",backgroundColor:"White"}}>
								<ul className="list-inline">
									<li style={{color:"black"}} ><Link to={"/"}>Home</Link></li>
									<li style={{color:"black"}}>Qualification Master</li>
								</ul>
							</div> */}
            <div className="content-block" >
                <div className="section-full browse-job content-inner-2">

                    <div className="container">
                        <div className="row">
                            <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12 m-b30">
                                <AdminSideBar2 activelist="admin-qualification-master"/>

                            </div>
                            <div className="ml-30 col-xl-9 col-lg-8 col-md-8 col-sm-12">
                                <div className=" job-bx bg-white m-b30">
                                    <div className="d-flex">
                                        <h5 className="m-b15">Qualification Type Master</h5>
                                        <Link to={"#"} className="site-button add-btn button-sm" onClick={() => setQualificationModal(true)}>
                                            <i className="fa fa-plus m-r5"></i> Add
                                        </Link>
                                    </div>
                                    <table className=" table-job-bx cv-manager company-manage-job">
                                        <thead>
                                            <tr className='table-primary'>
                                                <th >Sl No.</th>
                                                <th >Qualification Type</th>
                                                <th >Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                qualification.map((val, ind) => {
                                                    return (
                                                        <tr className='table-hover' >
                                                            <td >{ind + 1} </td>
                                                            <td>{val.education}</td>
                                                            <td >
                                                                <button className="btn btn-warning button-sm mrg" onClick={() => EditHandler(val.id)}><i className="fa fa-pencil"></i></button>
                                                                <button className=" btn btn-danger button-sm mrg" style={{ marginLeft: "10px" }} onClick={() => QualificationDeleteHandler(val.id)}  ><i className="fa fa-trash"></i></button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }
                                        </tbody>
                                    </table>
                                    <div className='df-rr'><Link className='f-sz' to='/admin-all-masters' >...View Less</Link></div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>      
        </div>
            <Modal show={qualificationModal} onHide={setQualificationModal} className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog my-0" role="document">
                    <div className="modal-content">
                        <div className="modal-header" >
                            <h5 className="modal-title"  id="RegionMaster">Add Qualification </h5>
                            <button type="button" className="close" onClick={() => { setQualificationModal(false) }} >
                                <span aria-hidden="true" >&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p><span className='cl-r'>Note: </span> It is the first thing Candidates and Employers notice,while filling the form. Write concisely what makes your application unique and suitable for them.</p>
                            <form>
                                <div className="row">

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label>Qualification</label>
                                            <input type="text"
                                                className="form-control"
                                                placeholder="Enter Qualification."
                                                value={qualicationData}
                                                onChange={(e) => setQualificationdata(e.target.value)}
                                                style={{color:'black'}}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger"  onClick={() => setQualificationModal(false)} >Cancel</button>
                            <button type="button" className="btn btn-success"  onClick={addQualificationHandler} >Save</button>
                        </div>
                    </div>
                </div>
                <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />

            </Modal>

            <Modal show={EditQualificationModal} onHide={setEditQualificationModal} className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog my-0" role="document">
                    <div className="modal-content">
                        <div className="modal-header" >
                            <h5 className="modal-title"  id="RegionMaster">Edit Qualification </h5>
                            <button type="button" className="close" onClick={() => { setEditQualificationModal(false) }} >
                                <span aria-hidden="true" >&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p><span className='cl-r'>Note: </span> It is the first thing Candidates and Employers notice,while filling the form. Write concisely what makes your application unique and suitable for them.</p>
                            <form>
                                <div className="row">

                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label>Qualification</label>
                                            <input type="text"
                                                className="form-control"
                                                placeholder="Enter Qualification."
                                                value={qualification_name}
                                                onChange={(e) => setQualificationName(e.target.value)} style={{color:'black'}}/>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger"  onClick={() => { setEditQualificationModal(false) }} >Cancel</button>
                            <button type="button" className="btn btn-warning"  onClick={EditQualificationHandler} >Update</button>
                        </div>
                    </div>
                </div>
                <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />

            </Modal>
         

            <Modal
				show={DeleteQualificationModal}
				onHide={setDeleteQualificationModal}
				backdrop=""
				size='md'
				className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true"

			>
				<div class="modal-dialog my-0" role="document" style={{ width: '100%' }}>
					<div className="modal-content "  >
						<div class="modal-header">
							<div class="logo-img">
								<img alt="" src="images/logo/icon2.png" />
							</div>
							<h5 class="modal-title">Delete Qualification</h5>
							<button type="button" className="close" onClick={() => setDeleteQualificationModal(false)} data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>

						<div className="modal-body" >
							
							<p style={{ color: "red" }}> Do you want to delete this Qualification </p>



						</div>
						<div className="modal-footer">
							<div style={{ display: 'flex', justifyContent: 'flex-end' }}>

							</div>
							<button type='button' className='btn btn-info' onClick={() => setDeleteQualificationModal(false)} data-dismiss="modal" aria-label="Close">Cancel</button>
							<button type="button" className="btn btn-danger" onClick={deleteQualification}>Delete</button>

						</div>
					</div>
				</div>
                <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />
			</Modal >

        </>
    )
}

export default AdminQualificationMaster

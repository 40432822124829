import React, { useState , useEffect} from 'react';
import Header1 from '../../../components/Layout/Header';
import AdminSideBar2 from './AdminSideBar2';
import { Link } from 'react-router-dom';
import Footer from '../../../components/Layout/Footer';
import { Modal } from 'react-bootstrap';
import AlertMessage from '../../../components/AlertMessage';
import DateMasterService from '../../../services/Master/DateMaster';




const AdminDateMaster = () => {

    const [addDate, setAddDate] = useState(false);
    const [date, setdates] = useState([]);
    const [date_name, setDate_name] = useState();
    const [id, setId] = useState();
    const [editDate, setEditDate] = useState(false);
    const [deleteDate, setDeleteDate] = useState(false);
    const [dateModal, setdateModal] = useState();
    const [alertState, setAlertState] = useState({ });



    function alertClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        setAlertState({ open: false });
    };

    const getAllDates = async () => {
        try {
            const res = await DateMasterService.GetDate();
            if (res.status === 200) {
                setdates(res.data);
            }

        } catch (err) {
            return err;
        }
    }

    useEffect(() => {
        getAllDates();
    }, [])

// const EditHandler=(index)=>{

//    const data=date
//    console.log(data,"date initialisation")
//    setEditDate(true);

//     }
    



    const EditHandler = (Id) => {
        date.map((val) => {
            if (val.id === Id) {
               
                setDate_name(val.date)
                setId(val.id);
                setEditDate(true);
            }
        })
    }

    const DateDeleteHandler = (Id) => {
        date.map((val) => {
            if (val.id === Id) {
                setId(val.id);
                setDeleteDate(true);
            }
        })
    }


    const dateHandler = async () => {
        try {

            const payload = {};
            payload.date = parseInt(dateModal);
            
            if (payload.date) {
                const res = await DateMasterService.AddDate(payload);
                if (res.status === 200) {
                    setAlertState({ open: true, type: 'success', message: 'Date saved successfully.' });
                    setdateModal("");
                    setTimeout(() => {
                    window.location.reload();   
                    }, 1000);
                }
            } else {
                setAlertState({ open: true, type: 'warning', message: 'Please provide valid inputs.' });

            }
        } catch (err) {
            return (err);
        }
    }

    const DateEditMethod = async () => {
        try {
            const payload = {};
            payload.date = parseInt(date_name);
            var Id = parseInt(id);
            if (payload.date) {
                const res = await DateMasterService.UpdateDates(Id, payload);
                if (res.status === 200) {
                    setDate_name("");
                    setAlertState({ open: true, type: 'warning', message: 'Date updated successfully.' });
                    setTimeout(() => {
                        window.location.reload();
                    }, 1000);
                }
            } else {
                setAlertState({ open: true, type: 'warning', message: 'Please provide valid inputs.' });
            }
        } catch (err) {
            return err;
        }
    }

    const deleteDateMethod = async () => {
        try {
            var Id = parseInt(id);
            const res = await DateMasterService.DeleteDates(Id);
            if (res.status === 200) {
                setAlertState({ open: true, type: 'error', message: 'Date deleted successfully.' });
                setTimeout(() => {
                    window.location.reload(); 
                }, 1000);
                
            }
        } catch (err) {
            return err;
        }
    }
    return (
        <>
            <Header1 />
            <div style={{ borderTop: '3px solid #2e55fa' }}>
            {/* <div className="breadcrumb-row" style={{color:"black",paddingLeft:"5.5%",backgroundColor:"White"}}>
								<ul className="list-inline">
									<li style={{color:"black"}} ><Link to={"/"}>Home</Link></li>
									<li style={{color:"black"}}>Date Master</li>
								</ul>
							</div> */}
            <div className="content-block" >
                <div className="section-full browse-job content-inner-2">
                    <div className="container">
                        <div className="row">
                            <div className="col-xl-3 col-lg-4 col-md-4 col-sm-12 m-b30">
                                <AdminSideBar2 activelist="admin-date-master"/>
                            </div>
                            <div className="ml-30 col-xl-9 col-lg-8 col-md-8 col-sm-12 ">
                                <div className=" job-bx bg-white m-b30">
                                    <div className='d-flex'>
                                        <h5 className="m-b15">Date Master</h5>
                                        <Link to={"#"} className="site-button add-btn button-sm" onClick={() => setAddDate(true)} >
                                            <i className="fa fa-plus m-r5"></i> Add
                                        </Link>
                                    </div>
                                    <table className=' table-job-bx cv-manager company-manage-job '>
                                        <thead>
                                            <tr className="table-primary">
                                                <th >Sl No.</th>
                                                <th >Dates</th>
                                                <th >Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                date.map((val, ind) => {
                                                    return (
                                                        <tr className='table-hover' key={ind}>
                                                            <td >{ind + 1} </td>
                                                            <td >{val.date}</td>
                                                            <td >
                                                            {/* <button className="btn btn-warning button-sm mrg" onClick={() => EditHandler(ind)} ><i className="fa fa-pencil"></i></button> */}
                                                                <button className="btn btn-warning button-sm mrg" onClick={() => EditHandler(val.id)} ><i className="fa fa-pencil"></i></button>
                                                                <button className="btn btn-danger button-sm mrg" style={{ marginLeft: "10px" }} onClick={() => DateDeleteHandler(val.id)} ><i className="fa fa-trash"></i></button>
                                                            </td>
                                                        </tr>
                                                    )
                                                })
                                            }

                                        </tbody>
                                    </table>
                                    <div className='df-rr'><Link className='f-sz' to='/admin-all-masters' >...View Less</Link></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            </div>
            {/* =================================================== Add Modal ======================================================= */}
            <Modal show={addDate} onHide={setAddDate} className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog my-0" role="document">
                    <div className="modal-content">
                        <div className="modal-header" >
                            <h5 className="modal-title"  id="RegionMaster">Add Date </h5>
                            <button type="button" className="close" onClick={() => { setAddDate(false) }} >
                                <span aria-hidden="true">&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p><span className='cl-r'>Note: </span> It is the first thing Candidates and Employers notice,while filling the form. Write concisely what makes your application unique and suitable for them.</p>

                            <form>
                                <div className="row">


                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label>Date</label>
                                            <input type="number"
                                                className="form-control"
                                                placeholder="Enter Date."
                                                value={dateModal}
                                                onChange={(e) => setdateModal(e.target.value)} style={{color:'black'}}/>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger"  onClick={() => { setAddDate(false) }} >Cancel</button>
                            <button type="button" className="btn btn-success"  onClick={dateHandler} >Save</button>
                        </div>
                    </div>
                </div>
                <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />

            </Modal>
            {/* ================================================== Edit Modal ==================================================== */}
            <Modal show={editDate} onHide={setEditDate} className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog my-0" role="document">
                    <div className="modal-content">
                        <div className="modal-header" >
                            <h5 className="modal-title"  id="RegionMaster">Edit Date </h5>
                            <button type="button" className="close" onClick={() => { setEditDate(false) }} >
                                <span aria-hidden="true" >&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p><span className='cl-r'>Note: </span> It is the first thing Candidates and Employers notice,while filling the form. Write concisely what makes your application unique and suitable for them.</p>
                            <form>
                                <div className="row">


                                    <div className="col-lg-12 col-md-12">
                                        <div className="form-group">
                                            <label>Date</label>
                                            <input type="number"
                                                className="form-control"
                                                placeholder="Enter Date."
                                                value={date_name}
                                                onChange={(e) => setDate_name(e.target.value)} style={{color:'black'}}/>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-danger"  onClick={() => { setEditDate(false) }} >Cancel</button>
                            <button type="button" className="btn btn-warning"  onClick={DateEditMethod} >Update</button>
                        </div>
                    </div>
                </div>
                <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />

            </Modal>
            {/* ============================================== Delete Modal ================================================= */}
          
            <Modal show={deleteDate} onHide={setDeleteDate} className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true">

                <div className="modal-dialog my-0 mx-0" role="document">
                    <div className="modal-content">
                        <div className="modal-header" >
                            <h5 className="modal-title" id="RegionMaster"> Delete Date</h5>

                            <button type="button" className="close" onClick={() => { setDeleteDate(false) }} >
                                <span aria-hidden="true" >&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <p><span className='cl-r'> Do You want to delete this Date </span></p>
                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-primary sm" onClick={() => { setDeleteDate(false) }} >Cancel</button>
                            <button type="button" className=" btn btn-danger sm" onClick={deleteDateMethod} >Delete</button>
                        </div>
                    </div>
                </div>
            </Modal>


            <Footer />
        </>
    )
};

export default AdminDateMaster;
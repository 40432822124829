import React from 'react'
import { PDFViewer ,PDFDownloadLink} from '@react-pdf/renderer'
import ResumePdfView from './ResumePdfView'

const PdfPreview = () => {
    return (
        <div style={{ height: window.innerHeight}}>
            <PDFViewer style={{ height: '94%', width: '100%' }}>
                <ResumePdfView />
            </PDFViewer>
            <div>
                <PDFDownloadLink document={<ResumePdfView />}>
                {({ loading }) => (loading ? <button type='button' className='site-button  button-sm' >loading</button> : <button type='button' className='site-button  button-sm'>Download</button>)}
            </PDFDownloadLink>
            </div>
            
        </div>
    )
}

export default PdfPreview
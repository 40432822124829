import React, { useEffect, useState } from 'react';
import Footer from '../../components/Layout/Footer';
import Header from '../../components/Layout/Header';
import * as EmailValidator from 'email-validator';
import UsersService from '../../services/Users';
import { useNavigate, Link } from 'react-router-dom';
import AlertMessage from '../../components/AlertMessage';
import CategoryMaster from '../../services/Master/CategoryMaster';
import { Country, State, City } from 'country-state-city';
import { Form } from 'react-bootstrap';
import pincodeDirectory from 'india-pincode-lookup';
import Error from '../../constants/ErrorMessage';
import { ThreeCircles } from 'react-loader-spinner'
import Users from '../../services/Users';

var lowerCase = /[a-z]/g;
var upperCase = /[A-Z]/g;
var numbers = /[0-9]/g;
var special = /[ -/:-@[-`{-~#^$% ]/g;



const inputInitialState = {
	userFullName: "",
	userEmail: "",
	userMobile: "",
	userPassword: "",
	userConfirmPassword: '',
	empName: "",
	empEmail: "",
	empMobile: "",
	empPassword: "",
	empConfirmPassword: '',
	streetAddress: '',
	empCaptcha: '',
	candiCaptcha: "",
	status: '',
	company_category: '',
	company_name: '',
	company_email: "",
	region: '',
	state: '',
	city: '',
	pin: '',
};



const Register = () => {
	const [loader, setLoader] = useState(false);
	const [candidatebtn, setCandidatebtn] = useState(true);
	const [employerbtn, setEmployerbtn] = useState(false);
	const [candidateProfile, setCandidateProfile] = useState(true);
	const [categoryData, setCategoryData] = useState([]);
	const [countryNames, setCountryNames] = useState([]);
	const [stateNames, setStateNames] = useState([]);
	const [captchaData, setCaptchaData] = useState('');
	const [candicaptchaData, setCandiCaptchaData] = useState('');
	const [cityNames, setCityNames] = useState([]);
	const [show, setShow] = useState(false);
	const [showEmp, setShowEmp] = useState(false);
	const [showError, setShowError] = useState(false);
	const [viewError, setViewError] = useState(false);
	const [alertState, setAlertState] = useState({});
	const [showPassword, setShowPassword] = useState(false);
	const [showConfirmPassword, setShowConfirmpassword] = useState(false);
	const [employerShowPassword, setEmployerShowPassword] = useState(false);
	const [employerShowConfirmPassword, setEmployerShowConfirmPassword] = useState(false);
	const [error, setError] = useState('')
	const [
		{ userFullName,
			userEmail,
			userMobile,
			userPassword,
			userConfirmPassword,
			empName,
			empEmail,
			empMobile,
			empPassword,
			empConfirmPassword,
			empCaptcha,
			candiCaptcha,
			streetAddress,
			status,
			company_category,
			pin,
			company_name,
			company_email,
			region,
			state,
			city
		}, setState
	] = useState(inputInitialState);
	const [users, setUsers] = useState([])


	const navigate = useNavigate();

	useEffect(() => {
		navigate('/register', { replace: true });
	}, [navigate]);



	const getUserlists = async () => {
		try {
			const res = await Users.getUser();

			if (res.status === 200) {
				setUsers(res.data);
			}
		} catch (error) {
			return error;
		}
	}



	const EmployerList = [(users.filter((val) => { if (val.roleId !== 1 && val.roleId !== 3) return val }))]
	const ComapanyNameList = [... new Set(users.filter((val) => { if (val.roleId !== 1 && val.roleId !== 3) return val }).map(item => item.company_name))]


	useEffect(() => {
		getUserlists();
	}, [])


	const resetInputState = () => {
		setState({ ...inputInitialState });
	};


	// 	const onchangeComapanyName = (companyName) => {
	// 		setShow(true);
	// 		setCompany_name(companyName);
	// 	}



	// 	const onchangeComapanyCategory = (e) => {
	// 		setShow(true)
	// 		setCompanyCategory(e.target.value)
	// 	}
	// const onchangeComapanyStreetAddress=(e)=>{
	// 	setShow(true)
	// 	setStreetAddress(e.target.value)

	// }

	// 	console.log(company_category, "companycategory")
	// const fillHandler = (idx) => {
	// 	alert("hii")
	// 	{
	// 		EmployerList.filter((item) => {
	// 			if (item.id === idx) {

	// 				setCompany_name(item.company_name)
	// 				setCompanyCategory(item.company_category)
	// 				setPin(item.pin)
	// 				setCity(item.City)
	// 				setStreetAddress(item.streetAddress)
	// 			}
	// 		})
	// 	}
	// }



	const inputChange = (e) => {
		const { name, value } = e.target;
		setState((prevState) => ({ ...prevState, [name]: value }));
		setShow(true);

		// if (!userPassword.match(lowerCase)) {
		// 	setError("Password should contains lowercase letters!")
		// } else if (!userPassword.match(upperCase)) {
		// 	setError("Password should contain uppercase letters!")
		// } else if (!userPassword.match(numbers)) {
		// 	setError("Password should contains numbers also!")
		// } else if (!userPassword.match(special)) {
		// 	setError("Password should contains one special charecter")
		// } else {
		// 	setError("Password is Strong")
		// }

		// if (!empPassword.match(lowerCase)) {
		// 	setError("Password should contains lowercase letters!")
		// } else if (!empPassword.match(upperCase)) {
		// 	setError("Password should contain uppercase letters!")
		// } else if (!empPassword.match(numbers)) {
		// 	setError("Password should contains numbers also!")
		// } else if (!empPassword.match(special)) {
		// 	setError("Password should contains one special charecter")
		// } else {
		// 	setError("Password is Strong")
		// }

	};



	function alertClose(event, reason) {
		if (reason === 'clickaway') {
			return;
		}
		setAlertState({ open: false });
	};


	const getAllCountryStateCityData = async () => {
		try {
			setCountryNames(Country.getAllCountries());
			setStateNames(State.getAllStates());
			setCityNames(City.getAllCities());
		} catch (error) {
			console.log(error);
		}
	}

	const getAllCategoryData = async () => {
		try {
			const res = await CategoryMaster.getCategory();
			if (res.status === 200) {
				setCategoryData(res.data);
			}
		} catch (error) {
			console.log(error)
		}
	}
	useEffect(() => {
		setLoader(true);
		getAllCategoryData();
		getAllCountryStateCityData();
		setCaptchaData((Math.random() + 1).toString(36).slice(2, 8));
		setCandiCaptchaData((Math.random() + 1).toString(36).slice(2, 8));
		setLoader(false);
	}, []);


	const registerUser = async (e) => {
		e.preventDefault();
		setShowError(true);
		setLoader(true);
		try {

			if (candicaptchaData === candiCaptcha) {

				if (userFullName === "" || userFullName.length > 30) {
					if (userFullName.length > 30) {
						setAlertState({ open: true, type: 'warning', message: 'Name should be under 30 characters.' });
					} else {
						setAlertState({ open: true, type: 'warning', message: 'Please provide your name.' });
					}
				}
				else if (userEmail === "" || userEmail.length > 100 || EmailValidator.validate(userEmail) === false) {
					if (EmailValidator.validate(userEmail) === false) {
						setAlertState({ open: true, type: 'warning', message: 'Please provide a valid email.' });
					}
					else if (userEmail.length > 100) {
						setAlertState({ open: true, type: 'warning', message: 'Email should be under 100 characters' });
					} else {
						setAlertState({ open: true, type: 'warning', message: 'Please provide your email.' });
					}
				}
				else if (userMobile === "" || userMobile.length !== 10 || parseInt(userMobile) < 0) {

					setAlertState({ open: true, type: 'warning', message: 'Please provide a valid mobile number.' });
				}
				else if (userPassword === "" || userPassword.length < 8 || userPassword.length > 100) {
					if (userPassword.length < 8) {
						setAlertState({ open: true, type: 'warning', message: 'Password should be minimum 8 characters.' });
					} else if (userPassword.length > 100) {
						setAlertState({ open: true, type: 'warning', message: 'Password should be under 100 characters' });
					} else {
						setAlertState({ open: true, type: 'warning', message: 'Please provide a password.' });
					}
				}
				else if (userConfirmPassword === "" || userConfirmPassword.length < 8 || userConfirmPassword !== userPassword || userConfirmPassword.length > 100) {
					if (userConfirmPassword !== userPassword) {
						setAlertState({ open: true, type: 'warning', message: "Password doesn't match." });
					} else {
						setAlertState({ open: true, type: 'warning', message: 'Please provide a password.' });
					}
				}
				else {

					const userRegistration = {};
					userRegistration.name = userFullName;
					userRegistration.email = userEmail;
					userRegistration.mobile = userMobile;
					userRegistration.password = userPassword;
					userRegistration.roleId = 1;
					userRegistration.status = 'Active';
					
					userRegistration.emailVerified="0";
					userRegistration.verificationToken="";
					userRegistration.roleName="NA";
					userRegistration.company_name= "NA";
					userRegistration.company_email= "NA";
					userRegistration.company_category= "NA";
					userRegistration.company_streetAddress = "NA";
					userRegistration.company_zip= "NA";
					userRegistration.company_city = "NA";
					userRegistration.company_state= "NA";
					userRegistration.company_country = "NA";
				
					const res = await UsersService.registerUser(userRegistration);
					
					if (res.status === 200) {
						setLoader(false);
						resetInputState();
						setAlertState({ open: true, type: 'success', message: 'Candidate registration successful' });
						setTimeout(()=>{
							navigate('/login');
						},[3000])
					}
					else {
						setLoader(false);
						setAlertState({ open: true, type: 'error', message: 'Something went wrong in Register' });
						setTimeout(() => {
							window.location.reload()
						}, 2000);
					}
				}

			} else {
				setViewError(true);
				setAlertState({ open: true, type: 'warning', message: 'Captcha Does Not Match.' });
				setLoader(false);
			}

		} catch (err) {
			if (err.response.data.message && err.response.data.status === 400) {
				setAlertState({ open: true, type: 'warning', message: 'Email is already Registered' });
				setTimeout(() => {
					window.location.reload()
				}, 2000);
			}



		}

	};


	const registerEmployer = async (e) => {
		e.preventDefault();
		setViewError(true);
		setLoader(true);
		try {
			if (captchaData === empCaptcha) {
				if (empName === "" || empName.length > 30) {
					if (empName.length > 30) {
						setAlertState({ open: true, type: 'warning', message: 'Name should be under 30 character.' });
					} else {
						setAlertState({ open: true, type: 'warning', message: 'Please provide your name.' });
					}
				}
				else if (empEmail === "" || empEmail.length > 100 || EmailValidator.validate(empEmail) === false) {
					if (empEmail.length > 100) {
						setAlertState({ open: true, type: 'warning', message: 'Email should be under 100 characters.' });
					} else if (EmailValidator.validate(empEmail) === false) {
						setAlertState({ open: true, type: 'warning', message: 'Please provide a valid email.' });
					} else {
						setAlertState({ open: true, type: 'warning', message: 'Please provide your email.' });
					}
				}
				else if (empMobile === "" || empMobile.length !== 10 || parseInt(empMobile) < 0) {

					setAlertState({ open: true, type: 'warning', message: 'Please provide a valid mobile number.' });
				}
				else if (empPassword === "" || empPassword.length < 8 || empPassword.length > 100) {

					if (empPassword.length < 8) {
						setAlertState({ open: true, type: 'warning', message: 'Password should be minimum 8 characters.' });
					} else if (empPassword.length > 100) {
						setAlertState({ open: true, type: 'warning', message: 'Password should be under 100 characters' });
					} else {
						setAlertState({ open: true, type: 'warning', message: 'Please provide a password.' });
					}
				} else if (empConfirmPassword === "" || empConfirmPassword.length < 8 || empPassword !== empConfirmPassword) {
					if (empPassword !== empConfirmPassword) {
						setAlertState({ open: true, type: 'warning', message: "Password doesn't match." });
					} else {
						setAlertState({ open: true, type: 'warning', message: 'Please provide a password.' });
					}
				}
				else if (company_name === "" || company_name.length > 250) {
					if (company_name.length > 250) {
						setAlertState({ open: true, type: 'warning', message: 'Company Name should be under 250 characters.' });
					} else {
						setAlertState({ open: true, type: 'warning', message: 'Please provide company name.' });
					}
				}
				else if (company_email === "" || company_email.length > 100 || EmailValidator.validate(company_email) === false) {
					if (company_email.length > 100) {
						setAlertState({ open: true, type: 'warning', message: 'Email should be under 100 characters.' });
					} else if (EmailValidator.validate(company_email) === false) {
						setAlertState({ open: true, type: 'warning', message: 'Please provide a valid email.' });
					} else {
						setAlertState({ open: true, type: 'warning', message: 'Please provide your email.' });
					}
				}
				else if (company_category === "" || company_category.length > 250) {

					if (company_category.length > 250) {
						setAlertState({ open: true, type: 'warning', message: 'Company Category should be under 250 characters.' });
					} else {
						setAlertState({ open: true, type: 'warning', message: 'Please provide Company Category.' });
					}
				}
				else if (pin.length !== 6 || pin.length === 0 || parseInt(pin) < 0) {

					setAlertState({ open: true, type: 'warning', message: 'Please provide a valid PIN Code.' });
				} else if (pin.length !== 6 || (pincodeDirectory.lookup(pin).length === 0) || pincodeDirectory.lookup(pin)[0].stateName !== 'ODISHA') {
					if (pin.length !== 6) {
						setAlertState({ open: true, type: 'warning', message: 'Please provide a valid PIN code' });
					} else if (pincodeDirectory.lookup(pin).length === 0) {
						setAlertState({ open: true, type: 'warning', message: `${pin} doesn't belong to Odisha` });
					}
					else if (pincodeDirectory.lookup(pin)[0].stateName !== 'ODISHA') {
						setAlertState({ open: true, type: 'warning', message: `${pin} doesn't belong to Odisha` });

					}
					else {
						setAlertState({ open: true, type: 'warning', message: 'Please provide PIN code.' });
					}
				}

				else if (streetAddress === '' || streetAddress.length > 200) {
					if (streetAddress.length > 200) {
						setAlertState({ open: true, type: 'warning', message: 'Address should be under 200 characters' });
					} else {
						setAlertState({ open: true, type: 'warning', message: 'Please provide company address.' });
					}
				}
				else if (city === "" || city.length > 150) {
					if (city.length > 150) {
						setAlertState({ open: true, type: 'warning', message: 'City should be under 150 characters' });
					} else {
						setAlertState({ open: true, type: 'warning', message: 'Please select city.' });
					}

				} else {

					// user Table

					const userRegistration = {};
					userRegistration.name = empName;
					userRegistration.email = empEmail;
					userRegistration.mobile = empMobile;
					userRegistration.password = empPassword;
					// userRegistration.confirmPassword = empConfirmPassword;
					userRegistration.roleId = 2;
					userRegistration.roleName = 'employer';
					userRegistration.status = 'In Review';
					userRegistration.emailVerified="0";
					userRegistration.verificationToken="";

					// company Profile Table

					userRegistration.company_name = company_name;
					userRegistration.company_email = company_email;
					userRegistration.company_category = company_category;
					userRegistration.company_streetAddress = streetAddress;
					userRegistration.company_zip = pin;
					userRegistration.company_city = city;
					userRegistration.company_state = 'Odisha';
					userRegistration.company_country = 'India';
				
					



					
					const res = await UsersService.registerUser(userRegistration);
					if (res.status === 200 || res.status === 204) {
						resetInputState();
						setAlertState({ open: true, type: 'success', message: 'Employer registration successful' });
						setLoader(false);

						setTimeout(() => {
							navigate('/login');
						}, 2000)
					}
				}

			} else {
				setShowError(true);
				setAlertState({ open: true, type: 'warning', message: 'Captcha Does Not Match.' });
				setLoader(false);
			}
		} catch (err) {

			if (err.response.data.message && err.response.data.status === 400) {
				alert('Email is already Registered')
				setAlertState({ open: true, type: 'warning', message: 'Email is already Registered'});
				
				setTimeout(() => {
					window.location.reload()
				}, 2000);

			} else {
				setAlertState({ open: true, type: 'error', message: 'Something went wrong.' });
			}

		}


	};



	const candidateButtonHandler = () => {
		setCandidatebtn(true);
		setEmployerbtn(false);
		setCandidateProfile(true);

	}
	const employerButtonHandler = () => {

		setCandidatebtn(false);
		setEmployerbtn(true);
		setCandidateProfile(false);
	}



	let stateList = [];
	let cityList = [];
	let countryCode = countryNames.filter((val) => { if (val.name === 'India') { return val } })
	if (countryCode.length > 0) {

		stateList = stateNames.filter((val) => { if (val.countryCode === countryCode[0].isoCode) { return val } });
		if (stateList.length > 0) {
			let stateCode = stateList.filter((val) => { if (val.name === 'Odisha') { return val; } })
			if (stateCode.length > 0) {
				// cityList= cityNames.filter((val)=>{if(val.stateCode === stateCode[0].isoCode && val.countryCode === countryCode[0].isoCode){return val}});
			}
		}

	}
	cityList = [...new Set(pincodeDirectory.lookup(pin).map(val => val.taluk))]


	const checkHandler = (check) => {
		try {
			setShow(check);

		} catch (error) {
			console.log(error);
		}
	}


	const checkedHandler = (check) => {
		try {
			setShowEmp(check);

		} catch (error) {
			console.log(error);
		}
	}

	const captchaHandler = () => {
		const data = (Math.random() + 1).toString(36).slice(2, 8);
		setCaptchaData(data);
	}
	const candicaptchaHandler = () => {
		const val = (Math.random() + 1).toString(36).slice(2, 8);
		setCandiCaptchaData(val);
	}

	const PasswordVisibility = () => {
		setShowPassword((showPassword) => !showPassword)
	}


	const ConfirmPasswordVisibility = () => {
		setShowConfirmpassword((showConfirmPassword) => !showConfirmPassword)
	}


	const EmployerPasswordVisibility = () => {
		setEmployerShowPassword((employerShowPassword) => !employerShowPassword)
	}

	const EmployerConfirmPasswordVisibility = () => {
		setEmployerShowConfirmPassword((employerShowConfirmPassword) => !employerShowConfirmPassword)
	}

	return (

		<>
			{
				loader ?
					<div style={{ width: "100%", height: "500px", display: "flex", justifyContent: "center", alignItems: 'center' }}>
						<ThreeCircles
							height="90"
							width="90"
							color="#2e55fa"
							wrapperStyle={{}}
							wrapperClass=""
							visible={true}
							ariaLabel="three-circles-rotating"
							outerCircleColor=""
							innerCircleColor=""
							middleCircleColor=""
						/>
					</div>

					:
					<div>
						<Header />
						<AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />



						<div className="page-content">
							<div className="dez-bnr-inr overlay-black-middle bg-pt">
								<div className="container">
									<div className="dez-bnr-inr-entry">
										<h1 className="text-white">Register</h1>

										<div className="breadcrumb-row">
											<ul className="list-inline">
												<li><a href="/">Home</a></li>
												<li>Register</li>
											</ul>
										</div>

									</div>
								</div>
							</div>

							<div className="section-full content-inner browse-job bg-white shop-account">
								<div className="container" >
									<div className="row">
										<div className="col-md-12 ">
											<div className="max-w500 radius-sm m-auto" style={{ display: 'flex', flexDirection: 'row' }}>
												<div style={{ width: "50%" }} >
													<button type='button'
														style={{
															display: "flex",
															width: "100%",
															height: "50px",
															borderTopLeftRadius: 5,
															borderBottom: " 1px solid #e9ebee",
															fontSize: "16px",
															border: "none",
															color: candidatebtn ? "#e9ebee" : "#000",
															backgroundColor: candidatebtn ? "#2e55fa" : "#e9ebee",
															alignItems: "center",
															fontWeight: " 600",
															justifyContent: "center",
															outline: 'none'
														}}
														onClick={candidateButtonHandler}  > Candidate  </button>

												</div>
												<div style={{ width: "50%" }} >
													<button
														style={{

															display: "flex",
															width: "100%",
															height: "50px",
															borderTopRightRadius: "5px",
															fontSize: "16px",
															border: "none",
															color: employerbtn ? "#e9ebee" : "#000",
															backgroundColor: employerbtn ? "#2e55fa" : "#e9ebee",
															alignItems: "center",
															justifyContent: "center",
															fontWeight: " 600",
															outline: 'none'
														}} onClick={employerButtonHandler}> Employer </button>
												</div>
											</div>
										</div>
									</div>
								</div>
								<div className="container" >
									<div className="row">
										<div className="col-md-12 m-b30">
											<div className="card max-w500 radius-sm m-auto" >

												{candidateProfile ? (
													<div >

														<form className="tab-pane active">
															<h4 className="font-weight-700 m-b5">CANDIDATE INFORMATION</h4>
															<div className="font-weight-600">If you have an account with us ?  <Link to="/login">LogIn Here</Link> </div>
															<div className="form-group">
																<label className="font-weight-700">Full Name <span style={{ color: 'red' }}>*</span> </label>
																<input value={userFullName} name="userFullName" onChange={inputChange} className="form-control" placeholder="Your Full Name" type="text" />
																{userFullName ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.name}</span> : null)}
																{userFullName.length > 30 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.nameLimit}</span> : null}
															</div>

															<div className="form-group">
																<label className="font-weight-700">E-Mail <span style={{ color: 'red' }}>*</span> </label>
																<input value={userEmail} name="userEmail" onChange={inputChange} className="form-control" placeholder="Your Email Address" type="email" />
																{userEmail ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.email}</span> : null)}
																{userEmail.length > 100 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.emailLimit}</span> : null}
																{userEmail ? (EmailValidator.validate(userEmail) === false ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.invalidEmail}</span> : null) : null}
															</div>
															<div className="form-group">
																<label className="font-weight-700">Mobile Number <span style={{ color: 'red' }}>*</span> </label>
																<input value={userMobile} name="userMobile" onChange={inputChange} className="form-control" placeholder="Your Mobile Number" type="number" />
																{userMobile ? null : (showError ? !userMobile ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.mobile}</span> : null : null)}
																{userMobile ? (userMobile.length !== 10 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.invalidMobile}</span> : null) : null}
															</div>
															<div className="form-group" >
																<label className="font-weight-700 mb-4">Password <span style={{ color: 'red' }}>*</span> </label>
																<div style={{ marginLeft: "93%", marginBottom: "-8.7%" }}><Link onClick={PasswordVisibility}>{showPassword ? <i className="fa-regular fa-eye"></i> : <i className="fa-regular fa-eye-slash"></i>}</Link></div>
																<input value={userPassword} name="userPassword" onChange={inputChange} className="form-control " placeholder="Create a Password of Length 8" type={showPassword ? "text" : "password"} />
																{userPassword ? null : (showError ? (!userPassword ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.password}</span> : null) : null)}
																{userPassword ? (userPassword.length < 8 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.minPass}</span> : null) :userPassword ? (userPassword.length > 100 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.maxPass}</span> : null) : null}
																{/* <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{error}</span> */}
															</div>

															<div className="form-group"  >
																<label className="font-weight-700 mb-4">Confirm Password <span style={{ color: 'red' }}>*</span> </label>
																<div style={{ marginLeft: "93%", marginBottom: "-8.7%", }}><Link onClick={ConfirmPasswordVisibility}>{showConfirmPassword ? <i className="fa-regular fa-eye"></i> : <i className="fa-regular fa-eye-slash"></i>}</Link></div>
																<input value={userConfirmPassword} name="userConfirmPassword" onChange={inputChange} className="form-control " placeholder="Confirm Password" type={showConfirmPassword ? "text" : "password"} />


																{userConfirmPassword ? null : (showError ? (!userConfirmPassword ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.confirmPassword}</span> : null) : null)}
																{userConfirmPassword ? (userConfirmPassword !== userPassword ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.matchedPassword}</span> : null) : null}

															</div>
															<div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>

																<input
																	className='mb-2 mr-2'
																	type="checkbox"
																	id='checkbox'
																	onChange={(e) => checkHandler(e.target.checked)}
																/>

																<label htmlFor="checkbox">I agree to <a href='#'>terms & conditions .</a></label><br />
															</div>
															<div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: 20, marginBottom: 20, flexWrap: "wrap" }}>
																<div style={{ display: 'flex', justifyContent: 'flex-start', flexDirection: 'row' }}>
																	<div style={{ width: 150, height: 30, backgroundColor: '#2e55fa', display: 'flex', justifyContent: 'center', alignItems: 'center', marginBottom: "15px" }}>
																		<span style={{ fontSize: 22, color: '#fff', letterSpacing: 10, fontFamily: 'sans-serif', fontStyle: 'italic', fontWeight: 520, paddingLeft: 12 }}>{candicaptchaData}</span>
																	</div>
																	<div style={{ height: "30px", display: 'flex', justifyContent: "center", alignItems: "center", width: "40px", backgroundColor: "#dcdce3" }}>
																		<Link onClick={candicaptchaHandler} > <img src="images/logo/reload.png" width={20} height={20} alt="logo" /></Link>
																	</div>
																</div>

																<div style={{ display: 'flex', flexDirection: 'column' }}>
																	<input type="text" value={candiCaptcha} name="candiCaptcha" onChange={inputChange} style={{ border: '1px solid black', backgroundColor: '#C6CFFA', height: 30, borderRadius: 5, textAlign: 'center', }} placeholder="Enter Sequrity Text" color='#000' maxLength={6} />
																	{candiCaptcha ? viewError ? (candicaptchaData !== candiCaptcha ? (<span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.invalidCaptcha}</span>) : null) : null : viewError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.Captcha}</span> : null}
																</div>
															</div>


															<div className="text-center">
																<button disabled={show ? false : true} type="button" className={show ? "site-button " : "site-button bg-dark"} style={{ borderRadius: "50px" }} onClick={registerUser}>Sign Up</button>
															</div>
														</form>

													</div>

												) : (
													<div >

														<form className="tab-pane active">
															<h4 className="font-weight-700 m-b5">EMPLOYER INFORMATION</h4>
															<div className="font-weight-600">If you have an account with us ?  <Link to="/login">LogIn Here</Link> </div>
															<div className="form-group">
																<label className="font-weight-700">Employer Name <span style={{ color: 'red' }}>*</span> </label>
																<input value={empName} name="empName" onChange={inputChange} required className="form-control" placeholder="Your Full Name" type="text" />
																{empName ? null : (viewError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.name}</span> : null)}
																{empName.length > 30 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.nameLimit}</span> : null}
															</div>
															<div className="form-group">
																<label className="font-weight-700">E-Mail <span style={{ color: 'red' }}>*</span> </label>
																<input value={empEmail} name="empEmail" onChange={inputChange} required className="form-control" placeholder="Your Email Address" type="email" />
																{empEmail ? null : (viewError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.email}</span> : null)}
																{empEmail.length > 100 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.emailLimit}</span> : null}
																{empEmail ? (EmailValidator.validate(empEmail) === false ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.invalidEmail}</span> : null) : null}
															</div>
															<div className="form-group">
																<label className="font-weight-700">Mobile Number <span style={{ color: 'red' }}>*</span> </label>
																<input value={empMobile} name="empMobile" onChange={inputChange} required className="form-control" placeholder="Your Mobile Number" type="number" />
																{empMobile ? null : (viewError ? !empMobile ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.mobile}</span> : null : null)}
																{empMobile ? (empMobile.length !== 10 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.invalidMobile}</span> : null) : null}
															</div>
															<div className="form-group">
																<label className="font-weight-700 mb-4">Password <span style={{ color: 'red' }}>*</span> </label>
																<div style={{ marginLeft: "93%", marginBottom: "-8.7%" }}><Link onClick={EmployerPasswordVisibility}>{employerShowPassword ? <i className="fa-regular fa-eye"></i> : <i className="fa-regular fa-eye-slash"></i>}</Link></div>
																<input value={empPassword} name="empPassword" onChange={inputChange} required className="form-control " placeholder="Create a Password of Length 8" type={employerShowPassword ? "text" : "password"} />
																{empPassword ? null : (viewError ? (!empPassword ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.password}</span> : null) : null)}
																{empPassword ? (empPassword.length < 8 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.minPass}</span> : null) : null}
																{empPassword ? (empPassword.length > 100 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.maxPass}</span> : null) : null}
																{/* <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{error}</span> */}
															</div>
															<div className="form-group">
																<label className="font-weight-700 mb-4">Confirm Password <span style={{ color: 'red' }}>*</span> </label>
																<div style={{ marginLeft: "93%", marginBottom: "-8.7%" }}><Link onClick={EmployerConfirmPasswordVisibility}>{employerShowConfirmPassword ? <i className="fa-regular fa-eye"></i> : <i className="fa-regular fa-eye-slash"></i>}</Link></div>
																<input value={empConfirmPassword} name="empConfirmPassword" onChange={inputChange} required className="form-control " placeholder="Confirm Password" type={employerShowConfirmPassword ? "text" : "password"} />
																{empConfirmPassword ? null : (viewError ? (!empConfirmPassword ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.confirmPassword}</span> : null) : null)}
																{empConfirmPassword ? (empConfirmPassword !== empPassword ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.matchedPassword}</span> : null) : null}
															</div>
															<h4 className="font-weight-700 m-b5 mb-3">Company Details</h4>

															<div className="form-group">
																<label className="font-weight-700">Company Name <span style={{ color: 'red' }}>*</span> </label>
																<input value={company_name} name="company_name" onChange={inputChange} required className="form-control" placeholder="Your Company Name" type="text" />
																{company_name ? null : viewError ? (!company_name ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.companyName}</span> : null) : null}
																{company_name ? (company_name.length > 250 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.companyNameLimit}</span> : null) : null}
															</div>

															{/* {

																company_name ?
																	<div className='drop-down-div '>

																		{
																			EmployerList.filter((val) => {
																				if (val.toLowerCase().includes(company_name.toLowerCase())) {
																					return val;
																				}
																			}).map((val) => {
																				return (
																					<>
																						{
																							show ?

																								<Link className='drop-down ' onClick={() => fillHandler(val.id)}> {val} </Link>
																								: null
																						}

																					</>
																				)
																			})
																		}

																	</div> : null

															} */}

															<div className="form-group">
																<label className="font-weight-700">Company E-Mail <span style={{ color: 'red' }}>*</span> </label>
																<input value={company_email} name="company_email" onChange={inputChange} className="form-control" placeholder="Enter Company Email Address" type="email" />
																{company_email ? null : (showError ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.email}</span> : null)}
																{company_email.length > 100 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.emailLimit}</span> : null}
																{company_email ? (EmailValidator.validate(company_email) === false ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.invalidEmail}</span> : null) : null}
															</div>

															<div className="form-group">
																<label className="font-weight-700">Company Category <span style={{ color: 'red' }}>*</span> </label>
																<input list="browsers" value={company_category} name="company_category" onChange={inputChange} required className="form-control" placeholder="Company Category" type="text" />
																{company_category ? null : viewError ? (!company_category ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.companyCategory}</span> : null) : null}
																<datalist id="browsers">
																	{
																		categoryData.map((val) => {
																			return (
																				<React.Fragment key={val.id}>
																					<option value={val.category} >{val.category} </option>
																				</React.Fragment>
																			)
																		})
																	}

																</datalist>
															</div>
															<div className="form-group">
																<label className="font-weight-700">Street Address <span style={{ color: 'red' }}>*</span> </label>
																<input value={streetAddress} name="streetAddress" onChange={inputChange} required className="form-control" placeholder="Street Address" type="text" />
																{streetAddress ? null : viewError ? (!streetAddress ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.companyAddress}</span> : null) : null}
																{streetAddress ? (streetAddress.length > 250 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.companyAddressLimit}</span> : null) : null}
															</div>
															<div className="form-group">
																<label className="font-weight-700">PIN Code <span style={{ color: 'red' }}>*</span> </label>
																<input value={pin} name="pin" onChange={inputChange} required className="form-control" placeholder="PIN Code" type="number" />
																{pin ? pincodeDirectory.lookup(pin).length > 0 ? (pincodeDirectory.lookup(pin)[0].stateName === 'ODISHA' ? null : <span style={{ color: 'red', fontSize: 11, fontWeight: 'bold', marginLeft: 5 }}>{pin} doesn't belong to Odisha</span>) : <span style={{ color: 'red', fontSize: 12 }}>{pin} doesn't belong to Odisha</span> : null}
																{pin ? null : (viewError ? (!pin ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.pincode}</span> : null) : null)}
															</div>



															<div className="form-group">
																<label className="font-weight-700">City <span style={{ color: 'red' }}>*</span> </label>
																<input list='ct' value={city} name="city" onChange={inputChange} required className="form-control" placeholder="City" type="text" />
																{city ? null : viewError ? (!city ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.city}</span> : null) : null}
																{city ? (city.length > 250 ? <span style={{ fontSize: 11, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.cityLimit}</span> : null) : null}
																<datalist id='ct' >
																	{
																		cityList.map((item, index) => {
																			return (
																				<option style={{ backgroundColor: 'blue', color: '#fff' }} key={index} >{item}</option>
																			)
																		})
																	}
																</datalist>

															</div>
															<div className="form-group">
																<label className="font-weight-700">State <span style={{ color: 'red' }}>*</span> </label>
																<Form.Control style={{ height: 50 }} as="select" value={'Odisha'} name="state" onChange={inputChange} required className="form-control custom-select" placeholder="State" type="text">
																	<option value="" >--Select State--</option>
																	{
																		stateList.map((item, index) => {
																			return (
																				<option key={index} >{item.name}</option>
																			)
																		})
																	}
																</Form.Control>
															</div>
															<div className="form-group">
																<label className="font-weight-700">Country <span style={{ color: 'red' }}>* </span> </label>
																<Form.Control style={{ height: 50 }} as="select" className="form-control" value={'India'} name="region" onChange={inputChange} required placeholder="Country" type="text">
																	<option value="" >--Select Country--</option>
																	{
																		countryNames.map((item, index) => {
																			return (
																				<option key={index} >{item.name}</option>
																			)
																		})
																	}
																</Form.Control>
															</div>
															<div style={{ width: '100%', display: 'flex', alignItems: 'center' }}>

																<input
																	className='mb-2 mr-2'
																	type="checkbox"
																	id='checkbox1'
																	onChange={(e) => checkedHandler(e.target.checked)}
																/>

																<label htmlFor="checkbox1">I agree to <a href='#'>terms & conditions .</a></label><br />
															</div>

															<div style={{ width: '100%', display: 'flex', justifyContent: 'space-between', marginTop: 20, marginBottom: 20, flexWrap: "wrap" }}>
																<div style={{ display: 'flex', justifyContent: 'center', flexDirection: 'row', alignItems: 'center', marginBottom: "15px" }}>
																	<div style={{ width: 150, height: 30, backgroundColor: '#2e55fa', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
																		<span style={{ fontSize: 22, color: '#fff', letterSpacing: 10, fontFamily: 'sans-serif', fontStyle: 'italic', fontWeight: 520, paddingLeft: 12 }}>{captchaData}</span>
																	</div>
																	<div style={{ height: "30px", display: 'flex', justifyContent: "center", alignItems: "center", width: "40px", backgroundColor: "#dcdce3" }}>
																		<Link onClick={captchaHandler} > <img src="images/logo/reload.png" width={20} height={20} alt="logo" /></Link>
																	</div>
																</div>

																<div style={{ display: 'flex', flexDirection: 'column' }}>
																	<input value={empCaptcha} name="empCaptcha" onChange={inputChange} required style={{ border: '1px solid black', backgroundColor: '#C6CFFA', height: "30px", borderRadius: 5, textAlign: 'center' }} placeholder="Enter Sequrity Text" color='#000' type="text" />
																	{empCaptcha ? showError ? (captchaData !== empCaptcha ? (<span style={{ fontSize: 10, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.invalidCaptcha}</span>) : null) : null : showError ? <span style={{ fontSize: 10, fontWeight: 'bold', color: 'red', marginLeft: 5 }}>{Error.Captcha}</span> : null}
																</div>
															</div>




															<div className="text-center">
																<button disabled={showEmp ? false : true} type="button" className={showEmp ? "site-button " : "site-button bg-dark"} style={{ borderRadius: "50px" }} onClick={registerEmployer}>Sign Up</button>
															</div>
														</form>

													</div>
												)}
											</div>
										</div>
									</div>
								</div>

							</div>

						</div>





						<Footer />
					</div>}
		</>




	)

}


export default Register;
import React, { useEffect, useState } from 'react'
import Slider from 'react-slick';
import AdminBlogService from '../../services/admin/AdminBlogService';
import AdminblogImageService from '../../services/admin/AdminblogImageService';
import { Link } from 'react-router-dom';

const BlogsSlider = () => {

    const [blogImage, setBlogImage] = useState([]);
    const [allBlogdetails, setAllBlogDetails] = useState([])



    const getAllBlogDetails = async () => {
        const res = await AdminBlogService.getBlog();
        if (res.status === 200) {
            setAllBlogDetails(res.data)
        }
    }

    const getBlogImage = async () => {
        const res = await AdminblogImageService.getBlogImage()
        if (res.status === 200) {
            setBlogImage(res.data)

        }
    }

    useEffect(() => {
        getAllBlogDetails();
        getBlogImage();
    }, [])
    const slidesToShow = allBlogdetails.length < 3 ? allBlogdetails.length : 3;
    var settings = {
        infinite: true,
        speed: 3000,
        autoplaySpeed: 3000,
        slidesToShow: slidesToShow,
        slidesToScroll: 1,
        pauseOnHover: true,
        swipeToSlide: true,
        autoplay: true,
        arrows: false,

        responsive: [
            {
                breakpoint: 1200,
                settings: {
                    slidesToShow: slidesToShow,
                    autoplay: true

                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: slidesToShow,
                    autoplay: true,
                    fontSize: 12
                }
            },
            {
                breakpoint: 550,
                settings: {
                    slidesToShow: 1,
                    autoplay: true,
                    fontSize: 10
                }
            }
        ]
    };

    
    return (
        <>
            <Slider {...settings} className="blog-carousel ">

                {
                    allBlogdetails.map((val, ind) => {
                        return (
                            <div className="item" key={ind}>
                                <div className="blog-post blog-grid blog-style-1" style={{ marginRight: "3%" }}>

                                    {blogImage.filter((itm) => { if (itm.blogId === val.id && itm.position === "C") { return itm } }).map((value, index) => {
                                        return (
                                            <div className="dez-post-media dez-img-effect radius-sm"> <Link to={`/blog-details/${val.id}`} key={index}><img src={value.image} alt="" style={{ width: "100%", height: "263px" }} /></Link> </div>
                                        )

                                    })}
                                    <div className="dez-info">
                                        <div className="dez-post-meta">
                                            <ul className="d-flex align-items-center">
                                                <li className="post-date"><i className="fa fa-calendar"></i>{val.publishdate}</li>
                                                <li className="post-comment"><i className="fa fa-comments-o"></i><a href="#">25k</a> </li>
                                            </ul>
                                        </div>
                                        <div className="dez-post-title ">
                                            <h5 className="post-title font-20"><a href="#">{val.blogTitle}</a></h5>
                                        </div>
                                        <div className="dez-post-text">
                                            <p>{(val.content).slice(0, 200)}</p>
                                        </div>
                                        <div className="dez-post-readmore blog-share">
                                            <Link to={`/blog-details/${val.id}`} title="READ MORE" rel="bookmark" className="site-button-link"><span className="fw6">READ MORE</span></Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }

            </Slider>

        </>
    )
}

export default BlogsSlider
import React, { useState, useEffect } from 'react'
import Header from '../../components/Layout/Header';
import AdminSideBar from './Component/AdminSideBar';
import Footer from '../../components/Layout/Footer';
import { Form, Modal } from 'react-bootstrap';
import Users from '../../services/Users';
import AlertMessage from '../../components/AlertMessage';
import { Link } from 'react-router-dom';
import EmployerProfile from '../../services/EmployerProfile';
import EmployerTeam from '../../services/EmployerTeam';
import CompanyInfo from '../../services/CompanyInfo';
import ReactPaginate from 'react-paginate';
import UserCompany from '../../services/UserCompany';



const AdminManageEmployer = () => {

    const [userList, setUserList] = useState([]);
    const [statusData, setStatusData] = useState('');
    const [view, setView] = useState(false);
    const [permission, setPermission] = useState(false);
    const [radioData, setRadioData] = useState('');
    const [id, setId] = useState();
    const [name, setName] = useState();
    const [email, setEmail] = useState();
    const [password, setPassword] = useState();
    const [verificationToken, setVerificationToken] = useState();
    const [mobile, setMobile] = useState();
    const [roleId, setRoleId] = useState();
    const [status, setStatus] = useState();
    const [emailVerified, setEmailVerified] = useState();
    const [gst_no, setGst_no] = useState();
    const [company_name, setCompany_name] = useState();
    const [state, setState] = useState();
    const [city, setCity] = useState();
    const [address, setAddress] = useState();
    const [alertState, setAlertState] = useState({});
    const [companyInfo, setCompanyInfo] = useState([]);
    const [comapanyProfileData, setComapanyProfileData] = useState([]);
    const [employerTeamInfo, setEmployerTeamInfo] = useState([]);
    const [employerData, setEmployerData] = useState({});
    const [companyData, setCompanyData] = useState();
    const [team, setTeam] = useState({});
    const [pageNumber, setPageNumber] = useState(0);
    const [sortedData, setSortedData] = useState([]);
    const [sortOrder, setSortOrder] = useState('asc');
    const [keyword, setKeyword] = useState();
    const [companyInfoId, setComapnyInfoId] = useState();
    const [companyProfileId, setCompanyProfileId] = useState();
    const [userCompanyData, setUserComapanyData] = useState([]);
    const [comapanyId, setCompanyId] = useState(0)
    const [userComapnyId, setUserCompanyId] = useState(0)

    const itemsPerPage = 10




    function alertClose(event, reason) {
        if (reason === 'clickaway') {
            return;
        }
        setAlertState({ open: false });
    };



    const viewHandler = async (idx) => {
        try {
            // user
            userList.map((val) => {
                if (val.id === idx) {

                    setName(val.name);
                    setEmail(val.email);
                    setPassword(val.password);
                    setVerificationToken(val.verificationToken);
                    setMobile(val.mobile);
                    setRoleId(val.roleId);
                    setStatus(val.status);
                    setEmailVerified(val.emailVerified);

                    //   UserCompany
                    userCompanyData.filter((item) => {
                        if (item.user_id === idx) {
                            setCompanyId(item.company_id)
                            // companyProfile
                            comapanyProfileData.filter((itm) => {
                                if (itm.id === item.company_id) {
                                    setGst_no(itm.company_gstin);
                                    setCompany_name(itm.company_name);
                                    setState(itm.company_state);
                                    setCity(itm.company_city);
                                    setAddress(itm.company_streetAddress);
                                }
                            })
                        }
                    })



                    setView(true);
                }
            })
        } catch (err) {
            return err;
        }
    }

    const permissionHandler = async (idx) => {
        try {

            userList.filter((val) => {
                if (val.id === idx) {
                    setId(val.id);
                    setName(val.name);
                    setEmail(val.email);
                    setPassword(val.password);
                    setVerificationToken(val.verificationToken);
                    setMobile(val.mobile);
                    setRoleId(val.roleId);
                    setEmailVerified(val.emailVerified);
                    setCompanyData(val)
                    employerTeamInfo.find((itm) => {
                        if (itm.email === val.email) {
                            setTeam(itm)
                        }
                    })
                    userCompanyData.filter((item) => {
                        if (item.user_id === idx) {
                            setCompanyId(item.company_id)
                            setUserCompanyId(item.id)

                        }
                    })

                    setPermission(true);
                }
            })
        } catch (error) {
            return error;
        }
    }


    const submitHandler = async () => {

        try {

            const payload = {};

            payload.name = name;
            payload.email = email;
            payload.password = password;
            payload.verificationToken = verificationToken;
            payload.mobile = mobile;
            payload.roleId = roleId;
            payload.status = radioData;
            payload.emailVerified = emailVerified;

            if (radioData) {
                const res = await Users.updateUser(id, payload);
                if (res.status === 200) {


                    const payload = {}

                    payload.status = radioData;

                    const res = await UserCompany.updateUserCompany(userComapnyId, payload)



                    if (companyData.roleId === 4) {
                        const payload = {};
                        payload.employerId = team.employerId;
                        payload.name = team.name;
                        payload.email = team.email;
                        payload.mobile = team.mobile;
                        payload.role = team.role;
                        payload.status = radioData;
                       

                        const resp = await EmployerTeam.editEmployer(team.id, payload)

                        if (resp.status === 200) {
                          
                            setAlertState({ open: true, type: 'success', message: 'Employer Status Updated Successfully' });
                            setTimeout(() => {
                                window.location.reload();
                            }, 1000);

                        }
                    } else {
                        window.location.reload();
                    }


                }
            } else {
                setAlertState({ open: true, type: 'warning', message: 'Please select option' });

            }

            // }
        } catch (err) {
            if (err.response && err.response.status === 400) {
                setAlertState(err.response.data.message);
            } else {
                setAlertState('An error occurred.');
            }
        }
    }

    const getUserCompanyData = async () => {
        try {
            const res = await UserCompany.getAllUserCompany();
            if (res.status === 200) {
                setUserComapanyData(res.data)
            }
        } catch (error) {

        }
    }

    const getJoblists = async () => {
        try {
            const res = await Users.getUser();

            if (res.status === 200) {

                setUserList(res.data);
            }
        } catch (error) {
            return error;
        }
    }
    const getAllCompanyData = async () => {
        try {
            const res = await EmployerProfile.getAllCompanyProfile();
            if (res.status === 200) {
                setComapanyProfileData(res.data)
            }
        } catch (error) {
            console.log(error)
        }
    }


    const getEmployerTeam = async () => {
        try {
            const res = await EmployerTeam.getEmployerTeam();
            setEmployerTeamInfo(res.data);
        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        getUserCompanyData();
        getJoblists();
        getAllCompanyData();
        getEmployerTeam();
    }, []);


    const handlePageChange = ({ selected }) => {
        setPageNumber(selected);
    };

    const startIndex = pageNumber * itemsPerPage;
    const endIndex = startIndex + itemsPerPage;
    const jobdata = userList.slice(startIndex, endIndex);

    const sortingHandler = (col) => {


        if (sortOrder === "asc") {
            const sorted = jobdata.sort((a, b) =>
                a[col] > b[col] ? 1 : -1


            );
            setSortedData(sorted);
            setSortOrder('dsc');
        } else {
            const sorteds = jobdata.sort((a, b) =>
                a[col] < b[col] ? 1 : -1

            );
            setSortedData(sorteds);
            setSortOrder('asc')

        }
    };


    return (
        <>
            <Header activelist="admin-manage-employer" />

            <AlertMessage open={alertState.open} type={alertState.type} message={alertState.message} onclose={alertClose} />
            <div style={{ borderTop: '3px solid #2e55fa' }}>
                <div className="page-content bg-white">

                    <div className="content-block">

                        <div className="section-full bg-white p-t50 p-b20">
                            <div className="container" PaginatedItems itemsPerPage={5} >

                                <div className="row">
                                    <div className="col-xl-3 col-lg-4 m-b30">
                                        <AdminSideBar activemenu="admin-manage-employer" />
                                    </div>

                                    <div className="col-xl-9 col-lg-8 m-b30">
                                        <div className="job-bx clearfix">


                                            <div className="job-bx-title clearfix">
                                                <h5 className="font-weight-700 pull-left text-uppercase">Manage Employers</h5>
                                                <div className="float-right">
                                                    {/* <span className="select-title">Sort by freshness</span> */}

                                                    <div className="">
                                                        <input type="text"
                                                            className="form-control"
                                                            placeholder="Search by Job Title"
                                                            value={keyword}
                                                            onChange={(e) => setKeyword(e.target.value)}
                                                            style={{ borderRadius: "5px" }}
                                                        />
                                                    </div>



                                                </div>
                                            </div>

                                            {sortOrder === 'asc' ?

                                                <table className="table-job-bx cv-manager company-manage-job">
                                                    <thead >
                                                        <tr>

                                                            <th onClick={() => sortingHandler('name')} style={{ fontSize: 12, fontWeight: 800 }}>Name<span>{sortOrder === 'asc' ? <i className="fa fa-caret-down" aria-hidden="true"></i> : <i className="fa fa-caret-up"></i>}</span></th>
                                                            <th style={{ fontSize: 12, fontWeight: 800 }} onClick={() => sortingHandler('company_name')}>Company Email<span>{sortOrder === 'asc' ? <i className="fa fa-caret-down" aria-hidden="true"></i> : <i className="fa fa-caret-up"></i>}</span></th>
                                                            <th style={{ fontSize: 12, fontWeight: 800 }}>Mobile </th>
                                                            <th style={{ fontSize: 12, fontWeight: 800 }} >Status </th>
                                                            <th style={{ fontSize: 12, fontWeight: 800 }}>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {
                                                            jobdata.filter((itm) => { if (itm.roleId !== 1 && itm.roleId !== 3 && (!statusData || statusData === 'Status' || itm.status === statusData) && (!keyword || keyword && itm.name.toLowerCase().includes(keyword.toLowerCase()))) { return itm; } }).map((val, ind) => {

                                                                return (

                                                                    <tr key={ind} >

                                                                        <td className="job-name">

                                                                            <Link to="#" style={{ fontSize: "12px", fontWeight: 800 }}>{val.name}</Link>

                                                                        </td>



                                                                        <td className="application text-primary" style={{ fontSize: "12px" }}>{val.email}</td>

                                                                        <td style={{ fontSize: "12px" }}>{val.mobile}</td>


                                                                        {

                                                                            val.status === "In Review" ?
                                                                                <td style={{ color: 'blue', fontSize: 12 }} className="expired primary">In Review</td> :
                                                                                val.status === "Active" ?
                                                                                    <td className="expired success" style={{ fontSize: 12 }}>Active</td> :
                                                                                    val.status === "Cancel" ?
                                                                                        <td style={{ color: "red", fontSize: 12 }} className="expired danger ">Cancelled</td> :
                                                                                        val.status === 'On Verification' ?
                                                                                            <td style={{ color: "orange", fontSize: 12 }}>On Verification</td> :
                                                                                            null
                                                                        }






                                                                        <td className="job-links mt-2 ml-2">

                                                                            <Link data-toggle="modal" data-target="#exampleModalLong" title='view'><i className="fa fa-eye " style={{ color: 'white' }} onClick={() => viewHandler(val.id)}></i></Link>
                                                                            <Link data-toggle="modal" data-target="#exampleModalLong"><i className=" fa fa-bookmark" style={{ color: 'white', borderRadius: '5px', borderColor: 'green', backgroundColor: "green" }} onClick={() => permissionHandler(val.id)}></i></Link>

                                                                        </td>
                                                                    </tr>

                                                                )

                                                            })
                                                        }



                                                    </tbody>


                                                    <div style={{ marginTop: "10%", display: "flex", alignItems: "flex-end" }}>
                                                        <ReactPaginate
                                                            previousLabel={'Previous'}
                                                            nextLabel={'Next'}
                                                            breakLabel={'...'}
                                                            pageCount={Math.ceil(userList.length / itemsPerPage)}
                                                            marginPagesDisplayed={7}
                                                            pageRangeDisplayed={userList.length}
                                                            onPageChange={handlePageChange}
                                                            containerClassName={'pagination'}
                                                            activeClassName={'active'}
                                                        /></div>
                                                </table>





                                                :
                                                <table className="table-job-bx cv-manager company-manage-job">
                                                    <thead >
                                                        <tr>

                                                            <th onClick={() => sortingHandler('name')} style={{ fontSize: 12, fontWeight: 800 }}>Name<span>{sortOrder === 'asc' ? <i className="fa fa-caret-down" aria-hidden="true"></i> : <i className="fa fa-caret-up"></i>}</span></th>
                                                            <th style={{ fontSize: 12, fontWeight: 800 }} onClick={() => sortingHandler('company_name')}>Company Email<span>{sortOrder === 'asc' ? <i className="fa fa-caret-down" aria-hidden="true"></i> : <i className="fa fa-caret-up"></i>}</span></th>
                                                            <th style={{ fontSize: 12, fontWeight: 800 }}>Mobile </th>
                                                            <th style={{ fontSize: 12, fontWeight: 800 }} >Status </th>
                                                            <th style={{ fontSize: 12, fontWeight: 800 }}>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>

                                                        {
                                                            sortedData.filter((itm) => { if (itm.roleId !== 1 && itm.roleId !== 3 && (!statusData || statusData === 'Status' || itm.status === statusData) && (!keyword || keyword && itm.name.toLowerCase().includes(keyword.toLowerCase()))) { return itm; } }).map((val, ind) => {

                                                                return (

                                                                    <tr key={ind} >

                                                                        <td className="job-name">

                                                                            <Link to="#" style={{ fontSize: "12px", fontWeight: 800 }}>{val.name}</Link>

                                                                        </td>



                                                                        <td className="application text-primary" style={{ fontSize: "12px" }}>{val.email}</td>

                                                                        <td style={{ fontSize: "12px" }}>{val.mobile}</td>



                                                                        {

                                                                            val.status === "In Review" ?
                                                                                <td style={{ color: 'blue', fontSize: 12 }} className="expired primary">In Review</td> :
                                                                                val.status === "Active" ?
                                                                                    <td className="expired success" style={{ fontSize: 12 }}>Active</td> :
                                                                                    val.status === "Cancel" ?
                                                                                        <td style={{ color: "red", fontSize: 12 }} className="expired danger ">Cancelled</td> :
                                                                                        val.status === 'On Verification' ?
                                                                                            <td style={{ color: "orange", fontSize: 12 }}>On Verification</td> :
                                                                                            null
                                                                        }



                                                                        <td className="job-links mt-2 ml-2">

                                                                            <Link data-toggle="modal" data-target="#exampleModalLong"><i className="fa fa-eye " style={{ color: 'white' }} onClick={() => viewHandler(val.id)}></i></Link>
                                                                            <Link data-toggle="modal" data-target="#exampleModalLong"><i className=" fa fa-bookmark" style={{ color: 'white', borderRadius: '5px', borderColor: 'green', backgroundColor: "green" }} onClick={() => permissionHandler(val.id)}></i></Link>

                                                                        </td>
                                                                    </tr>

                                                                )

                                                            })
                                                        }



                                                    </tbody>


                                                    <div style={{ marginTop: "10%", display: "flex", alignItems: "flex-end" }}>
                                                        <ReactPaginate
                                                            previousLabel={'Previous'}
                                                            nextLabel={'Next'}
                                                            breakLabel={'...'}
                                                            pageCount={Math.ceil(userList.length / itemsPerPage)}
                                                            marginPagesDisplayed={7}
                                                            pageRangeDisplayed={userList.length}
                                                            onPageChange={handlePageChange}
                                                            containerClassName={'pagination'}
                                                            activeClassName={'active'}
                                                        /></div>
                                                </table>

                                            }






                                        </div>


                                    </div>





                                </div>
                            </div>
                        </div>



                    </div>
                </div>
            </div>









            <Footer />

            <Modal show={permission} onHide={setPermission} className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog my-0" role="document" style={{ width: "100%" }}>
                    <div className="modal-content">
                        <div className="modal-header" >
                            <h5 className="modal-title" >Set Job Status</h5>
                            <button type="button" className="close" onClick={() => setPermission(false)}>
                                <span aria-hidden="true" >&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">


                            <Form.Control
                                as="select"
                                className="custom-select"

                                value={radioData}
                                onChange={(e) => setRadioData(e.target.value)}
                                style={{ height: "45px", color: "black" }}
                            >
                                <option style={{ color: 'black' }} >-----------------------------Select Status----------------------------</option>
                                <option className='fc-grn' style={{ color: 'black' }}>Active</option>
                                <option style={{ color: 'blue' }}>In Review</option>
                                <option style={{ color: 'orange' }}>On Verification</option>
                                <option className=' fc-red' style={{ color: 'black' }}>Cancel</option>

                            </Form.Control>
                        </div>
                        <div className="modal-footer">
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>

                            </div>
                            <button type="button" className="btn btn-primary" onClick={submitHandler} >Submit</button>
                            <button type="button" className="btn btn-secondary" onClick={() => setPermission(false)}>Close</button>
                        </div>
                    </div>
                </div>
            </Modal>

            <Modal show={view} onHide={setView} className="modal fade modal-bx-info" id="exampleModalLong" tabIndex="-1" role="dialog" aria-hidden="true">
                <div className="modal-dialog my-0" role="document" style={{ width: '100%' }}>
                    <div className="modal-content">
                        <div className="modal-header">
                            <h5 className="modal-title" >View Employer</h5>
                            <button type="button" className="close" onClick={() => setView(false)}>
                                <span aria-hidden="true" >&times;</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <ul>
                                <li>Title : {name}</li>
                                <li>Email : {email} </li>
                                <li>Mobile : {mobile}</li>
                                <li>Company : {company_name}</li>
                                <li>GST No.: {gst_no}</li>
                                <li>Location: {city},{state}</li>
                                <li>StreesAddress : {address}</li>
                                <li>Status : {status}</li>
                            </ul>
                        </div>
                        <div className="modal-footer">
                            <div style={{ display: 'flex', justifyContent: 'flex-end' }}>

                            </div>
                            <button type="button" className="btn btn-secondary" onClick={() => setView(false)}>Close</button>
                        </div>
                    </div>
                </div>
            </Modal>


        </>
    )
}



export default AdminManageEmployer;
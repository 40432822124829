import React from 'react';
import { Link } from 'react-router-dom';
import Header from '../../components/Layout/Header';
import Footer from '../../components/Layout/Footer';
import BlogsSlider from '../../components/Layout/BlogsSlider';
const AboutUs = () => {
  return (
    <>
	<Header/>
       <div className="page-content bg-white">
        {/* <!-- inner page banner  */}
        <div className="dez-bnr-inr overlay-black-middle" style={{backgroundImage:"url(images/aboutus.jpg)"}}>
            <div className="container">
                <div className="dez-bnr-inr-entry">
                    <h1 className="text-white">About Us</h1>
					{/* <!-- Breadcrumb row --> */}
					<div className="breadcrumb-row">
						<ul className="list-inline">
							<li><Link to="/">Home</Link></li>
							<li>About Us</li>
						</ul>
					</div>
					{/* <!-- Breadcrumb row END --> */}
                </div>
            </div>
        </div>
        {/* <!-- inner page banner END --> */}
		<div className="content-block">
            <div className="section-full content-inner">
				<div className="container">
					<div className="row align-items-center m-b50">
						<div className="col-md-12 col-lg-6 m-b20">
							<h2 className="m-b5">About Us</h2>
							<h3 className="fw4">We create unique experiences</h3>
							<p className="m-b15">JobYard is an online employment solution for candidates seeking jobs and the employers who need great proffessionals. 
										We've been doing this for more than 20 years, and have expanded from our roots as a "Job Yard" to a global provider of a full array of job seeking, 
										career management, recruitment and talent management products and services.</p>
						</div>
						<div className="col-md-12 col-lg-6" >
							<img src="images/background/testimonials_bg.jpg" alt=""/>
						</div>
					</div>
					<div className="row">
						<div className="col-lg-4 col-md-4 col-sm-12 m-b30">
							<div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
								<div className="icon-md text-primary m-b20"> <Link to="#" className="icon-cell text-primary"><i className="ti-desktop"></i></Link> </div>
								<div className="icon-content">
									<h5 className="dlab-tilte text-uppercase">Personalised Job Alerts</h5>
									<p>Get job alerts based on multiple criterias e.g. location, designation, industry, salary ,skills & many more.</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-12 m-b30">
							<div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
								<div className="icon-md text-primary m-b20"> <Link to="#" className="icon-cell text-primary"><i className="ti-image"></i></Link> </div>
								<div className="icon-content">
									<h5 className="dlab-tilte text-uppercase">Resume Building</h5>
									<p>Create quality resume through our interactive resume builder to get right opportunities.</p>
								</div>
							</div>
						</div>
						<div className="col-lg-4 col-md-4 col-sm-12 m-b30">
							<div className="icon-bx-wraper p-a30 center bg-gray radius-sm">
								<div className="icon-md text-primary m-b20"> <Link to="#" className="icon-cell text-primary"><i className="ti-cup"></i></Link> </div>
								<div className="icon-content">
									<h5 className="dlab-tilte text-uppercase">Employers Portal</h5>
									<p>Shortlist productive proffessionals for your company using our unique employer interface.</p>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			{/* <!-- Why Chose Us --> */}
			{/* <!-- Call To Action --> */}
			<div className="section-full content-inner-2 call-to-action overlay-black-dark text-white text-center bg-img-fix" style={{backgroundImage: "url(images/banner/jobs.jpg)"}}>
				<div className="container">
					<div className="row">
						<div className="col-lg-12">
							<h2 className="m-b10">Make a Difference with Your Online Resume!</h2>
							<p className="m-b0">Jobyard is not just another job portal; it is a revolutionary platform designed to empower job seekers. With its user-friendly interface, Jobyard allows you to create a visually appealing and interactive online resume that speaks volumes about your potential and capabilities.</p>
							<Link to="/register" className="site-button m-t20 outline outline-2 radius-xl">Create an Account</Link>
						</div>
					</div>
				</div>
			</div>
			{/* <!-- Call To Action END --> */}
			{/* <!-- Our Latest Blog --> */}
			<div className="section-full content-inner-2">
				<div className="container">
					<div className="section-head text-black text-center">
						<h2 className="text-uppercase m-b0">Our Latest Blog</h2>
						<p>Research and highlight the latest trends in the job market, such as remote work, gig economy, AI-driven hiring, upskilling, etc. Discuss how Jobyard is aligned with these trends and how it addresses the needs of job seekers and employers.</p>
					</div>
					<BlogsSlider/>

				</div>
			</div>
			{/* <!-- Our Latest Blog --> */}
        </div>
		{/* <!-- contact area END --> */}
    </div>
    <Footer/>
    </>
  )

}

export default AboutUs;
